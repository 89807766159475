<div class="custom-header">
    <mat-select
        class="selector"
        (valueChange)="onSelectHeader($event)"
        [formControl]="filterSelectFormControl"
        [class.default]="!selected || isDefaultHeader(selected)"
    >
        <mat-option *ngIf="selected" [value]="selected" class="selected custom-header-mat-option">
            <span [class.required]="isRequiredOption(selected)">
                {{ selected }}
            </span>
        </mat-option>
        <ng-container *ngFor="let header of headers">
            <mat-option
                *ngIf="!header.isSelected"
                [value]="header.headerName"
                [class.default]="isDefaultHeader(header.headerName)"
                class="custom-header-mat-option"
            >
                <span [class.required]="isRequiredOption(header.headerName)">
                    {{ header.headerName }}
                </span>
            </mat-option>
        </ng-container>
    </mat-select>
</div>
