import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SidebarPanelType, TimePeriod, TimeRangeOptions } from '../enums/market-inspection.enum';
import {
    fromTimeByRange,
    IPriceChartInfo,
    IUserFilterState,
    MITableType,
    PackageType,
    RowColorRule,
    ISymbolsPair,
    ISymbolLegendConfigMap,
    LegendItem,
    SideBarButtonMap,
    SideBarButtonsMap
} from '../models/market-inspection.models';
import { IMinMax, IClientEventsSize } from '../service/events/client-events-handler.service';
import { MIChartRequestBody } from '../models/market-inspection.models';
import { IPeriod, ISymbolExchange } from '../models/chart.models';
import {
    DEFAULT_EVENTS_COLOR_CONFIG,
    DEFAULT_TIME_PERIOD,
    DEFAULT_TIME_RANGE_OPTION,
    DEFAULT_TRADER_SEARCH_TYPE
} from '../market-inspection.config';
import { ISearchCriteria, TraderSearchType } from '../components/tables/table.model';
import { ChipModel } from '../components/chip/chip.model';
import { TableAlertDTO } from '../models/alerts.model';
import { IMiAlertDescriptionView, Paginated } from 'src/app/_shared/shared.models';
import { LegendFilters } from '../models/events.models';
import { IEventColorConfig } from '../models/events.models';

export interface MarketInspectionState {
    isZoomInDisabled: boolean;
    isZoomIn: boolean;
    isCandleChart: boolean;
    eventsToggleValue: boolean;
    enableEventToggle: boolean;
    displayedSegment: IPeriod;
    isAlertView: boolean;
    isCustomRange: boolean;
    selectedPeriod: TimePeriod;
    selectedTimeRange: TimeRangeOptions;
    isEditView: boolean;
    isBEview: boolean;
    isCSVView: boolean;
    showTimeRange: boolean;
    selectedPair: ISymbolsPair;
    showAlerts: boolean;
    showAggAlerts: boolean;
    clientEventsSize: IClientEventsSize;
    beDataModeWhenCsvSelected: boolean;
    csvAfterEdit: { [key: string]: any }[];
    csvFileFileName: string;
    includeEvents: boolean;
    includeEventsStatistic: boolean;
    showTrades: boolean;
    symbolPairCompare: string;
    isChartFocus: boolean;
    clientHasPrivateData: boolean;
    chipModelArray: ChipModel[];
    chartRequestBody: MIChartRequestBody;
    displayedChartPeriod: IPeriod;
    rowStyleRule: RowColorRule;
    symbolExchangeFilter: ISymbolExchange;
    exchangesList: string[];
    tableTotalEvents: number;
    // tableMaxEvents: number;
    isEventsTotalLoading: boolean;
    guardrailsRules: { [key: string]: boolean };
    timePeriodRule: { [key: string]: boolean };
    clientPackageType: PackageType;
    isCompareDisabled: boolean;
    isPriceChartDisabled: boolean;
    selectedAlgoTypes: string[];
    isAlertsTypeLoading: boolean;
    isMultiLineChart: boolean;
    userFilterState: IUserFilterState;
    screenShotEnabled: boolean;
    isDrawDisabled: boolean;
    drawOn: boolean;
    isShowDraw: boolean;
    caseId: string;
    isDerivatives: boolean;
    isDerivativesEnabled: boolean;
    traderSearchType: TraderSearchType;
    candleViewToggleDisabled: boolean;
    showOnlyAlertRelatedEvents: boolean;
    // eventsType: MIEventType;
    // tabType: MITableType;
    tableType: MITableType;
    tableAlertData: Paginated<TableAlertDTO>;
    disabledTabs: number[];
    eventsAggregationView: boolean;
    showExecutionsOnly: boolean;
    isBeEventsLoading: boolean;
    alertViewResetViewDisabled: boolean;
    alertViewFocusViewDisabled: boolean;
    isEmptyChartResponse?: boolean;
    isEmptyBenchmarkResponse?: boolean;
    priceChartInfo: IPriceChartInfo;
    minMaxPriceSingleEvents: IMinMax;
    minMaxPriceAggregationEvents: IMinMax;
    minMaxPriceSmartAggregationEvents: IMinMax;
    minMaxPriceAlertRelatedEvents: IMinMax;
    includeBenchmark: boolean;
    searchCriteria: ISearchCriteria;
    alertRelated: IMiAlertDescriptionView;
    eventsModeWasChangedByUser: boolean;
    isEventsTotalExceedsTheLimit: boolean;
    legendFilters: LegendFilters;
    eventsColorConfig: IEventColorConfig;
    isAlertRelatedPined: boolean;
    selectedSecondarySymbols: Set<string>;
    hiddenSymbols: Set<string>;
    symbolsLegendConfigMap: ISymbolLegendConfigMap;
    selectedEvent: { [eventId: number]: { [traderType: string]: string; status: string; symbol: string } };
    isPrimarySymbolHided: { symbol: string; isSelected: boolean };
    legendItem: LegendItem[];
    showNews: boolean;
    sideBarButtons: SideBarButtonsMap;
    panelsEditMode: boolean;
    includePrivateData: boolean;
    multiVenues: string[];
    selectedVenues: string[];
    selectedHiddenVenues: Set<string>;
}

export function createInitialState(): MarketInspectionState {
    return {
        isZoomInDisabled: false,
        isZoomIn: false,
        isCandleChart: null,
        eventsToggleValue: false,
        enableEventToggle: false,
        displayedSegment: { fromTime: fromTimeByRange[DEFAULT_TIME_RANGE_OPTION](), toTime: Date.now() },
        isAlertView: false,
        isCustomRange: false,
        selectedPeriod: DEFAULT_TIME_PERIOD,
        selectedTimeRange: DEFAULT_TIME_RANGE_OPTION,
        isEditView: false,
        isBEview: false,
        isCSVView: false,
        showTimeRange: true,
        selectedPair: null,
        showAlerts: false,
        showAggAlerts: false,
        clientEventsSize: null,
        beDataModeWhenCsvSelected: false,
        csvAfterEdit: null,
        csvFileFileName: null,
        includeEvents: false,
        includeEventsStatistic: false,
        showTrades: false,
        symbolPairCompare: null,
        isChartFocus: true,
        clientHasPrivateData: false,
        chipModelArray: [],
        chartRequestBody: null,
        displayedChartPeriod: null,
        rowStyleRule: {},
        symbolExchangeFilter: null,
        exchangesList: [],
        tableTotalEvents: 0,
        isEventsTotalLoading: false,
        guardrailsRules: null,
        clientPackageType: null,
        isCompareDisabled: false,
        isPriceChartDisabled: false,
        timePeriodRule: null,
        selectedAlgoTypes: [],
        isAlertsTypeLoading: false,
        isMultiLineChart: false,
        userFilterState: null,
        screenShotEnabled: false,
        isDrawDisabled: true,
        drawOn: false,
        isShowDraw: true,
        caseId: null,
        isDerivatives: null,
        isDerivativesEnabled: true,
        traderSearchType: DEFAULT_TRADER_SEARCH_TYPE,
        candleViewToggleDisabled: false,
        showOnlyAlertRelatedEvents: false,
        tableType: MITableType.EVENT,
        tableAlertData: null,
        disabledTabs: [1],
        eventsAggregationView: true,
        showExecutionsOnly: false,
        isBeEventsLoading: false,
        alertViewResetViewDisabled: true,
        alertViewFocusViewDisabled: true,
        priceChartInfo: null,
        minMaxPriceSingleEvents: null,
        minMaxPriceAggregationEvents: null,
        minMaxPriceSmartAggregationEvents: null,
        minMaxPriceAlertRelatedEvents: null,
        includeBenchmark: false,
        searchCriteria: null,
        alertRelated: null,
        eventsModeWasChangedByUser: false,
        isEventsTotalExceedsTheLimit: true,
        legendFilters: { status: [], side: [] },
        eventsColorConfig: DEFAULT_EVENTS_COLOR_CONFIG,
        isAlertRelatedPined: true,
        selectedSecondarySymbols: new Set<string>(),
        hiddenSymbols: new Set<string>(),
        symbolsLegendConfigMap: {},
        selectedEvent: null,
        isPrimarySymbolHided: null,
        legendItem: [],
        showNews: false,
        sideBarButtons: SideBarButtonMap,
        panelsEditMode: false,
        includePrivateData: false,
        multiVenues: [],
        selectedVenues: [],
        selectedHiddenVenues: new Set()
    };
}

export const MI_STORE = 'miStore';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: MI_STORE, resettable: true })
export class MarketInspectionStore extends Store<MarketInspectionState> {
    constructor() {
        super(createInitialState());
    }
}
