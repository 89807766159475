import { Clipboard } from '@angular/cdk/clipboard';
import { Injector } from '@angular/core';

import { WindowOpenService } from 'src/app/_services/window-open/window-open.service';
import { CopyQuickActionItem, QuickActionItem, SearchQuickActionItem } from 'src/app/_shared/quick-actions/models';
import { AccountActorRoleDisplayField } from 'src/app/_shared/shared.models';
import { AdvancedSearchService } from 'src/app/advanced-search/advanced-search-service/advanced-search.service';
import { TableType } from 'src/app/advanced-search/advanced-search.models';

export interface IQuickActionsMenu {
    quickActionItems: QuickActionItem[];
}

export class EntitySearchQuickActionsMenu implements IQuickActionsMenu {
    public readonly quickActionItems: QuickActionItem[] = [];

    private readonly clipboard: Clipboard;
    private readonly advancedSearchService: AdvancedSearchService;
    private readonly windowOpenService: WindowOpenService;

    constructor(
        injector: Injector,
        private readonly entityKey: AccountActorRoleDisplayField,
        private readonly entityIds: string[],
        private readonly createdAtTime?: number,
        private readonly isMiScreen?: boolean,
        private readonly onActionCallback?: () => void
    ) {
        this.clipboard = injector.get(Clipboard);
        this.advancedSearchService = injector.get(AdvancedSearchService);
        this.windowOpenService = injector.get(WindowOpenService);
        this.quickActionItems = this.prepareQuickActionItems();
    }

    private prepareQuickActionItems(): QuickActionItem[] {
        return [
            new CopyQuickActionItem({ clipboard: this.clipboard, entityIds: this.entityIds }),
            new QuickActionItem({ title: 'Search for:', isLabel: true }),
            this.getQuickActionItemWithSearchSubItems(
                `Transactions for this entity${this.createdAtTime ? ' (1 day)' : ''}`,
                TableType.Transactions
            ),
            this.getQuickActionItemWithSearchSubItems(
                `Orders for this entity${this.createdAtTime ? ' (1 day)' : ''}`,
                TableType.Orders
            ),
            this.getQuickActionItemWithSearchSubItems(
                `Executions for this entity${this.createdAtTime ? ' (1 day)' : ''}`,
                TableType.Executions
            ),
            this.getQuickActionItemWithSearchSubItems(
                `Alerts for this entity${this.createdAtTime ? ' (30 days)' : ''}`,
                TableType.Alerts
            ),
            this.getQuickActionItemWithSearchSubItems(
                `Cases for this entity${this.createdAtTime ? ' (30 days)' : ''}`,
                TableType.Cases
            )
        ];
    }

    private getQuickActionItemWithSearchSubItems(title: string, tableType: TableType): QuickActionItem {
        if (this.entityIds.length === 1) {
            return this.getSearchQuickActionItem(title, this.entityIds[0], tableType, 'search');
        }

        return new QuickActionItem({
            title,
            icon: 'search',
            subItems: this.entityIds
                .map(id => this.getSearchQuickActionItem(id, id, tableType))
                .sort((a, b) => a.title.localeCompare(b.title))
        });
    }

    private getSearchQuickActionItem(
        title: string,
        entityId: string,
        tableType: TableType,
        icon?: string
    ): SearchQuickActionItem {
        return new SearchQuickActionItem(
            {
                title,
                entityId,
                icon,
                tableType,
                entityKey: this.entityKey,
                advancedSearchService: this.advancedSearchService,
                createdAtTime: this.createdAtTime,
                isMiScreen: this.isMiScreen,
                onActionCallback: this.onActionCallback
            },
            this.windowOpenService
        );
    }
}
