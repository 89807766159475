import { PackageType } from '../models/market-inspection.models';
export abstract class ClientPermission {
    abstract isSingleExchange(): boolean;
    abstract packageType(): PackageType;
    abstract screenShotEnabled(): boolean;
    abstract isDrawEnabled(): boolean;
    abstract packageClientSearchMaxLength(): number;

    private _customClientSearchLimit: number;
    set customClientSearchLimit(num: number) {
        this._customClientSearchLimit = num;
    }

    clientSearchMaxLength(): number {
        return this._customClientSearchLimit || this.packageClientSearchMaxLength();
    }
}
