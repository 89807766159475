export const caseLink = (caseId: string): string => `/ucm/cases/${caseId}`;
export const alertLink = (
    caseId: string,
    origin: string,
    alertCategory: string,
    alertId: string,
    alertOrigin: string
): string => {
    // TODO: remove the 2 lines below when integration with dex data from snowflake will be ready
    const isAlertScreenV1 = alertCategory.startsWith('DEX_INSIDER_TRADING') || alertOrigin !== 'MS';
    const alertScreenVersion = isAlertScreenV1 ? 'alerts' : 'alerts-v2';
    return `/ucm/cases/${caseId}/${alertScreenVersion}/${origin}/${alertCategory}/${alertId}`;
};
export const clientIdLink = (clientId: string): string => `/ucv/${clientId}`;
export const imagesPath = (icon: string): string => `assets/images/chart-markers/images/${icon}.svg`;
export const iconsPath = (icon: string): string => `/assets/images/icons/${icon}.svg`;
export const eventsPath = (icon: string): string => `assets/images/chart-markers/events/${icon}.svg`;
export const chartToolbarPath = (icon: string): string => `assets/images/chart-toolbar/${icon}.svg`;
