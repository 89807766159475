import { Injector } from '@angular/core';

import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-enterprise';

import { isArray } from 'lodash';

import { EntitySearchQuickActionsMenu } from 'src/app/_shared/quick-actions/entity-search-quick-actions-menu';
import { QuickActionItem } from 'src/app/_shared/quick-actions/models';
import { AccountActorRoleDisplayField } from 'src/app/_shared/shared.models';

export const SEARCH_ICON = '<mat-icon class="mat-icon material-icons mat-icon-no-color">' + 'search' + '</mat-icon>';

export class AgQuickActionItemWrapper implements MenuItemDef {
    readonly name: string;
    readonly icon?: string | undefined;
    readonly cssClasses?: string[] | undefined;
    readonly subMenu?: AgQuickActionItemWrapper[] | undefined;
    readonly action?: () => void | undefined;

    constructor(item: QuickActionItem) {
        this.name = item.title;

        if (item.icon) {
            this.icon = SEARCH_ICON;
        }

        if (item.isLabel) {
            this.cssClasses = ['label'];
        }

        if (item.hasSubItems) {
            this.subMenu = item.subItems.map(subItem => new AgQuickActionItemWrapper(subItem));
        }

        if ('action' in item) {
            this.action = () => item.action();
        }
    }
}

export class AgEntitySearchContextMenu {
    items: (string | AgQuickActionItemWrapper)[] = [];

    private readonly nameMap: { [key: string]: AccountActorRoleDisplayField } = {
        clientId: 'clientId',
        actorId: 'actorId',
        account: 'account',
        primaryClients: 'clientId',
        primaryActors: 'actorId',
        primaryAccounts: 'account',
        clients: 'clientId',
        actors: 'actorId',
        accounts: 'account'
    };

    constructor(injector: Injector, params: GetContextMenuItemsParams) {
        const colDef = params?.column?.getColDef();

        if (!params.value || !params.value.length || !colDef || !(colDef.field in this.nameMap)) {
            this.items = ['copy', 'copyWithHeaders'];
            return;
        }

        const menu = new EntitySearchQuickActionsMenu(
            injector,
            this.nameMap[colDef.field],
            isArray(params.value) ? params.value : [params.value]
        );
        const items = menu.quickActionItems.map(item => new AgQuickActionItemWrapper(item));
        items.splice(0, 1);

        this.items = ['copy', 'separator', ...items];
    }
}
