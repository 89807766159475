import {
    AccountActorRole,
    AccountActorRoles,
    Dictionary,
    InvestorType,
    OriginType,
    VisualData
} from 'src/app/_shared/shared.models';
import { ITMEventDTO, IMSEventTypeDTO, IBaseEventDTO } from 'src/app/_shared/events.models';
import { TMEvent } from 'src/app/_shared/events.class';
import { CandlesListDTO, CandlesSet } from 'src/app/_shared/candles.class';
import { AbstractMSEvent, BaseEvent, MSEventType } from 'src/app/_shared/events.class';
import { LoggingService } from 'src/app/_logging/logging.service';
import { getClientProfilesFromAAR, isAnyNullOrUndefined } from 'src/app/_shared/shared.functions';
import { ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { ExtendedCase } from 'src/app/ucm/cases/models';
import { MultiSelectAutocompleteItem } from '../../_shared/multi-select-autocomplete/multi-select-autocomplete.model';

export enum AlertSTRStatus {
    NOT_REQUIRED = 'NOT_REQUIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED = 'SUBMITTED'
}

export interface IBaseAlertDTO {
    createdAt: number;
    description: string;
    id: string;
    caseId: string;
    score: number;
    scoreMultiplier?: number;
    symbols: string[];
    secondarySymbols?: string[];
    alertType: AlertType;
    accountActorRoles: AccountActorRole[];
    exchanges: string[];
    events: IBaseEventDTO[];
    algoVersion?: string;
    buIdentifiers?: string[];
    modelTestId?: string;
}

export interface ITMExtendedAlertDTO extends IBaseAlertDTO {
    createdBy: string;
    fromTime: number;
    toTime: number;
    status: string;
    externalAccountFunction: string;
    events: ITMEventDTO[];
    context: {
        additionalFields: {
            [key: string]: unknown;
        };
        visualData?: {
            [key: string]: unknown;
        };
    };
}

export interface IChainalysisTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            txnType: string;
            service: string;
            totalAmount: string;
            totalAmountOriginal: string;
            clusterName: string;
            clusterCategory: string;
            customAddress?: string;
            chainalysisIdentification?: string;
            exposure: string;
            chainalysisAlertId: string; // we got it only in future alert
            externalLink: string; // https://kyt.chainalysis.com/alerts/ + chainalysisAlertId
            // for immediate alert
            chainalysisRiskRating?: string;
            riskRatingThreshold?: string[];
            // for future alert
            chainalysisSeverityLevel?: string;
            severityLevelThreshold?: string;
        };
    };
}

export interface IChainalysisV2TMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            // fields for BOTH :
            source: string;
            chainalysisAlertId: string;
            chainalysisAlertIdExternalLink: string;
            chainalysisRiskRating: string;
            // score: number -> BaseAlert  // Normalized Risk score
            segmentName: string; // Client Profile
            totalAmount: string;
            totalAmountOriginal: string;
            service: string;
            exposure: string;

            // fields for CHT :
            txnType?: string;
            percentageOfTransfer?: string;
            percentageOfTransferOriginal?: string;
            clusterName?: string;
            categoryName?: string;
            registeredOrganization?: string;
            destinationAddress?: string;
            destinationAddressExternalLink?: string;

            // fields for CWA :
            withdrawalAddress?: string;
            alertCategory?: string;
            addressName?: string;
            addressCategory?: string;
            cwaDescription?: string;
        };
    };
}

export interface IAzakawTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            source: string;
            azakawAlertId: string;
            screenedName: string;
            azakawMatchStatus: string;
            segmentName: string; // Client Profile
            numberOfHits: number;
            transactionAmount: string;
            transactionAmountOriginal: string;
            transactionType: string; // Deposit or Withdrawal

            riskTypesInvolved: string;
            countriesOfOrigin: string;
        };
    };
}

export interface IEllipticTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            alertForUnknownRisk: boolean;
            transactionType: string;
            riskScoreThreshold: number;
            ellipticAnalysisId: string;
            externalLink: string; // 'https://app.elliptic.co/navigator/transactions/' + ellipticAnalysisId
            segmentName: string;
            rules: EllipticRule[];
        };
    };
}

export interface IEllipticLensTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            transactionType: string;
            riskScoreThreshold: number;
            riskScoreChange: number;
            externalLink: string; // 'https://app.elliptic.co/navigator/transactions/' + ellipticAnalysisId
            segmentName: string;
            segmentId: string;
            localCurrency: string;
            triggeredRules: EllipticTriggeredRules[];
            screenedAddress: string;
            walletIncomingFunds: Dictionary<string>;
            walletOutgoingFunds: Dictionary<string>;
        };
    };
}

type EllipticRule = {
    name: string;
    categories: EllipticRuleCategory[];
};

type EllipticRuleCategory = {
    name: string;
    entities: EllipticEntity[];
};

type EllipticEntity = {
    name: string;

    // string of number with 2 digits after dot
    // transformation on the BE side
    contributionPercentage: string; // '0.97'
    contributionValue: string; // '0.11'
};

export interface EllipticTriggeredRules {
    ruleId: string;
    ruleName: string;
    elements: EllipticTriggeredRule[];
}

export interface EllipticTriggeredRule {
    entityName: string;
    categoryName: string;
    ruleRelation: string;
    contributionValue: number;
    contributionPercentage: number;
}

// Temporarily (?) removed extending IAlertDTO to simplify mocks
// May be added back, depending on backend.
export interface IMSExtendedAlertDTO extends IBaseAlertDTO {
    events: IMSEventTypeDTO[];
    isDerivatives?: boolean;
    context: {
        candles: CandlesListDTO;
        visualData?: VisualData;
    };
}

export type AlertType = {
    id: number;
    name: string;
    origin: string;
    alertGroup: string;
    alertCategory: string;
    creationAgent: string;
};

export interface TrdsAlert {
    alert: BaseAlert;
}

export class BaseAlert extends AccountActorRoles {
    createdAt: number;
    description: string;
    accountActorRoles: AccountActorRole[];
    id: string;
    caseId: string;
    score: number;
    scoreMultiplier: number;
    symbols: string[];
    secondarySymbols?: string[];
    exchanges: string[];
    alertType: AlertType;
    events: BaseEvent[];
    sourceOverwrite: string;
    fromTime?: Date | number;
    toTime?: Date | number;
    manipulationValue?: string;
    thresholdBreach?: string;
    thresholdValue?: string;
    localCurrency?: string;
    totalDepositCount?: number;
    totalWithdrawalCount?: number;
    strReportStatus?: AlertSTRStatus;
    algoVersion?: string;
    context?: {
        additionalFields?: {
            [key: string]: unknown;
        };
        candles?: CandlesSet;
        visualData?: VisualData;
    };
    buIdentifiers?: string[];
    modelTestId?: string;

    constructor(dto: IBaseAlertDTO) {
        super(dto.accountActorRoles);
        this.copyOwn(dto);
    }

    copyOwn(dto: IBaseAlertDTO | { [key: string]: unknown }): void {
        Object.keys(dto).forEach(key => {
            this[key] = dto[key];
        });
    }

    get clientProfiles(): string {
        return getClientProfilesFromAAR(this.accountActorRoles);
    }
}

export class TmSlimCaseAlert extends BaseAlert {
    alertId: string;

    constructor(dto: IBaseAlertDTO) {
        super(dto);
        this.alertId = this.id;
    }
}

export class MSExtendedAlert extends BaseAlert {
    events: MSEventType[];
    isDerivatives?: boolean;

    constructor(dto: IMSExtendedAlertDTO) {
        super(dto);
        super.copyOwn(dto);

        this.events = !Array.isArray(dto.events)
            ? []
            : dto.events.map(e => AbstractMSEvent.init(e)).sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
        this.context = { candles: new CandlesSet(dto?.context?.candles), visualData: dto?.context?.visualData };
        this.isDerivatives = dto.isDerivatives;
    }
}

export class TMExtendedAlert extends BaseAlert {
    createdBy: string;
    fromTime: Date;
    toTime: Date;
    status: string;
    externalAccountFunction: string;
    events: TMEvent[];

    constructor(dto: ITMExtendedAlertDTO) {
        super(dto);
        super.copyOwn(dto);

        this.fromTime = new Date(dto.fromTime);
        this.toTime = new Date(dto.toTime);

        this.events = !Array.isArray(dto.events)
            ? []
            : dto.events.map(e => new TMEvent(e)).sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    }
}

export type ChainalysisAddress = {
    addressName: string;
    description: string;
    categoryName: string;
};

export class ChainalysisTMExtendedAlert extends TMExtendedAlert {
    source: string;
    service: string;
    txnType: string;
    totalAmount: string;
    totalAmountOriginal: string;
    clusterName: string;
    clusterCategory: string;
    customAddress?: ChainalysisAddress;
    chainalysisIdentification?: ChainalysisAddress;
    exposure: string;
    chainalysisAlertId: string; // we got it only in future alert
    externalLink: string; // https://kyt.chainalysis.com/alerts/ + chainalysisAlertId
    // for immediate alert
    chainalysisRiskRating?: string;
    riskRatingThreshold?: string[];
    // for future alert
    chainalysisSeverityLevel?: string;
    severityLevelThreshold?: string;

    constructor(dto: IChainalysisTMExtendedAlertDTO) {
        super(dto);
        const aFields = dto?.context?.additionalFields;
        if (aFields) {
            super.copyOwn(aFields);

            // BE can currently only send strings in the additional fields
            // Parsing them to get the underlying object, but only if the type is a string
            // If BE change the property to an object, we will be ready MUHAHAHA
            if (aFields.customAddress && typeof aFields.customAddress === 'string') {
                this.customAddress = JSON.parse(aFields.customAddress);
            }
            if (aFields.chainalysisIdentification && typeof aFields.chainalysisIdentification === 'string') {
                this.chainalysisIdentification = JSON.parse(aFields.chainalysisIdentification);
            }
        } else {
            throw new Error(
                'Cannot initialize ChainalysisTMExtendedAlert. DTO missing context and/or additionalFields.'
            );
        }
    }
}

export class ChainalysisV2TMExtendedAlert extends TMExtendedAlert {
    // fields for BOTH :
    source: string;
    chainalysisAlertId: string;
    chainalysisAlertIdExternalLink: string;
    chainalysisRiskRating: string;
    // score: number -> BaseAlert  // Normalized Risk score
    segmentName: string; // Client Profile
    totalAmount: string;
    totalAmountOriginal: string;
    service: string;
    exposure: string;

    // fields for CHT :
    txnType?: string;
    percentageOfTransfer?: string;
    percentageOfTransferOriginal?: string;
    clusterName?: string;
    categoryName?: string;
    registeredOrganization?: string;
    destinationAddress?: string;
    destinationAddressExternalLink?: string;

    // fields for CWA :
    withdrawalAddress?: string;
    alertCategory?: string;
    addressName?: string;
    addressCategory?: string;
    cwaDescription?: string;

    constructor(dto: IChainalysisV2TMExtendedAlertDTO) {
        super(dto);
        const aFields = dto?.context?.additionalFields;
        if (aFields) {
            super.copyOwn(aFields);
        } else {
            throw new Error(
                'Cannot initialize ChainalysisV2TMExtendedAlert. DTO missing context and/or additionalFields.'
            );
        }
    }
}

export class AzakawTMExtendedAlert extends TMExtendedAlert {
    source: string;
    azakawAlertId: string;
    screenedName: string;
    azakawMatchStatus: string;
    segmentName: string; // Client Profile
    numberOfHits: number;
    transactionAmount: string;
    transactionAmountOriginal: string;
    transactionType: string; // Deposit or Withdrawal
    riskTypesInvolved: string;
    countriesOfOrigin: string;

    constructor(dto: IAzakawTMExtendedAlertDTO) {
        super(dto);
        const aFields = dto?.context?.additionalFields;
        if (aFields) {
            super.copyOwn(aFields);
        } else {
            throw new Error('Cannot initialize AzakawTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export class EllipticTMExtendedAlert extends TMExtendedAlert {
    alertForUnknownRisk: boolean;
    transactionType: string;
    riskScoreThreshold: number;
    ellipticAnalysisId: string;
    externalLink: string; // 'https://app.elliptic.co/navigator/transactions/' + ellipticAnalysisId
    segmentName: string;
    rules: EllipticRule[];

    constructor(dto: IEllipticTMExtendedAlertDTO) {
        super(dto);
        const aFields = dto?.context?.additionalFields;
        if (aFields) {
            super.copyOwn(aFields);
            this.transactionType = aFields.transactionType.toLowerCase();
        } else {
            throw new Error('Cannot initialize EllipticTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export class EllipticLensTMExtendedAlert extends TMExtendedAlert {
    transactionType: string;
    riskScoreThreshold: number;
    riskScoreChange: number;
    externalLink: string; // 'https://app.elliptic.co/navigator/transactions/' + ellipticAnalysisId
    segmentName: string;
    triggeredRules: EllipticTriggeredRule[];
    screenedAddress: string;
    source: string;
    rules: EllipticTriggeredRules[];
    walletIncomingFunds: Dictionary<string>;
    walletOutgoingFunds: Dictionary<string>;

    constructor(dto: IEllipticLensTMExtendedAlertDTO) {
        super(dto);
        this.source = 'Elliptic';
        const aFields = dto?.context?.additionalFields;
        if (aFields) {
            super.copyOwn(aFields);
            this.transactionType = aFields.transactionType.toLowerCase();
            this.rules = aFields.triggeredRules || [];
        } else {
            throw new Error(
                'Cannot initialize EllipticLensTMExtendedAlert. DTO missing context and/or additionalFields.'
            );
        }
    }
}

export class TrmTMExtendedAlert extends TMExtendedAlert {
    issuedAt: number;
    // RiskScore - get from BaseAlert (score)
    riskScoreLevelLabel: RiskScoreLevelLabelEnum;
    riskLevelThreshold: RiskScoreLevelLabelEnum; //Minimum Risk Level Threshold
    ruleName: string;
    ruleDescription: string;
    ruleExternalLink: string; // 'https://app.trmlabs.com/monitoring/rules-engine/{TRMId}/edit/'
    ruleId: string;
    segmentName: string; // Client Profile
    trmAlertId: string;
    trmAlertExternalLink: string;
    alertValidity: boolean;
    status: keyof typeof TrmStatusEnum;
    closedAt: number;
    updatedBy: string;
    closedReason: string;
    closedJustification: string;
    statusUpdatedAt: number;
    transactionType: string;

    constructor(dto: ITrmTMExtendedAlertDTO) {
        super(dto);
        const additionalFields = dto?.context?.additionalFields;
        if (additionalFields) {
            super.copyOwn(additionalFields);
        } else {
            throw new Error('Cannot initialize TrmTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export interface ITrmTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            issuedAt: number;
            // RiskScore - get from BaseAlert (score)
            riskScoreLevelLabel: RiskScoreLevelLabelEnum;
            riskLevelThreshold: RiskScoreLevelLabelEnum; // Minimum Risk Level Threshold
            ruleName: string;
            ruleDescription: string;
            ruleExternalLink: string; // 'https://app.trmlabs.com/monitoring/rules-engine/{TRMId}/edit/'
            ruleId: string;
            segmentName: string; // Client Profile
            trmAlertId: string;
            trmAlertExternalLink: string;
            alertValidity: boolean;
            status: keyof typeof TrmStatusEnum; // status -> got from TMExtendedAlert -> ‘Closed’/ ‘Open’ / ‘In review’
            closedAt: number;
            updatedBy: string;
            closedReason: string;
            closedJustification: string;
            statusUpdatedAt: number;
            transactionType: string;
        };
    };
}

export enum RiskScoreLevelLabelEnum {
    SEVERE = 'Severe',
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
    UNKNOWN = 'Unknown',
    NULL = 'Null'
}

export enum TrmStatusEnum {
    OPEN = 'Open',
    IN_REVIEW = 'In review',
    CLOSED = 'Closed'
}

export class TrmwTMExtendedAlert extends TMExtendedAlert {
    walletAddress: string;
    trmwExternalLink: string; // https://app.trmlabs.com/address/{walletAddress}/btc
    // Normalized RiskScore - get from BaseAlert (score)
    maxRiskScoreLevelLabel: RiskScoreLevelLabelEnum;
    minRiskLevelThreshold: RiskScoreLevelLabelEnum; //Minimum Risk Level Threshold
    segmentName: string; // Client Profile
    transactionType: string;
    addressRiskIndicators: AddressRiskIndicator[];
    associatedEntities: AssociatedEntity[];

    constructor(dto: ITrmwTMExtendedAlertDTO) {
        super(dto);
        const additionalFields = dto?.context?.additionalFields;
        if (additionalFields) {
            super.copyOwn(additionalFields);
        } else {
            throw new Error('Cannot initialize TrmwTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export interface ITrmwTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            walletAddress: string;
            trmwExternalLink: string; // https://app.trmlabs.com/address/{walletAddress}/btc
            // Normalized RiskScore - get from BaseAlert (score)
            maxRiskScoreLevelLabel: RiskScoreLevelLabelEnum;
            minRiskLevelThreshold: RiskScoreLevelLabelEnum; //Minimum Risk Level Threshold
            segmentName: string; // Client Profile
            transactionType: string;
            addressRiskIndicators: AddressRiskIndicator[];
            associatedEntities: AssociatedEntity[];
        };
    };
}

export type AddressRiskIndicator = {
    category: string;
    riskType: string;
    categoryRiskScoreLevelLabel: RiskScoreLevelLabelEnum;
    incomingVolumeUsd: string;
    outgoingVolumeUsd: string;
    totalVolumeUsd: string;
};

export type AssociatedEntity = {
    entity: string;
    trmAppUrl: string; //  'https://app.trmlabs.com/entities/trm/{id}'
    category: string;
    riskScoreLevelLabel: RiskScoreLevelLabelEnum;
};

export type ActionType = 'DEPOSIT' | 'WITHDRAWAL' | 'DEPOSIT_WITHDRAWAL';

export class BigtTMExtendedAlert extends TMExtendedAlert {
    // Normalized RiskScore - get from BaseAlert (score)
    riskType: string[];
    transactionAmount: string;
    transactionAmountOriginal: string;
    inputAddressesDetails: AddressDetails[];
    outputAddressesDetails: AddressDetails[];

    constructor(dto: IBigtTMExtendedAlertDTO) {
        super(dto);
        const additionalFields = dto?.context?.additionalFields;
        if (additionalFields) {
            super.copyOwn(additionalFields);
        } else {
            throw new Error('Cannot initialize BigtTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export interface IBigtTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            // Normalized RiskScore - get from BaseAlert (score)
            riskType: string[];
            transactionAmount: string;
            transactionAmountOriginal: string;
            inputAddressesDetails: AddressDetails[];
            outputAddressesDetails: AddressDetails[];
        };
    };
}

export type AddressDetails = {
    riskScore: number;
    address: string;
    riskType: string[];
    names: string[];
};

export class BigwTMExtendedAlert extends TMExtendedAlert {
    // Normalized RiskScore - get from BaseAlert (score)
    riskType: string[];
    walletAddress: string;
    walletBalance: string;
    walletBalanceOriginal: string;
    walletOwners: string[];
    addressesExpose: AddressExpose[];
    walletActivities: WalletActivity[];

    constructor(dto: IBigwTMExtendedAlertDTO) {
        super(dto);
        const additionalFields = dto?.context?.additionalFields;
        if (additionalFields) {
            super.copyOwn(additionalFields);
        } else {
            throw new Error('Cannot initialize BigwTMExtendedAlert. DTO missing context and/or additionalFields.');
        }
    }
}

export interface IBigwTMExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            // Normalized RiskScore - get from BaseAlert (score)
            riskType: string[];
            walletAddress: string;
            walletBalance: string;
            walletBalanceOriginal: string;
            walletOwners: string[];
            addressesExpose: AddressExpose[];
            walletActivities: WalletActivity[];
        };
    };
}

export type AddressExpose = {
    direction: keyof typeof TransactionDirectionEnum;
    amount: string;
    numberOfEntities: number;
    riskType: string[];
};

export type WalletActivity = {
    direction: keyof typeof TransactionDirectionEnum;
    amount: string;
    count: number;
    startDate: number;
    endDate: number;
};

export enum TransactionDirectionEnum {
    IN = 'Incoming',
    OUT = 'Outgoing'
}

export enum FeatureRange {
    DAILY = 'Daily',
    WEEKLY = 'Weekly'
}

export const FeatureTypeEnum = {
    transactionAmount: { fullName: 'Transaction Amount', shortName: 'Amount' },
    transactionCount: { fullName: 'Transaction Count', shortName: 'Count' }
};

export type FeatureType = 'transactionAmount' | 'transactionCount';
export type SensitivityLabel = 'HIGH' | 'MEDIUM' | 'LOW';
export type TransactionType = 'deposit' | 'withdrawal';

export type FeatureAggValue = {
    transactionType: TransactionType;
    featureType: FeatureType;
    average?: number;
    thresholdValue?: number;
    currentValue?: number;
    sensitivity: SensitivityLabel;
    isRaisedAlert?: boolean;
    featureTrendLine?: Series[];
    averageTrendLine?: Series[];
    lowerBandLine?: Series[];
    upperBandLine?: Series[];
};

export class Series {
    timestamp: Date;
    value: number;

    constructor(timestamp: number, value: number) {
        this.timestamp = new Date(timestamp); // exe: 1666569600000
        this.value = isNaN(value) ? 0 : value;
    }
}

export type ColumnInfo = {
    columnDef: string;
    tooltipHeader?: string;
};

export type FeatureSelectedRow = {
    featureValue: FeatureAggValue;
    selectedRange: FeatureRange;
};

export type UpdatedUcvField = {
    fieldName: string;
    fromValue: string;
    toValue: string;
    timestamp: number;
};

export type CategoryToMonitor =
    | 'COMPANY_DETAILS'
    | 'CONTACT_DETAILS'
    | 'INDIVIDUAL_FIELDS'
    | 'TRADING_OPERATIONS'
    | 'PERSONAL_DETAILS'
    | 'FINANCIAL_DETAILS';
('RUG_PULL_DEPLOYER');
('OFAC_SANCTIONED_DIGITAL_CURRENCY_ADDRESS');

export interface ITMGenericExtendedAlertDTO extends ITMExtendedAlertDTO {
    context: {
        additionalFields: {
            source: string;
            breach: string;
            thresholdValue: string;
            thresholdValueOriginal: string;
            depositCount: number;
            depositAmount: string;
            depositAmountOriginal: string;
            withdrawalCount: number;
            withdrawalAmount: string;
            withdrawalAmountOriginal: string;
            ratioDepositWithdrawal: string;
            manipulationValue: string;
            manipulationValueOriginal: string;
            segmentName: string;
            localCurrency?: string;
            transactionCount?: number;
            transactionAmount?: string;
            transactionAmountOriginal?: string;
            minTransactionThreshold?: string;
            minTransactionThresholdOriginal?: string;
            jurisdictionSource?: string;
            transactionSourceIP?: string;
            jurisdictionRiskScore?: number;
            type?: ActionType; // DEPOSIT, WITHDRAWAL, DEPOSIT_WITHDRAWAL (= Transaction)
            allowedPercentageDifferenceThreshold?: string;
            timePeriod?: string;
            combineDepositWithdrawal?: boolean;
            similarAmount?: string;
            similarAmountOriginal?: string;
            aggregateAmountThreshold?: string;
            aggregateAmountThresholdOriginal?: string;
            similarCount?: number;
            minimumCountThreshold?: number;
            totalAmount?: string;
            totalAmountOriginal?: string;
            spokeCount?: number;
            spokeAccounts?: string[];
            transactionAggregationCriteria?: string;
            appliedTo?: string;
            singleWireTransferAmount?: string;
            singleWireTransferAmountOriginal?: string;
            singleWireTransferAmountThreshold?: string;
            singleWireTransferAmountThresholdOriginal?: string;
            numOfWireTransfers?: number;
            wireTransferFrequencyThreshold?: number;
            investorType?: InvestorType; //  'RETAIL' | 'INSTITUTIONAL'
            annualIncome?: string;
            annualIncomeMultiplier?: string;
            netWorth?: string;
            netWorthMultiplier?: string;
            monthlyDepositAmount?: string;
            monthlyDepositAmountMultiplier?: string;
            annualTurnover?: string;
            annualTurnoverMultiplier?: string;
            assetsUnderManagement?: string;
            assetsUnderManagementMultiplier?: string;
            netDepositAmount?: string;
            netDepositAmountOriginal?: string;
            ipAddress?: string;
            activityMonitoringTimeWindow?: string;
            reportActivityAcrossMultipleAccounts?: boolean;
            numberOfExternalAccounts?: number;
            externalAccounts?: string[];
            transactionCombinationsToMonitor?: string;
            minNumOfTransactionsThreshold?: number;
            fullBeneficiaryName?: string;
            fullClientNameInUcv?: string;
            accountBalanceBreachAmount?: string;
            accountBalanceBreachAmountOriginal?: string;
            accountBalanceAmountThreshold?: string;
            accountBalanceAmountThresholdOriginal?: string;
            minimumThresholdValue?: string;
            minimumThresholdValueOriginal?: string;
            minFundsTransferredThreshold?: string;
            netFundsTransferredAmount?: string;
            netFundsTransferredAmountOriginal?: string;
            maxTradingAmountThreshold?: string;
            maxTradingAmountThresholdOriginal?: string;
            minActivePeriod?: number;
            actualActivePeriod?: number;
            breachScale?: FeatureRange;
            activityTimeWindow?: number;
            minimumAgeThreshold?: string;
            clientAge?: string;
            alertOnFirstTransaction?: boolean;
            thresholdLimits?: string;
            frequencyPeriod?: string;
            numberOfFrequencies?: number;
            minInternalSpokeCountThreshold?: number;
            minExternalSpokeCountThreshold?: number;
            countRange?: string;
            amountRange?: string;
            transactionType?: ActionType;
            amountThreshold?: string;
            amountThresholdOriginal?: string;
            numberOfChangesThreshold?: number;
            numberOfChangesActual?: number;
            jurisdictionScore?: number;
            jurisdictionScoreThreshold?: number;
            updatedUcvFieldsByCategory?: { [category in CategoryToMonitor]: UpdatedUcvField[] };
            screenedAddress?: string;
            watchlistAppearances?: string[];
            addressClassification?: string;
            addressFlaggedDate?: number;
            categories?: string[];
            blockchain?: string;
            txnHash?: string;
            scamTypes?: string[];
            monitoredAge?: string;
            appliesTo?: string;
        };
        visualData?: {
            dailyFeatures?: FeatureAggValue[];
            weeklyFeatures?: FeatureAggValue[];
        };
    };
}

export class TMGenericExtendedAlert extends TMExtendedAlert {
    source: string;
    breach: string;
    thresholdValue: string;
    thresholdValueOriginal: string;
    depositCount: number;
    depositAmount: string;
    depositAmountOriginal: string;
    withdrawalCount: number;
    withdrawalAmount: string;
    withdrawalAmountOriginal: string;
    ratioDepositWithdrawal: string;
    manipulationValue: string;
    manipulationValueOriginal: string;
    segmentName: string;
    fullNameAlertType: string; // alertTypeName_algoVersion
    transactionCount?: number;
    transactionAmount?: string;
    transactionAmountOriginal?: string;
    minTransactionThreshold?: string;
    minTransactionThresholdOriginal?: string;
    jurisdictionSource?: string;
    transactionSourceIP?: string;
    jurisdictionRiskScore?: number;
    type?: ActionType; // DEPOSIT, WITHDRAWAL, DEPOSIT_WITHDRAWAL (= Transaction)
    allowedPercentageDifferenceThreshold?: string;
    timePeriod?: string;
    combineDepositWithdrawal?: boolean;
    similarAmount?: string;
    similarAmountOriginal?: string;
    aggregateAmountThreshold?: string;
    aggregateAmountThresholdOriginal?: string;
    similarCount?: number;
    minimumCountThreshold?: number;
    totalAmount?: string;
    totalAmountOriginal?: string;
    spokeCount?: number;
    spokeAccounts?: string[];
    transactionAggregationCriteria?: string;
    appliedTo?: string;
    singleWireTransferAmount?: string;
    singleWireTransferAmountOriginal?: string;
    singleWireTransferAmountThreshold?: string;
    singleWireTransferAmountThresholdOriginal?: string;
    numOfWireTransfers?: number;
    wireTransferFrequencyThreshold?: number;
    investorType?: InvestorType; //  'RETAIL' | 'INSTITUTIONAL'
    annualIncome?: string;
    annualIncomeMultiplier?: string;
    netWorth?: string;
    netWorthMultiplier?: string;
    monthlyDepositAmount?: string;
    monthlyDepositAmountMultiplier?: string;
    annualTurnover?: string;
    annualTurnoverMultiplier?: string;
    assetsUnderManagement?: string;
    assetsUnderManagementMultiplier?: string;
    netDepositAmount?: string;
    netDepositAmountOriginal?: string;
    ipAddress?: string;
    activityMonitoringTimeWindow?: string;
    reportActivityAcrossMultipleAccounts?: boolean;
    numberOfExternalAccounts?: number;
    externalAccounts?: string[];
    transactionCombinationsToMonitor?: string;
    minNumOfTransactionsThreshold?: number;
    fullBeneficiaryName?: string;
    fullClientNameInUcv?: string;
    accountBalanceBreachAmount?: string;
    accountBalanceBreachAmountOriginal?: string;
    accountBalanceAmountThreshold?: string;
    accountBalanceAmountThresholdOriginal?: string;
    minimumThresholdValue?: string;
    minimumThresholdValueOriginal?: string;
    minFundsTransferredThreshold?: string;
    netFundsTransferredAmount?: string;
    netFundsTransferredAmountOriginal?: string;
    maxTradingAmountThreshold?: string;
    maxTradingAmountThresholdOriginal?: string;
    minActivePeriod?: number;
    actualActivePeriod?: number;
    breachScale?: FeatureRange;
    activityTimeWindow?: number;
    minimumAgeThreshold?: string;
    clientAge?: string;
    alertOnFirstTransaction?: boolean;
    thresholdLimits?: string;
    frequencyPeriod?: string;
    numberOfFrequencies?: number;
    minInternalSpokeCountThreshold?: number;
    minExternalSpokeCountThreshold?: number;
    countRange?: string;
    amountRange?: string;
    transactionType?: ActionType;
    amountThreshold?: string;
    amountThresholdOriginal?: string;
    numberOfChangesThreshold?: number;
    numberOfChangesActual?: number;
    jurisdictionScore?: number;
    jurisdictionScoreThreshold?: number;
    updatedUcvFieldsByCategory?: { [category in CategoryToMonitor]?: UpdatedUcvField[] };
    screenedAddress?: string;
    watchlistAppearances?: string[];
    addressClassification?: string;
    addressFlaggedDate?: number;
    categories?: string[];
    blockchain?: string;
    txnHash?: string;
    scamTypes?: string[];
    monitoredAge?: string;
    appliesTo?: string;

    visualData?: {
        dailyFeatures?: FeatureAggValue[];
        weeklyFeatures?: FeatureAggValue[];
    };

    constructor(dto: ITMGenericExtendedAlertDTO) {
        super(dto);
        super.copyOwn(dto.context.additionalFields);
        this.visualData = dto?.context?.visualData || undefined;
        this.visualDataHandler();

        this.generateFullNameAlertType();
    }

    private generateFullNameAlertType(): void {
        this.fullNameAlertType =
            this.algoVersion && this.algoVersion !== 'V1'
                ? `${this.alertType.name}_${this.algoVersion}`
                : `${this.alertType.name}`;
    }

    private visualDataHandler() {
        if (!this.visualData) {
            return;
        }

        Object.keys(this.visualData)?.forEach(key => {
            if (Array.isArray(this.visualData[key])) {
                this.visualData[key].sort((a, b) => {
                    if (a.featureType === 'transactionAmount' && b.featureType === 'transactionCount') {
                        return -1;
                    } else if (a.featureType === 'transactionCount' && b.featureType === 'transactionAmount') {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            }
        });
    }
}

export enum TmAlertType {
    ACTIVITY_FOR_HIGH_RISK_CLIENT = 'ACTIVITY_FOR_HIGH_RISK_CLIENT',
    HIGH_RISK_JURISDICTION_TRANSACTION = 'HIGH_RISK_JURISDICTION_TRANSACTION',
    MULTIPLE_SIMILAR_TRANSACTIONS = 'MULTIPLE_SIMILAR_TRANSACTIONS',
    HUB_AND_SPOKE = 'HUB_AND_SPOKE',
    HUB_AND_SPOKE_INTERNAL_HUB_TO_EXTERNAL_SPOKES = 'HUB_AND_SPOKE_INTERNAL_HUB_TO_EXTERNAL_SPOKES',
    HUB_AND_SPOKE_INTERNAL_SPOKES_TO_EXTERNAL_HUB = 'HUB_AND_SPOKE_INTERNAL_SPOKES_TO_EXTERNAL_HUB',
    UNUSUAL_WIRE_TRANSFER = 'UNUSUAL_WIRE_TRANSFER',
    UNUSUAL_WIRE_TRANSFER_FREQUENCY = 'UNUSUAL_WIRE_TRANSFER_FREQUENCY',
    UNUSUAL_WIRE_TRANSFER_SINGLE_TRANSACTION = 'UNUSUAL_WIRE_TRANSFER_SINGLE_TRANSACTION',
    KYC_PROFILE_DEVIATION = 'KYC_PROFILE_DEVIATION',
    BENEFICIARY_NAME_MISMATCH = 'BENEFICIARY_NAME_MISMATCH',
    LARGE_ACCOUNT_BALANCE = 'LARGE_ACCOUNT_BALANCE',
    TRANSACTIONS_WITHOUT_ECONOMIC_PURPOSE = 'TRANSACTIONS_WITHOUT_ECONOMIC_PURPOSE',
    IRREGULAR_ACTIVITY_V2 = 'IRREGULAR_ACTIVITY_V2',
    ELDER_ABUSE = 'ELDER_ABUSE',
    MICRO_STRUCTURING = 'MICRO_STRUCTURING',
    CUSTOMER_PROFILE_DEVIATION_V2 = 'CUSTOMER_PROFILE_DEVIATION_V2',
    ACCOUNT_WATCHLIST_ACTIVITY = 'ACCOUNT_WATCHLIST_ACTIVITY',
    FREQUENT_CHANGES_TO_CLIENT_DATA_CATEGORIES = 'FREQUENT_CHANGES_TO_CLIENT_DATA_CATEGORIES',
    FREQUENT_CHANGES_TO_CLIENT_DATA_INDIVIDUAL_FIELDS = 'FREQUENT_CHANGES_TO_CLIENT_DATA_INDIVIDUAL_FIELDS',
    FREQUENT_CHANGES_TO_CLIENT_DATA_IP = 'FREQUENT_CHANGES_TO_CLIENT_DATA_IP',
    DEFAULT = 'DEFAULT',
    SMURFING = 'SMURFING',
    STRUCTURING = 'STRUCTURING',
    CAPITAL_CONTROLS = 'CAPITAL_CONTROLS',
    CAPITAL_CONTROLS_FREQUENCY = 'CAPITAL_CONTROLS_FREQUENCY',
    ACCOUNT_WATCHLIST_ACTIVITY_OCT_V2 = 'ACCOUNT_WATCHLIST_ACTIVITY_OCT_V2',
    ELDER_ABUSE_V2 = 'ELDER_ABUSE_V2'
}

export interface IKycAlertDTO extends IBaseAlertDTO {
    timestamp: number;
    context?: {
        additionalFields?: { [key: string]: unknown };
    };
}

export class KycGenericExtendedAlert extends BaseAlert {
    timestamp: number;
    readonly flaggedAt: number;

    constructor(dto: IKycAlertDTO) {
        super(dto);
        this.copyOwn(dto);
        this.copyContextAdditionalFields(dto);
    }

    copyContextAdditionalFields(dto: IKycAlertDTO): void {
        if (isAnyNullOrUndefined(dto?.context?.additionalFields)) {
            return;
        }
        Object.keys(dto.context.additionalFields).forEach(key => {
            this[key] = dto.context.additionalFields[key];
        });
    }
}

export class AlertTypesByOrigin {
    public MSAlertTypes: AlertType[] = [];
    public TMAlertTypes: AlertType[] = [];
    public KYCAlertTypes: AlertType[] = [];
    private readonly initErrorMsg = 'AlertTypesByOrigin :: Invalid parameters of class';

    constructor(
        alertTypes: AlertType[],
        private log: LoggingService
    ) {
        if (!alertTypes || !log) {
            console.error(this.initErrorMsg);
            return;
        }
        this.MSAlertTypes = alertTypes.filter(at => at.origin === OriginType.MS).sort(this.sortByName);
        this.TMAlertTypes = alertTypes.filter(at => at.origin === OriginType.TM).sort(this.sortByName);
        this.KYCAlertTypes = alertTypes.filter(at => at.origin === OriginType.KYC).sort(this.sortByName);
    }

    get hasMSAlerts(): boolean {
        return this.MSAlertTypes?.length > 0;
    }

    get hasTMAlerts(): boolean {
        return this.TMAlertTypes?.length > 0;
    }

    getAlertsTypesByOrigin(origin: OriginType): AlertType[] {
        switch (origin) {
            case OriginType.MS:
                return this.MSAlertTypes;
            case OriginType.TM:
                return this.TMAlertTypes;
            case OriginType.KYC:
                return this.KYCAlertTypes;
            default:
                this.log.error(`AlertTypesByOrigin :: Not expected origin: ${origin}`);
                return [];
        }
    }

    private sortByName(a: AlertType, b: AlertType): number {
        return a.name.localeCompare(b.name);
    }
}

export enum FormInputType {
    Text = 'text',
    Select = 'select',
    Textarea = 'textarea',
    Number = 'number',
    Date = 'date'
}

export enum GridType {
    Standard = 'standard',
    Double = 'double'
}

export interface FormSelectValue {
    value: string;
    label: string;
    disabled?: boolean;
}

export type FormItemBlock = [PanelFormInput] | [PanelFormInput, PanelFormInput];

export interface PanelFormInput {
    inputType: FormInputType;
    formControlName: string;
    placeholder?: string;
    disabled: boolean;
    options?: Observable<MultiSelectAutocompleteItem[]>;
    label: string;
    defaultValue: string | number;
    validators: ValidatorFn[];
    maxLength?: number;
}

export enum PanelType {
    FormGroup = 'FormGroup',
    FormArray = 'FormArray'
}

export interface BasePanel<T> {
    title?: string;
    subtitle?: string;
    panelTitle?: string;
    expanded?: boolean;
    formGroupName: string;
    panelType: T;
}

export interface Panel extends BasePanel<PanelType.FormGroup> {
    itemsGridClass: GridType;
    formItems: FormItemBlock[];
}

export interface MultiPanel extends BasePanel<PanelType.FormArray> {
    subPanels: Panel[];
}

export type FormPanel = Panel | MultiPanel;

export interface ReportSTRTransaction {
    transaction: {
        transactionId: string;
    };
    partB1: {
        dateOfTransaction: number | Date;
        nightDepositIndicator: string;
        detailFundsInvolvedInInitiatingTransaction: string;
        amountOfTransaction: number;
        transactionCurrency: string;
        howTheTransactionWasConducted: string;
        otherDescription: string;
    };
    partB2: {
        dispositionOfFunds: string;
        amountOfDisposition: number;
        dispositionCurrency: string;
        otherInstitutionNameAndNumberOrOtherPersonOrEntity: string;
        otherPersonOrEntityAccountOrPolicyNumber: string;
        onBehalfOfIndicator: string;
    };
    partC: {
        branchOrTransitNumberWhereAccountHeld: string;
        accountNumber: string;
        accountType: string;
        accountCurrency: string;
        statusOfAccount: string;
    };
    partD: {
        entityClientNumber: string;
    };
    partE: {
        corporationTrustOrOtherEntityName: string;
        typeOfBusiness: string;
        streetAddress: string;
        city: string;
        country: string;
        provinceState: string;
        zipCode: string;
        businessTelephoneNumber: string;
        incorporationNumber: string;
        countryOfIncorporation: string;
        provinceStateOfIncorporation: string;
        individualsAuthorizedToBindEntity: string;
    };
    partF: {
        surname: string;
        givenName: string;
        streetAddress: string;
        city: string;
        country: string;
        provinceState: string;
        zipCode: string;
        homeTelephoneNumber: string;
        officeTelephoneNumber: string;
        individualDateOfBirth: number | Date;
        individualIdentifier: string;
        idNumber: string;
        otherDescription: string;
        countryOfIssue: string;
        provinceStateOfIssue: string;
        individualOccupation: string;
        individualEmployer: string;
        streetAddress2: string;
        city2: string;
        country2: string;
        provinceState2: string;
        zipCode2: string;
        employerBusinessTelephoneNumber: string;
        relationshipToIndividual: string;
    };
}

export interface ReportSTR {
    partA: {
        reportingEntityIdentifierNumber: string;
        reportingEntityLocationNumber: string;
        contactSurname: string;
        contactGivenName: string;
        contactPersonTelephoneNumber: string;
        typeOfReportingEntity: string;
    };
    transactions: ReportSTRTransaction[];
    partG: {
        descriptionOfSuspiciousActivityG: string;
    };
    partH: {
        descriptionOfSuspiciousActivityH: string;
    };
    confirmationId: string;
}

export interface SampleReportSTR {
    partA: ReportSTR['partA'];
    partB1: ReportSTRTransaction['partB1'];
    partB2: ReportSTRTransaction['partB2'];
    partC: ReportSTRTransaction['partC'];
    partD: ReportSTRTransaction['partD'];
    partE: ReportSTRTransaction['partE'];
    partF: ReportSTRTransaction['partF'];
    partG: ReportSTR['partG'];
    partH: ReportSTR['partH'];
}

export type PartOfReportSTR = keyof SampleReportSTR;

export interface AlertPageResolverData<T> {
    alert: T;
    report: ReportSTR;
    reportSample: SampleReportSTR;
    caseDetails: ExtendedCase;
}

export const strCountriesValues: MultiSelectAutocompleteItem[] = [
    {
        name: 'Afghanistan',
        value: 'AF'
    },
    {
        name: 'ĹLand Islands',
        value: 'AX'
    },
    {
        name: 'Albania',
        value: 'AL'
    },
    {
        name: 'Algeria',
        value: 'DZ'
    },
    {
        name: 'American Samoa',
        value: 'AS'
    },
    {
        name: 'Andorra',
        value: 'AD'
    },
    {
        name: 'Angola',
        value: 'AO'
    },
    {
        name: 'Anguilla',
        value: 'AI'
    },
    {
        name: 'Antarctica',
        value: 'AQ'
    },
    {
        name: 'Antigua And Barbuda',
        value: 'AG'
    },
    {
        name: 'Argentina',
        value: 'AR'
    },
    {
        name: 'Armenia',
        value: 'AM'
    },
    {
        name: 'Aruba',
        value: 'AW'
    },
    {
        name: 'Australia',
        value: 'AU'
    },
    {
        name: 'Austria',
        value: 'AT'
    },
    {
        name: 'Azerbaijan',
        value: 'AZ'
    },
    {
        name: 'Bahamas',
        value: 'BS'
    },
    {
        name: 'Bahrain',
        value: 'BH'
    },
    {
        name: 'Bangladesh',
        value: 'BD'
    },
    {
        name: 'Barbados',
        value: 'BB'
    },
    {
        name: 'Belarus',
        value: 'BY'
    },
    {
        name: 'Belgium',
        value: 'BE'
    },
    {
        name: 'Belize',
        value: 'BZ'
    },
    {
        name: 'Benin',
        value: 'BJ'
    },
    {
        name: 'Bermuda',
        value: 'BM'
    },
    {
        name: 'Bhutan',
        value: 'BT'
    },
    {
        name: 'Bolivia (Plurinational State Of)',
        value: 'BO'
    },
    {
        name: 'Bonaire (Sint Eustatius And Saba)',
        value: 'BQ'
    },
    {
        name: 'Bosnia And Herzegowina',
        value: 'BA'
    },
    {
        name: 'Botswana',
        value: 'BW'
    },
    {
        name: 'Bouvet Island',
        value: 'BV'
    },
    {
        name: 'Brazil',
        value: 'BR'
    },
    {
        name: 'British Indian Ocean Territory',
        value: 'IO'
    },
    {
        name: 'Brunei Darussalam',
        value: 'BN'
    },
    {
        name: 'Bulgaria',
        value: 'BG'
    },
    {
        name: 'Burkina Faso',
        value: 'BF'
    },
    {
        name: 'Burundi',
        value: 'BI'
    },
    {
        name: 'Cabo Verde',
        value: 'CV'
    },
    {
        name: 'Cambodia',
        value: 'KH'
    },
    {
        name: 'Cameroon',
        value: 'CM'
    },
    {
        name: 'Canada',
        value: 'CA'
    },
    {
        name: 'Cayman Islands',
        value: 'KY'
    },
    {
        name: 'Central African Republic',
        value: 'CF'
    },
    {
        name: 'Chad',
        value: 'TD'
    },
    {
        name: 'Chile',
        value: 'CL'
    },
    {
        name: 'China',
        value: 'CN'
    },
    {
        name: 'Christmas Island',
        value: 'CX'
    },
    {
        name: 'Cocos (Keeling) Islands',
        value: 'CC'
    },
    {
        name: 'Colombia',
        value: 'CO'
    },
    {
        name: 'Comoros (The)',
        value: 'KM'
    },
    {
        name: 'Congo (The Democratic Republic Of The)',
        value: 'CD'
    },
    {
        name: 'Congo (The)',
        value: 'CG'
    },
    {
        name: 'Cook Islands',
        value: 'CK'
    },
    {
        name: 'Costa Rica',
        value: 'CR'
    },
    {
        name: "Cote D'ivoire (Ivory Coast)",
        value: 'CI'
    },
    {
        name: 'Croatia (Hrvatska)',
        value: 'HR'
    },
    {
        name: 'Cuba',
        value: 'CU'
    },
    {
        name: 'Curaçao',
        value: 'CW'
    },
    {
        name: 'Cyprus',
        value: 'CY'
    },
    {
        name: 'Czech Republic',
        value: 'CZ'
    },
    {
        name: 'Denmark',
        value: 'DK'
    },
    {
        name: 'Djibouti',
        value: 'DJ'
    },
    {
        name: 'Dominica',
        value: 'DM'
    },
    {
        name: 'Dominican Republic',
        value: 'DO'
    },
    {
        name: 'Ecuador',
        value: 'EC'
    },
    {
        name: 'Egypt',
        value: 'EG'
    },
    {
        name: 'El Salvador',
        value: 'SV'
    },
    {
        name: 'Equatorial Guinea',
        value: 'GQ'
    },
    {
        name: 'Eritrea',
        value: 'ER'
    },
    {
        name: 'Estonia',
        value: 'EE'
    },
    {
        name: 'Eswatini',
        value: 'SZ'
    },
    {
        name: 'Ethiopia',
        value: 'ET'
    },
    {
        name: 'Falkland Islands (Malvinas)',
        value: 'FK'
    },
    {
        name: 'Faroe Islands',
        value: 'FO'
    },
    {
        name: 'Fiji',
        value: 'FJ'
    },
    {
        name: 'Finland',
        value: 'FI'
    },
    {
        name: 'France',
        value: 'FR'
    },
    {
        name: 'France, Metropolitan',
        value: 'FX'
    },
    {
        name: 'French Guiana',
        value: 'GF'
    },
    {
        name: 'French Polynesia',
        value: 'PF'
    },
    {
        name: 'French Southern Territories',
        value: 'TF'
    },
    {
        name: 'Gabon',
        value: 'GA'
    },
    {
        name: 'Gambia',
        value: 'GM'
    },
    {
        name: 'Georgia',
        value: 'GE'
    },
    {
        name: 'Germany',
        value: 'DE'
    },
    {
        name: 'Ghana',
        value: 'GH'
    },
    {
        name: 'Gibraltar',
        value: 'GI'
    },
    {
        name: 'Greece',
        value: 'GR'
    },
    {
        name: 'Greenland',
        value: 'GL'
    },
    {
        name: 'Grenada',
        value: 'GD'
    },
    {
        name: 'Guadeloupe',
        value: 'GP'
    },
    {
        name: 'Guam',
        value: 'GU'
    },
    {
        name: 'Guatemala',
        value: 'GT'
    },
    {
        name: 'Guernsey, C.i.',
        value: 'GG'
    },
    {
        name: 'Guinea',
        value: 'GN'
    },
    {
        name: 'Guinea-bissau',
        value: 'GW'
    },
    {
        name: 'Guyana',
        value: 'GY'
    },
    {
        name: 'Haiti',
        value: 'HT'
    },
    {
        name: 'Heard And Mc Donald Islands',
        value: 'HM'
    },
    {
        name: 'Holy See (The)',
        value: 'VA'
    },
    {
        name: 'Honduras',
        value: 'HN'
    },
    {
        name: 'Hong Kong',
        value: 'HK'
    },
    {
        name: 'Hungary',
        value: 'HU'
    },
    {
        name: 'Iceland',
        value: 'IS'
    },
    {
        name: 'India',
        value: 'IN'
    },
    {
        name: 'Indonesia',
        value: 'ID'
    },
    {
        name: 'Iran (Islamic Republic Of)',
        value: 'IR'
    },
    {
        name: 'Iraq',
        value: 'IQ'
    },
    {
        name: 'Ireland',
        value: 'IE'
    },
    {
        name: 'Isle Of Man',
        value: 'IM'
    },
    {
        name: 'Israel',
        value: 'IL'
    },
    {
        name: 'Italy',
        value: 'IT'
    },
    {
        name: 'Jamaica',
        value: 'JM'
    },
    {
        name: 'Japan',
        value: 'JP'
    },
    {
        name: 'Jersey, C.i.',
        value: 'JE'
    },
    {
        name: 'Jordan',
        value: 'JO'
    },
    {
        name: 'Kazakhstan',
        value: 'KZ'
    },
    {
        name: 'Kenya',
        value: 'KE'
    },
    {
        name: 'Kiribati',
        value: 'KI'
    },
    {
        name: "Korea (The Democratic People's Republic Of)",
        value: 'KP'
    },
    {
        name: 'Korea (The Republic Of)',
        value: 'KR'
    },
    {
        name: 'Kuwait',
        value: 'KW'
    },
    {
        name: 'Kyrgyzstan',
        value: 'KG'
    },
    {
        name: "Lao People's Dem. Republic",
        value: 'LA'
    },
    {
        name: 'Latvia',
        value: 'LV'
    },
    {
        name: 'Lebanon',
        value: 'LB'
    },
    {
        name: 'Lesotho',
        value: 'LS'
    },
    {
        name: 'Liberia',
        value: 'LR'
    },
    {
        name: 'Libya (Libyan Arab Jamahiriya)',
        value: 'LY'
    },
    {
        name: 'Liechtenstein',
        value: 'LI'
    },
    {
        name: 'Lithuania',
        value: 'LT'
    },
    {
        name: 'Luxembourg',
        value: 'LU'
    },
    {
        name: 'Macao',
        value: 'MO'
    },
    {
        name: 'Macedonia (Former Yugoslav Republic)',
        value: 'MK'
    },
    {
        name: 'Madagascar',
        value: 'MG'
    },
    {
        name: 'Malawi',
        value: 'MW'
    },
    {
        name: 'Malaysia',
        value: 'MY'
    },
    {
        name: 'Maldives',
        value: 'MV'
    },
    {
        name: 'Mali',
        value: 'ML'
    },
    {
        name: 'Malta',
        value: 'MT'
    },
    {
        name: 'Marshall Islands',
        value: 'MH'
    },
    {
        name: 'Martinique',
        value: 'MQ'
    },
    {
        name: 'Mauritania',
        value: 'MR'
    },
    {
        name: 'Mauritius',
        value: 'MU'
    },
    {
        name: 'Mayotte',
        value: 'YT'
    },
    {
        name: 'Mexico',
        value: 'MX'
    },
    {
        name: 'Micronesia (Federated States Of)',
        value: 'FM'
    },
    {
        name: 'Moldova (The Republic Of)',
        value: 'MD'
    },
    {
        name: 'Monaco',
        value: 'MC'
    },
    {
        name: 'Mongolia',
        value: 'MN'
    },
    {
        name: 'Montenegro',
        value: 'ME'
    },
    {
        name: 'Montserrat',
        value: 'MS'
    },
    {
        name: 'Morocco',
        value: 'MA'
    },
    {
        name: 'Mozambique',
        value: 'MZ'
    },
    {
        name: 'Myanmar',
        value: 'MM'
    },
    {
        name: 'Namibia',
        value: 'NA'
    },
    {
        name: 'Nauru',
        value: 'NR'
    },
    {
        name: 'Nepal',
        value: 'NP'
    },
    {
        name: 'Netherlands',
        value: 'NL'
    },
    {
        name: 'Netherlands Antilles',
        value: 'AN'
    },
    {
        name: 'New Caledonia',
        value: 'NC'
    },
    {
        name: 'New Zealand',
        value: 'NZ'
    },
    {
        name: 'Nicaragua',
        value: 'NI'
    },
    {
        name: 'Niger',
        value: 'NE'
    },
    {
        name: 'Nigeria',
        value: 'NG'
    },
    {
        name: 'Niue',
        value: 'NU'
    },
    {
        name: 'Norfolk Island',
        value: 'NF'
    },
    {
        name: 'Northern Mariana Islands',
        value: 'MP'
    },
    {
        name: 'Norway',
        value: 'NO'
    },
    {
        name: 'Oman',
        value: 'OM'
    },
    {
        name: 'Pakistan',
        value: 'PK'
    },
    {
        name: 'Palau',
        value: 'PW'
    },
    {
        name: 'Palestine (State Of)',
        value: 'PS'
    },
    {
        name: 'Panama',
        value: 'PA'
    },
    {
        name: 'Papua New Guinea',
        value: 'PG'
    },
    {
        name: 'Paraguay',
        value: 'PY'
    },
    {
        name: 'Peru',
        value: 'PE'
    },
    {
        name: 'Philippines',
        value: 'PH'
    },
    {
        name: 'Pitcairn',
        value: 'PN'
    },
    {
        name: 'Poland',
        value: 'PL'
    },
    {
        name: 'Portugal',
        value: 'PT'
    },
    {
        name: 'Puerto Rico',
        value: 'PR'
    },
    {
        name: 'Qatar',
        value: 'QA'
    },
    {
        name: 'Reunion Island',
        value: 'RE'
    },
    {
        name: 'Romania',
        value: 'RO'
    },
    {
        name: 'Russian Federation (The)',
        value: 'RU'
    },
    {
        name: 'Rwanda',
        value: 'RW'
    },
    {
        name: 'Saint Barthélemy',
        value: 'BL'
    },
    {
        name: 'Saint Helena',
        value: 'SH'
    },
    {
        name: 'Saint Kitts And Nevis',
        value: 'KN'
    },
    {
        name: 'Saint Lucia',
        value: 'LC'
    },
    {
        name: 'Saint Martin (French Part)',
        value: 'MF'
    },
    {
        name: 'Saint Pierre And Miquelon',
        value: 'PM'
    },
    {
        name: 'Saint Vincent And The Grenadines',
        value: 'VC'
    },
    {
        name: 'Samoa',
        value: 'WS'
    },
    {
        name: 'San Marino',
        value: 'SM'
    },
    {
        name: 'Sao Tome And Principe',
        value: 'ST'
    },
    {
        name: 'Saudi Arabia',
        value: 'SA'
    },
    {
        name: 'Senegal',
        value: 'SN'
    },
    {
        name: 'Serbia ',
        value: 'RS'
    },
    {
        name: 'Serbia And Montenegro',
        value: 'CS'
    },
    {
        name: 'Seychelles',
        value: 'SC'
    },
    {
        name: 'Sierra Leone',
        value: 'SL'
    },
    {
        name: 'Singapore',
        value: 'SG'
    },
    {
        name: 'Sint Maarten (Dutch Part)',
        value: 'SX'
    },
    {
        name: 'Slovakia (Slovak Republic)',
        value: 'SK'
    },
    {
        name: 'Slovenia',
        value: 'SI'
    },
    {
        name: 'Solomon Islands',
        value: 'SB'
    },
    {
        name: 'Somalia',
        value: 'SO'
    },
    {
        name: 'South Africa',
        value: 'ZA'
    },
    {
        name: 'South Georgia & The South Sandwich Islands',
        value: 'GS'
    },
    {
        name: 'South Sudan',
        value: 'SS'
    },
    {
        name: 'Spain',
        value: 'ES'
    },
    {
        name: 'Sri Lanka',
        value: 'LK'
    },
    {
        name: 'Sudan',
        value: 'SD'
    },
    {
        name: 'Suriname',
        value: 'SR'
    },
    {
        name: 'Svalbard And Jan Mayen Islands',
        value: 'SJ'
    },
    {
        name: 'Sweden',
        value: 'SE'
    },
    {
        name: 'Switzerland',
        value: 'CH'
    },
    {
        name: 'Syria (Syrian Arab Republic)',
        value: 'SY'
    },
    {
        name: 'Taiwan',
        value: 'TW'
    },
    {
        name: 'Tajikistan',
        value: 'TJ'
    },
    {
        name: 'Tanzania (United Republic Of)',
        value: 'TZ'
    },
    {
        name: 'Thailand',
        value: 'TH'
    },
    {
        name: 'Timor-leste (East Timor)',
        value: 'TL'
    },
    {
        name: 'Togo',
        value: 'TG'
    },
    {
        name: 'Tokelau',
        value: 'TK'
    },
    {
        name: 'Tonga',
        value: 'TO'
    },
    {
        name: 'Trinidad And Tobago',
        value: 'TT'
    },
    {
        name: 'Tunisia',
        value: 'TN'
    },
    {
        name: 'Turkey',
        value: 'TR'
    },
    {
        name: 'Turkmenistan',
        value: 'TM'
    },
    {
        name: 'Turks And Caicos Islands (The)',
        value: 'TC'
    },
    {
        name: 'Tuvalu',
        value: 'TV'
    },
    {
        name: 'Uganda',
        value: 'UG'
    },
    {
        name: 'Ukraine',
        value: 'UA'
    },
    {
        name: 'United Arab Emirates (The)',
        value: 'AE'
    },
    {
        name: 'United Kingdom (The)',
        value: 'GB'
    },
    {
        name: 'United States Minor Outlying Islands',
        value: 'UM'
    },
    {
        name: 'United States Of America',
        value: 'US'
    },
    {
        name: 'Uruguay',
        value: 'UY'
    },
    {
        name: 'Uzbekistan',
        value: 'UZ'
    },
    {
        name: 'Vanuatu',
        value: 'VU'
    },
    {
        name: 'Venezuela (Bolivarian Republic Of)',
        value: 'VE'
    },
    {
        name: 'Viet Nam',
        value: 'VN'
    },
    {
        name: 'Virgin Islands (British)',
        value: 'VG'
    },
    {
        name: 'Virgin Islands (U.s.)',
        value: 'VI'
    },
    {
        name: 'Wallis And Futuna Islands',
        value: 'WF'
    },
    {
        name: 'Western Sahara',
        value: 'EH'
    },
    {
        name: 'Yemen',
        value: 'YE'
    },
    {
        name: 'Zambia',
        value: 'ZM'
    },
    {
        name: 'Zimbabwe',
        value: 'ZW'
    }
];

export const strCurrencyValues = [
    {
        name: 'Andorran Pesata (ADP)',
        value: 'ADP'
    },
    {
        name: 'Uae Dirham (AED)',
        value: 'AED'
    },
    {
        name: 'Afghani (AFA)',
        value: 'AFA'
    },
    {
        name: 'Afghani (AFN)',
        value: 'AFN'
    },
    {
        name: 'Lek (ALL)',
        value: 'ALL'
    },
    {
        name: 'Armenian Dram (AMD)',
        value: 'AMD'
    },
    {
        name: 'Netherlands Antillian Guilder (ANG)',
        value: 'ANG'
    },
    {
        name: 'Kwanza (AOA)',
        value: 'AOA'
    },
    {
        name: 'Kwanza (AOK)',
        value: 'AOK'
    },
    {
        name: 'New Kwanza (AON)',
        value: 'AON'
    },
    {
        name: 'Argentine Peso (ARP)',
        value: 'ARP'
    },
    {
        name: 'Argentine Peso (ARS)',
        value: 'ARS'
    },
    {
        name: 'Schilling  (ATS)',
        value: 'ATS'
    },
    {
        name: 'Australian Dollar (AUD)',
        value: 'AUD'
    },
    {
        name: 'Aruba Florin (AWF)',
        value: 'AWF'
    },
    {
        name: 'Aruban Florin (AWG)',
        value: 'AWG'
    },
    {
        name: 'Manat (AZM)',
        value: 'AZM'
    },
    {
        name: 'Azerbaijan Manat (AZN)',
        value: 'AZN'
    },
    {
        name: 'Convertible Mark (BAK)',
        value: 'BAK'
    },
    {
        name: 'Convertible Mark (BAM)',
        value: 'BAM'
    },
    {
        name: 'Barbados Dollar (BBD)',
        value: 'BBD'
    },
    {
        name: 'Taka (BDT)',
        value: 'BDT'
    },
    {
        name: 'Belgian Franc (Convertible) (BEC)',
        value: 'BEC'
    },
    {
        name: 'Belgian Franc  (BEF)',
        value: 'BEF'
    },
    {
        name: 'Belgian Franc (Financial) (BEL)',
        value: 'BEL'
    },
    {
        name: 'Lev (BGL)',
        value: 'BGL'
    },
    {
        name: 'Bulgarian Lev (BGN)',
        value: 'BGN'
    },
    {
        name: 'Bahraini Dinar (BHD)',
        value: 'BHD'
    },
    {
        name: 'Burundi Franc (BIF)',
        value: 'BIF'
    },
    {
        name: 'Bermudian Dollar (BMD)',
        value: 'BMD'
    },
    {
        name: 'Brunei Dollar (BND)',
        value: 'BND'
    },
    {
        name: 'Boliviano (BOB)',
        value: 'BOB'
    },
    {
        name: 'Bolivian Peso (BOP)',
        value: 'BOP'
    },
    {
        name: 'Mvdol (BOV)',
        value: 'BOV'
    },
    {
        name: 'Cruzeiro (BRC)',
        value: 'BRC'
    },
    {
        name: 'Brazilian Real (BRL)',
        value: 'BRL'
    },
    {
        name: 'Bahamian Dollar (BSD)',
        value: 'BSD'
    },
    {
        name: 'Ngultrum (BTN)',
        value: 'BTN'
    },
    {
        name: 'Bhutan Rupee (BTR)',
        value: 'BTR'
    },
    {
        name: 'Kyat (BUK)',
        value: 'BUK'
    },
    {
        name: 'Pula (BWP)',
        value: 'BWP'
    },
    {
        name: 'Belarusian Ruble (BYN)',
        value: 'BYN'
    },
    {
        name: 'Belarusian Ruble (BYR)',
        value: 'BYR'
    },
    {
        name: 'Belize Dollar (BZD)',
        value: 'BZD'
    },
    {
        name: 'Canadian Dollar (CAD)',
        value: 'CAD'
    },
    {
        name: 'Congolese Franc (CDF)',
        value: 'CDF'
    },
    {
        name: 'New Zaire (CDZ)',
        value: 'CDZ'
    },
    {
        name: 'Wir Euro  (CHE)',
        value: 'CHE'
    },
    {
        name: 'Swiss Franc (CHF)',
        value: 'CHF'
    },
    {
        name: 'Wir Franc  (CHW)',
        value: 'CHW'
    },
    {
        name: 'Unidades De Formento (CLF)',
        value: 'CLF'
    },
    {
        name: 'Chilean Peso (CLP)',
        value: 'CLP'
    },
    {
        name: 'Yuan Renminbi (CNY)',
        value: 'CNY'
    },
    {
        name: 'Colombian Peso (COP)',
        value: 'COP'
    },
    {
        name: 'Unidad De Valor Real  (COU)',
        value: 'COU'
    },
    {
        name: 'Costa Rican Colon (CRC)',
        value: 'CRC'
    },
    {
        name: 'Serbian Dinar (CSD)',
        value: 'CSD'
    },
    {
        name: 'Koruna (CSK)',
        value: 'CSK'
    },
    {
        name: 'Peso Convertible (CUC)',
        value: 'CUC'
    },
    {
        name: 'Cuban Peso (CUP)',
        value: 'CUP'
    },
    {
        name: 'Cape Verde Escudo (CVE)',
        value: 'CVE'
    },
    {
        name: 'Cyprus Pound (CYP)',
        value: 'CYP'
    },
    {
        name: 'Czech Koruna (CZK)',
        value: 'CZK'
    },
    {
        name: 'Mark Der Ddr (DDM)',
        value: 'DDM'
    },
    {
        name: 'Deutsche Mark (DEM)',
        value: 'DEM'
    },
    {
        name: 'Djibouti Franc (DJF)',
        value: 'DJF'
    },
    {
        name: 'Danish Krone (DKK)',
        value: 'DKK'
    },
    {
        name: 'Dominican Peso (DOP)',
        value: 'DOP'
    },
    {
        name: 'Algerian Dinar (DZD)',
        value: 'DZD'
    },
    {
        name: 'Sucre (ECS)',
        value: 'ECS'
    },
    {
        name: 'Kroon (EEK)',
        value: 'EEK'
    },
    {
        name: 'Egyptian Pound (EGP)',
        value: 'EGP'
    },
    {
        name: 'Nakfa (ERN)',
        value: 'ERN'
    },
    {
        name: 'Spanish Peseta (ESP)',
        value: 'ESP'
    },
    {
        name: 'Ethiopian Birr (ETB)',
        value: 'ETB'
    },
    {
        name: 'Euro (EUR)',
        value: 'EUR'
    },
    {
        name: 'Markka (FIM)',
        value: 'FIM'
    },
    {
        name: 'Fiji Dollar (FJD)',
        value: 'FJD'
    },
    {
        name: 'Falkland Islands Pound (FKP)',
        value: 'FKP'
    },
    {
        name: 'French Franc (FRF)',
        value: 'FRF'
    },
    {
        name: 'Pound Sterling (GBP)',
        value: 'GBP'
    },
    {
        name: 'Lari (GEL)',
        value: 'GEL'
    },
    {
        name: 'Cedi (GHC)',
        value: 'GHC'
    },
    {
        name: 'Ghana Cedi (GHS)',
        value: 'GHS'
    },
    {
        name: 'Gibraltar Pound (GIP)',
        value: 'GIP'
    },
    {
        name: 'Dalasi (GMD)',
        value: 'GMD'
    },
    {
        name: 'Guinean Franc (GNF)',
        value: 'GNF'
    },
    {
        name: 'Syli (GNS)',
        value: 'GNS'
    },
    {
        name: 'Ekwele (GQE)',
        value: 'GQE'
    },
    {
        name: 'Drachma (GRD)',
        value: 'GRD'
    },
    {
        name: 'Quetzal (GTQ)',
        value: 'GTQ'
    },
    {
        name: 'Guinea-bissau Peso (GWP)',
        value: 'GWP'
    },
    {
        name: 'Guyana Dollar (GYD)',
        value: 'GYD'
    },
    {
        name: 'Hong Kong Dollar (HKD)',
        value: 'HKD'
    },
    {
        name: 'Lempira (HNL)',
        value: 'HNL'
    },
    {
        name: 'Croatian Kuna (HRK)',
        value: 'HRK'
    },
    {
        name: 'Gourde (HTG)',
        value: 'HTG'
    },
    {
        name: 'Forint (HUF)',
        value: 'HUF'
    },
    {
        name: 'Rupiah (IDR)',
        value: 'IDR'
    },
    {
        name: 'Irish Pound (IEP)',
        value: 'IEP'
    },
    {
        name: 'New Israeli Sheqel (ILS)',
        value: 'ILS'
    },
    {
        name: 'Indian Rupee (INR)',
        value: 'INR'
    },
    {
        name: 'Iraqi Dinar (IQD)',
        value: 'IQD'
    },
    {
        name: 'Iranian Rial (IRR)',
        value: 'IRR'
    },
    {
        name: 'Iceland Krona (ISK)',
        value: 'ISK'
    },
    {
        name: 'Lira (ITL)',
        value: 'ITL'
    },
    {
        name: 'Jamaican Dollar (JMD)',
        value: 'JMD'
    },
    {
        name: 'Jordanian Dinar (JOD)',
        value: 'JOD'
    },
    {
        name: 'Yen (JPY)',
        value: 'JPY'
    },
    {
        name: 'Kenyan Shilling (KES)',
        value: 'KES'
    },
    {
        name: 'Som (KGS)',
        value: 'KGS'
    },
    {
        name: 'Riel (KHR)',
        value: 'KHR'
    },
    {
        name: 'Comorian Franc (KMF)',
        value: 'KMF'
    },
    {
        name: 'North Korean Won (KPW)',
        value: 'KPW'
    },
    {
        name: 'Won (KRW)',
        value: 'KRW'
    },
    {
        name: 'Kuwaiti Dinar (KWD)',
        value: 'KWD'
    },
    {
        name: 'Cayman Islands Dollar (KYD)',
        value: 'KYD'
    },
    {
        name: 'Tenge (KZT)',
        value: 'KZT'
    },
    {
        name: 'Lao Kip (LAK)',
        value: 'LAK'
    },
    {
        name: 'Lebanese Pound (LBP)',
        value: 'LBP'
    },
    {
        name: 'Sri Lankan Rupee (LKR)',
        value: 'LKR'
    },
    {
        name: 'Liberian Dollar (LRD)',
        value: 'LRD'
    },
    {
        name: 'Loti (LSL)',
        value: 'LSL'
    },
    {
        name: 'Maloti (LSM)',
        value: 'LSM'
    },
    {
        name: 'Lithuanian Litas (LTL)',
        value: 'LTL'
    },
    {
        name: 'Luxembourg Franc (LUF)',
        value: 'LUF'
    },
    {
        name: 'Lat (LVL)',
        value: 'LVL'
    },
    {
        name: 'Libyan Dinar (LYD)',
        value: 'LYD'
    },
    {
        name: 'Moroccan Dirham (MAD)',
        value: 'MAD'
    },
    {
        name: 'Molodavan Leu (MDL)',
        value: 'MDL'
    },
    {
        name: 'Malagasy Ariary  (MGA)',
        value: 'MGA'
    },
    {
        name: 'Malagasy Franc (MGF)',
        value: 'MGF'
    },
    {
        name: 'Macedonia Denar (MKD)',
        value: 'MKD'
    },
    {
        name: 'Mali Franc (MLF)',
        value: 'MLF'
    },
    {
        name: 'Kyat (MMK)',
        value: 'MMK'
    },
    {
        name: 'Tugrik (MNT)',
        value: 'MNT'
    },
    {
        name: 'Pataca (MOP)',
        value: 'MOP'
    },
    {
        name: 'Ouguiya (MRU)',
        value: 'MRU'
    },
    {
        name: 'Ouguiya (MRO)',
        value: 'MRO'
    },
    {
        name: 'Maltese Lira (MTL)',
        value: 'MTL'
    },
    {
        name: 'Maltese Pound (MTP)',
        value: 'MTP'
    },
    {
        name: 'Mauritian Rupee (MUR)',
        value: 'MUR'
    },
    {
        name: 'Rufiyaa (MVR)',
        value: 'MVR'
    },
    {
        name: 'Malawi Kwacha (MWK)',
        value: 'MWK'
    },
    {
        name: 'Mexican Peso (MXN)',
        value: 'MXN'
    },
    {
        name: 'Mexican Peso (MXP)',
        value: 'MXP'
    },
    {
        name: 'Mexican Unidad De Inversion (Udi) (MXV)',
        value: 'MXV'
    },
    {
        name: 'Malaysian Ringgit (MYR)',
        value: 'MYR'
    },
    {
        name: 'Metical (MZM)',
        value: 'MZM'
    },
    {
        name: 'Mozambique Metical (MZN)',
        value: 'MZN'
    },
    {
        name: 'Namibian Dollar (NAD)',
        value: 'NAD'
    },
    {
        name: 'Naira (NGN)',
        value: 'NGN'
    },
    {
        name: 'Cordoba (NIC)',
        value: 'NIC'
    },
    {
        name: 'Cordoba Oro (NIO)',
        value: 'NIO'
    },
    {
        name: 'Netherlands Guilder (NLG)',
        value: 'NLG'
    },
    {
        name: 'Norwegian Krone (NOK)',
        value: 'NOK'
    },
    {
        name: 'Nepalese Rupee (NPR)',
        value: 'NPR'
    },
    {
        name: 'New Zealand Dollar (NZD)',
        value: 'NZD'
    },
    {
        name: 'Omani Rial (OMR)',
        value: 'OMR'
    },
    {
        name: 'Balboa (PAB)',
        value: 'PAB'
    },
    {
        name: 'Sol (PEN)',
        value: 'PEN'
    },
    {
        name: 'Sol (PES)',
        value: 'PES'
    },
    {
        name: 'Kina (PGK)',
        value: 'PGK'
    },
    {
        name: 'Philippine Peso (PHP)',
        value: 'PHP'
    },
    {
        name: 'Pakistan Rupee (PKR)',
        value: 'PKR'
    },
    {
        name: 'Zloty (PLN)',
        value: 'PLN'
    },
    {
        name: 'Zloty (PLZ)',
        value: 'PLZ'
    },
    {
        name: 'Portuguese Escudo (PTE)',
        value: 'PTE'
    },
    {
        name: 'Guarani (PYG)',
        value: 'PYG'
    },
    {
        name: 'Qatari Rial (QAR)',
        value: 'QAR'
    },
    {
        name: 'Leu (ROL)',
        value: 'ROL'
    },
    {
        name: 'New Romanian Leu (RON)',
        value: 'RON'
    },
    {
        name: 'Serbian Dinar (RSD)',
        value: 'RSD'
    },
    {
        name: 'Russian Ruble (RUB)',
        value: 'RUB'
    },
    {
        name: 'Russian Ruble (RUR)',
        value: 'RUR'
    },
    {
        name: 'Rwandan Franc (RWF)',
        value: 'RWF'
    },
    {
        name: 'Saudi Riyal (SAR)',
        value: 'SAR'
    },
    {
        name: 'Solomon Islands Dollar (SBD)',
        value: 'SBD'
    },
    {
        name: 'Luigino (SBL)',
        value: 'SBL'
    },
    {
        name: 'Seychelles Rupee (SCR)',
        value: 'SCR'
    },
    {
        name: 'Sudanese Dinar (SDD)',
        value: 'SDD'
    },
    {
        name: 'Sudanese Pound (SDG)',
        value: 'SDG'
    },
    {
        name: 'Sudanese Pound (SDP)',
        value: 'SDP'
    },
    {
        name: 'Swedish Krona (SEK)',
        value: 'SEK'
    },
    {
        name: 'Singapore Dollar (SGD)',
        value: 'SGD'
    },
    {
        name: 'Saint Helena Pound (SHP)',
        value: 'SHP'
    },
    {
        name: 'Tolar (SIT)',
        value: 'SIT'
    },
    {
        name: 'Slovak Koruna (SKK)',
        value: 'SKK'
    },
    {
        name: 'Leone (SLL)',
        value: 'SLL'
    },
    {
        name: 'Somali Shilling (SOS)',
        value: 'SOS'
    },
    {
        name: 'Surinam Dollar (SRD)',
        value: 'SRD'
    },
    {
        name: 'Suriname Guilder (SRG)',
        value: 'SRG'
    },
    {
        name: 'South Sudanese Pound (SSP)',
        value: 'SSP'
    },
    {
        name: 'Dobra (STD)',
        value: 'STD'
    },
    {
        name: 'Dobra (STN)',
        value: 'STN'
    },
    {
        name: 'Ruble (SUR)',
        value: 'SUR'
    },
    {
        name: 'El Salvador Colon (SVC)',
        value: 'SVC'
    },
    {
        name: 'Syrian Pound (SYP)',
        value: 'SYP'
    },
    {
        name: 'Lilangeni (SZL)',
        value: 'SZL'
    },
    {
        name: 'Baht (THB)',
        value: 'THB'
    },
    {
        name: 'Tajikistani Ruble (TJR)',
        value: 'TJR'
    },
    {
        name: 'Somoni (TJS)',
        value: 'TJS'
    },
    {
        name: 'Manat  (TMM)',
        value: 'TMM'
    },
    {
        name: 'Turkmenistan New Manat (TMT)',
        value: 'TMT'
    },
    {
        name: 'Tunisian Dinar (TND)',
        value: 'TND'
    },
    {
        name: "Pa'anga (TOP)",
        value: 'TOP'
    },
    {
        name: 'Timor Escudo (TPE)',
        value: 'TPE'
    },
    {
        name: 'Old Turkish Lira (TRL)',
        value: 'TRL'
    },
    {
        name: 'Turkish Lira (TRY)',
        value: 'TRY'
    },
    {
        name: 'Trinidad & Tobago Dollar (TTD)',
        value: 'TTD'
    },
    {
        name: 'New Taiwan Dollar (TWD)',
        value: 'TWD'
    },
    {
        name: 'Tanzanian Shilling (TZS)',
        value: 'TZS'
    },
    {
        name: 'Hryvnia (UAH)',
        value: 'UAH'
    },
    {
        name: 'Uganda Shilling (UGS)',
        value: 'UGS'
    },
    {
        name: 'Uganda Shilling (UGX)',
        value: 'UGX'
    },
    {
        name: 'Us Dollar (USD)',
        value: 'USD'
    },
    {
        name: 'Us Dollar (Next Day) (USN)',
        value: 'USN'
    },
    {
        name: 'Us Dollar (Same Day) (USS)',
        value: 'USS'
    },
    {
        name: 'Uruguay Peso En Unidades Indexadas (Ui) (UYI)',
        value: 'UYI'
    },
    {
        name: 'Uruguayan Peso (UYP)',
        value: 'UYP'
    },
    {
        name: 'Peso Urugayo (UYU)',
        value: 'UYU'
    },
    {
        name: 'Unidad Previsional (UYW)',
        value: 'UYW'
    },
    {
        name: 'Uzbekistan Som (UZS)',
        value: 'UZS'
    },
    {
        name: 'Bolivar (VEB)',
        value: 'VEB'
    },
    {
        name: 'Bolivar (VEF)',
        value: 'VEF'
    },
    {
        name: 'Bolívar (VES)',
        value: 'VES'
    },
    {
        name: 'Dong (VND)',
        value: 'VND'
    },
    {
        name: 'Vatu (VUV)',
        value: 'VUV'
    },
    {
        name: 'Tala (WST)',
        value: 'WST'
    },
    {
        name: 'Cfa Franc Beac Communauté Financière Africaine (XAF)',
        value: 'XAF'
    },
    {
        name: 'Silver (XAG)',
        value: 'XAG'
    },
    {
        name: 'Gold (XAU)',
        value: 'XAU'
    },
    {
        name: 'Bond Markets Units European Composite Unit (Eurco) (XBA)',
        value: 'XBA'
    },
    {
        name: 'European Monetary Unit (E.m.u.– 6) (XBB)',
        value: 'XBB'
    },
    {
        name: 'Euro. Unit Of Acct. 9 (E.u.a.-9) (XBC)',
        value: 'XBC'
    },
    {
        name: 'Euro. Unit Of Acct. 17 (E.u.a.-17) (XBD)',
        value: 'XBD'
    },
    {
        name: 'East Caribbean Dollar (XCD)',
        value: 'XCD'
    },
    {
        name: 'Special Drawing Rights (XDR)',
        value: 'XDR'
    },
    {
        name: 'European Currency Unit (XEU)',
        value: 'XEU'
    },
    {
        name: 'Gold-franc  (XFO)',
        value: 'XFO'
    },
    {
        name: 'Uic-franc  (XFU)',
        value: 'XFU'
    },
    {
        name: 'Cfa Franc Bceao (XOF)',
        value: 'XOF'
    },
    {
        name: 'Palladium (XPD)',
        value: 'XPD'
    },
    {
        name: 'Cfp Franc (XPF)',
        value: 'XPF'
    },
    {
        name: 'Platinum (XPT)',
        value: 'XPT'
    },
    {
        name: 'Sucre (XSU)',
        value: 'XSU'
    },
    {
        name: 'Yemeni Dinar (YDD)',
        value: 'YDD'
    },
    {
        name: 'Yemeni Rial (YER)',
        value: 'YER'
    },
    {
        name: 'Yugoslavian Dinar (YUD)',
        value: 'YUD'
    },
    {
        name: 'New Dinar (YUM)',
        value: 'YUM'
    },
    {
        name: 'Yugoslav New Dinar (YUN)',
        value: 'YUN'
    },
    {
        name: 'Rand (Financial) (ZAL)',
        value: 'ZAL'
    },
    {
        name: 'Rand (ZAR)',
        value: 'ZAR'
    },
    {
        name: 'Kwacha (ZMK)',
        value: 'ZMK'
    },
    {
        name: 'Zambian Kwacha (ZMW)',
        value: 'ZMW'
    },
    {
        name: 'Zimbabwe Dollar (ZWD)',
        value: 'ZWD'
    },
    {
        name: 'Zimbabwe Dollar (ZWL)',
        value: 'ZWL'
    }
];
