import { EventEmitter } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Sort, SortDirection } from '@angular/material/sort';

import { ExecutionEvent, OrderEvent } from '../_shared/events.class';
import { IBaseEventDTO } from '../_shared/events.models';
import { TMEvent } from 'src/app/_shared/events.class';
import { Paginated } from '../_shared/shared.models';
import { ExtendedCase } from '../ucm/cases/models';
import { BaseAlert } from '../ucm/alerts/alerts.models';
import { Application } from '../onboarding/models';
import { AdvancedMultiSelectItemValue } from '../_shared/advanced-column-setting/advanced-column-setting.model';
import { ColumnWithFilters, IColumnFilters } from '../tables-with-filters/shared.models';
import { PageEvent } from '@angular/material/paginator';
import { Moment } from 'moment';

export enum TableType {
    Cases = 'CASE',
    Alerts = 'ALERT',
    Applications = 'APPLICATION',
    Orders = 'ORDER',
    Executions = 'EXECUTION',
    Transactions = 'TRANSACTION',
    DexTrades = 'DEX Trades'
}

export type AlertPageTableType = TableType.Orders | TableType.Executions | TableType.Transactions;

export type SearchableDataType =
    | IBaseEventDTO
    | ExecutionEvent
    | OrderEvent
    | TMEvent
    | ExtendedCase
    | BaseAlert
    | Application;

export type AdvancedSearchParams =
    | EventsAdvancedSearchParams
    | DexTradeAdvancedSearchParams
    | CaseAdvancedSearchParams
    | AlertAdvancedSearchParams
    | ApplicationAdvancedSearchParams;

export type AdvancedSearchPagedParams =
    | EventsAdvancedSearchPageParams
    | DexTradeAdvancedSearchPageParams
    | CaseAdvancedSearchPageParams
    | AlertAdvancedSearchPageParams
    | ApplicationAdvancedSearchPageParams;

export type EventsAdvancedSearchParams = {
    type: TableType;
    id?: string[];
    fromTime?: number;
    toTime?: number;
    clientId?: string[];
    actor?: string;
    account?: string;
    symbol?: string;
    currency?: string;
    transactionId?: string[];
    transactionHash?: string[];
    externalAddress?: string[];
    transactionType?: string;
};
export type EventsAdvancedSearchPageParams = EventsAdvancedSearchParams & AdvancedSearchPageParams;

export type DexTradeAdvancedSearchParams = {
    type: TableType;
    fromTime?: number;
    toTime?: number;
    blockchain?: string;
    tokenSymbol?: string[];
    liquidityPoolAddress?: string[];
    liquidityPoolContractAddress?: string[];
    dexName?: string[];
    wallet?: string[];
    transactionHash?: string[];
    minValue?: number;
    maxValue?: number;
};
export type DexTradeAdvancedSearchPageParams = DexTradeAdvancedSearchParams & AdvancedSearchPageParams;

export type CaseAdvancedSearchParams = {
    type?: TableType;
    caseId?: string[];
    clientId?: string[];
    alertId?: string[];
    account?: string;
    actor?: string;
    status?: string;
    alertType?: string;
    alertOrigin?: string;
    symbol?: string;
    manualOnly?: boolean;
    fromTime?: number;
    toTime?: number;
    resolutionFlag?: string;
};
export type CaseAdvancedSearchPageParams = CaseAdvancedSearchParams & AdvancedSearchPageParams;

export type AlertAdvancedSearchParams = {
    alertId?: string[];
    caseId?: string[];
    primaryAccounts?: string[];
    counterPartyAccounts?: string[];
    primaryActors?: string[];
    counterPartyActors?: string[];
    primaryClients?: string[];
    counterPartyClients?: string[];
    clientId?: string[];
    account?: string;
    actor?: string;
    alertType?: string;
    alertOrigin?: string;
    symbol?: string;
    fromTime?: number;
    toTime?: number;
};
export type AlertAdvancedSearchPageParams = AlertAdvancedSearchParams & AdvancedSearchPageParams;

export type ApplicationAdvancedSearchParams = {
    applicationId?: string[];
    email?: string;
    firstName?: string;
    lastName?: string;
    status?: string;
    investorType?: string;
    resolutionType?: string;
    fromTime?: number;
    toTime?: number;
};
export type ApplicationAdvancedSearchPageParams = ApplicationAdvancedSearchParams & AdvancedSearchPageParams;

export type PaginationFilter = {
    pageNumber: number;
    pageSize: number;
    pageLength?: number;
};

export interface SortFilter {
    sortBy: string;
    sortOrder: SortDirection;
    filters?: {
        [key: string]: AdvancedMultiSelectItemValue;
    };
    pagination?: PaginationFilter;
}

export type AdvancedSearchPageParams = {
    pagination: PaginationFilter;
    sort: SortFilter;
    selectedColumns?: string[];
    isAdditionalInfoEnabled?: boolean;
};

export type PaginatedSearchResults<T> = Paginated<T> & {
    limitExceeded: boolean;
    eventsType?: TableType;
    eventsStatus?: string;
};

export interface IAdvancedSearchTableWithFilters {
    tableReady: EventEmitter<IAdvancedSearchTableWithFilters>;
    defaultColumns: ColumnWithFilters[];
    currentFilters: IColumnFilters;
    currentSort: Sort;
    columns$: Observable<ColumnWithFilters[]>;
    loading$: BehaviorSubject<boolean>;
    totalCount(): number;
    loadInitData(filters: IColumnFilters): Observable<void>;
    changeFilters(filters: IColumnFilters): void;
    changePage(page: PageEvent): void;
    onColumnsChange(columnsKeys: string[]): void;
}

export type AgGridAdvancedSearchTables = {
    DEX_TRADES: boolean;
    CASES: boolean;
};

export function getAgGridAdvancedSearchTablesDefaults(): AgGridAdvancedSearchTables {
    return {
        DEX_TRADES: false,
        CASES: false
    };
}

export interface ISuggestedSearchParams {
    tableType: TableType;
    fromTime: Moment;
    toTime: Moment;
}
