import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
    CellStyleModule,
    ClientSideRowModelModule,
    ClipboardModule,
    ColumnApiModule,
    ColumnAutoSizeModule,
    ColumnMenuModule,
    ColumnsToolPanelModule,
    ContextMenuModule,
    CustomFilterModule,
    EventApiModule,
    InfiniteRowModelModule,
    LicenseManager,
    ModuleRegistry,
    NumberFilterModule,
    PaginationModule,
    provideGlobalGridOptions,
    RenderApiModule,
    RowApiModule,
    RowStyleModule,
    ScrollApiModule,
    ServerSideRowModelApiModule,
    ServerSideRowModelModule,
    SideBarModule,
    TooltipModule
} from 'ag-grid-enterprise';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));

ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    ServerSideRowModelApiModule,
    InfiniteRowModelModule,
    PaginationModule,
    ContextMenuModule,
    ColumnMenuModule,
    ColumnsToolPanelModule,
    RowApiModule,
    ColumnApiModule,
    ColumnAutoSizeModule,
    CustomFilterModule,
    NumberFilterModule,
    TooltipModule,
    SideBarModule,
    RenderApiModule,
    RowStyleModule,
    CellStyleModule,
    ClipboardModule,
    ScrollApiModule,
    EventApiModule
]);

LicenseManager.setLicenseKey(
    `Using_this_{AG_Grid}_Enterprise_key_{AG-070137}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Solidus_Labs}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Halo}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Halo}_need_to_be_licensed___{Halo}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_December_2025}____[v3]_[01]_MTc2Njc5MzYwMDAwMA==f9b419dbf7a857fa6fc70e898a198d84`
);
provideGlobalGridOptions({ theme: 'legacy' });
