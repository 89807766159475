import { getMinCandleSize, MAX_CANDLE_AMOUNT, MIN_CANDLE_AMOUNT, TimePeriod } from '../enums/market-inspection.enum';
import { IChartPeriodicity } from '../models/chart.models';
import {
    candleUnitsMs,
    ISymbolsPair,
    Period,
    timePeriodMs,
    TimePeriodOptions
} from '../models/market-inspection.models';
import { SMART_ZOOM_PERIODICITIES } from '../market-inspection.config';
import { IMinMax } from '../service/events/client-events-handler.service';

export function getSymbolPairFilterObj(symbolPair: string): ISymbolsPair {
    const ind = symbolPair?.indexOf('/');
    if (!symbolPair || ind < 0) {
        return;
    }

    return {
        fromSymbol: symbolPair.substring(0, ind),
        toSymbol: symbolPair.substring(ind + 1)
    };
}

export const dataURLtoFile = (dataurl: string, filename: string): File => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const timePeriodFromMs = (fromMs: number, toMs: number): TimePeriod => {
    const timePeriodRule = getPeriodRuleFromMs(fromMs, toMs);
    const timePeriod = Object.entries(timePeriodRule).find(a => a[1] === false);
    return timePeriod[0] as TimePeriod;
};

export const getPeriodRuleFromMs = (fromMs: number, toMs: number): { [key: string]: boolean } => {
    const timeDiffMs = toMs - fromMs;

    const rules = {
        [TimePeriod.ms_1]: isPeriodDisabled(timeDiffMs, TimePeriod.ms_1),
        [TimePeriod.ms_10]: isPeriodDisabled(timeDiffMs, TimePeriod.ms_10),
        [TimePeriod.ms_100]: isPeriodDisabled(timeDiffMs, TimePeriod.ms_100),
        [TimePeriod.sec_1]: isPeriodDisabled(timeDiffMs, TimePeriod.sec_1),
        [TimePeriod.sec_10]: isPeriodDisabled(timeDiffMs, TimePeriod.sec_10),
        [TimePeriod.min_1]: isPeriodDisabled(timeDiffMs, TimePeriod.min_1),
        [TimePeriod.min_10]: isPeriodDisabled(timeDiffMs, TimePeriod.min_10),
        [TimePeriod.hour_1]: isPeriodDisabled(timeDiffMs, TimePeriod.hour_1),
        [TimePeriod.hour_6]: isPeriodDisabled(timeDiffMs, TimePeriod.hour_6),
        [TimePeriod.day_1]: isPeriodDisabled(timeDiffMs, TimePeriod.day_1)
    };
    return rules;
};

function isPeriodDisabled(timeDiffMs: number, candleSize: TimePeriod): boolean {
    const amountOfCandles = timeDiffMs / timePeriodMs[candleSize];
    if (candleSize === getMinCandleSize()) {
        return amountOfCandles >= MAX_CANDLE_AMOUNT;
    }
    return !(amountOfCandles > MIN_CANDLE_AMOUNT && amountOfCandles < MAX_CANDLE_AMOUNT);
}

export const periodToTimePeriod = (period: IChartPeriodicity): TimePeriod => {
    const periodicity = SMART_ZOOM_PERIODICITIES;
    const entry = Object.entries(periodicity).find(_entry => {
        const quantity = (per: IChartPeriodicity) => (per.interval || 1) * (per.period || 1);
        return quantity(_entry[1]) === quantity(period) && _entry[1].timeUnit === period.timeUnit;
    });
    if (!entry) {
        console.log(periodicity);
        throw Error('No TimePeriod option was found for period ' + JSON.stringify(period));
    }

    return TimePeriod[Object.keys(TimePeriod).find(key => TimePeriod[key] == entry[0])];
};

export const periodToMs = (period: Period): number => {
    if (!period) {
        return 0;
    }
    const unitMs = candleUnitsMs[period.unit];
    return unitMs * period.quantity;
};

export const getTimePeriodFromPeriod = (period: Period): string => {
    const timePeriodEntry = Object.entries(TimePeriodOptions).find(
        entry => entry[1].quantity === period.quantity && entry[1].unit === period.unit
    );

    if (timePeriodEntry) {
        return timePeriodEntry[0];
    }

    console.error('getTimePeriodFromPeriod: Time period option not found');

    return period.quantity + period.unit[0].toLowerCase();
};

export const calculateMinMax = (newMinMax: IMinMax, currentMinMax: IMinMax): IMinMax => {
    const currentMin = currentMinMax && currentMinMax.min > -1 ? currentMinMax.min : Number.MAX_VALUE;
    const currentMax = currentMinMax?.max || -1;
    return new IMinMax(Math.min(currentMin, newMinMax.min), Math.max(currentMax, newMinMax.max));
};
