<div class="dialog-wrapper cases-notify-dialog">
    <div mat-dialog-title class="dialog-icon-header">
        <h2 class="re-auth-header">{{ titleText }}</h2>
    </div>
    <div mat-dialog-content>
        <div class="body">
            <div *ngIf="currentStep === reAuthSteps.SSO">
                Click to re-authenticate
            </div>

            <form *ngIf="currentStep === reAuthSteps.PASSWORD" autocomplete="off">
                <mat-form-field class="input-field" appearance="fill" color="accent">
                    <mat-label>Password</mat-label>
                    <input
                        (trdsCapsLock)="capsOn = $event"
                        [type]="showPassword ? 'text' : 'password'"
                        matInput
                        [formControl]="formControl"
                        name="new-password"
                        id="new-password"
                        autocomplete="new-password"
                    />
                    <mat-error *ngIf="formControl.errors">Password is required</mat-error>

                    <mat-icon matSuffix *ngIf="capsOn" fontSet="material-icons-outlined">keyboard_capslock</mat-icon>

                    <mat-icon
                        (click)="this.showPassword = !this.showPassword"
                        [attr.aria-label]="'Show password'"
                        [attr.aria-pressed]="showPassword"
                        type="button"
                        fontSet="material-icons-outlined"
                        matSuffix
                        >{{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon
                    >
                </mat-form-field>
                <br />
                <a class="link forgot-password-link" (click)="goToForgotPassword()">
                    Forgot password
                </a>
            </form>

            <div *ngIf="currentStep === reAuthSteps.REGISTER_2FA">
                <p class="register-instruction-text">
                    In order to protect your account against unauthorized access, you are required to set up a 2-step
                    verification.
                </p>
                <trds-register-2fa secretKey="{{ secretKey }}" qrCode="{{ qrCode }}"></trds-register-2fa>
            </div>

            <div *ngIf="currentStep === reAuthSteps.INPUT_2FA">
                <trds-input-2fa
                    [isInModal]="true"
                    [verificationStatus]="verificationStatus"
                    (codeEmitter)="verify2FA($event)"
                ></trds-input-2fa>
            </div>
        </div>
    </div>
    
    <div mat-dialog-actions align="end" *ngIf="currentStep !== reAuthSteps.INPUT_2FA">
        <trds-spinner-button
            cdkFocusInitial
            flat
            [text]="buttonText"
            [pending]="pending"
            [disabled]="currentStep === reAuthSteps.SSO ? false : !!password || pending || formControl.invalid"
            (clickEvent)="confirm()"
            class="confirm"
            color="accent"
        ></trds-spinner-button>
    </div>
</div>
