import { Injectable } from '@angular/core';
import { PackageType } from '../models/market-inspection.models';
import { MarketInspectionQuery } from '../state/market-inspection.query';
import { ClientPermission } from './client-permission.interface';
import { PremiumPackage } from './premium-package';
import { RegulatorPackage } from './regulator-package';
import { StandardPackage } from './standard-package';

const packageInstance = {
    [PackageType.STANDARD]: new StandardPackage(),
    [PackageType.PREMIUM]: new PremiumPackage(),
    [PackageType.REGULATOR]: new RegulatorPackage()
};

@Injectable({
    providedIn: 'root'
})
export class ClientPackage {
    private permission: ClientPermission;

    constructor(private marketInspectionQuery: MarketInspectionQuery) {}

    get package(): ClientPermission {
        return this.getInstance();
    }

    private getInstance(): ClientPermission {
        if (!this.permission) {
            return packageInstance[this.marketInspectionQuery.clientPackageType];
        }
        return this.permission;
    }
}
