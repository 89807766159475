import { HttpParams } from '@angular/common/http';
import {
    ColDef,
    ColumnState,
    GridApi,
    NumberFilterModel,
    SetFilterModel,
    SortModelItem,
    TextFilterModel,
    ValueFormatterParams
} from 'ag-grid-enterprise';

import { Observable, of } from 'rxjs';

import { TrdsLabelMultipleCellRendererComponent } from 'src/app/ag-grid-tables/shared/components/cell-renderers/trds-label-multiple-cell-renderer/trds-label-multiple-cell-renderer.component';
import { DateCellTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/date-cell-tooltip.component';
import { IdsContentCellTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/ids-content-cell-tooltip';
import { CellTippyTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/tippy-tooltips/cell-tippy-tooltip/cell-tippy-tooltip.component';
import { AgDateFilterComponent } from 'src/app/ag-grid-tables/shared/components/column-filters/ag-date-filter/ag-date-filter.component';
import { AgSetFilterComponent } from 'src/app/ag-grid-tables/shared/components/column-filters/ag-set-filter/ag-set-filter.component';
import { CellTooltipComponent } from 'src/app/ag-grid-tables/shared/components/cell-tooltip/cell-tooltip.component';
import { AdvancedMultiSelectItem } from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';
import { CustomHttpParameterCodec, SystemEnumsDictionary, TrdsMatIcon } from 'src/app/_shared/shared.models';
import { isNullOrUndefined } from 'src/app/_shared/shared.functions';

import { cloneDeep, isArray } from 'lodash';
import moment from 'moment';

export const SAVE_COLUMNS_CONFIGURATION_EVENT_NAME = 'SAVE_COLUMNS_CONFIGURATION';
export const RESET_COLUMNS_CONFIGURATION_EVENT_NAME = 'RESET_COLUMNS_CONFIGURATION';
export const SET_EXTERNAL_FILTERS_EVENT_NAME = 'SET_EXTERNAL_FILTERS_EVENT_NAME';
export const ADD_EXTERNAL_FILTER_EVENT_NAME = 'ADD_EXTERNAL_FILTER_EVENT_NAME';
export const REMOVE_FILTER_EVENT_NAME = 'REMOVE_FILTER_EVENT_NAME';
export const ON_GRID_READY = 'ON_GRID_READY';

export enum NumberFilterType {
    GreaterThanOrEqual = 'greaterThanOrEqual',
    LessThanOrEqual = 'lessThanOrEqual',
    InRange = 'inRange'
}

export const SAVE_ICON = '<mat-icon class="mat-icon material-icons mat-icon-no-color">' + 'save' + '</mat-icon>';

export const RESET_ICON =
    '<mat-icon class="mat-icon material-icons mat-icon-no-color">' + 'settings_backup_restore' + '</mat-icon>';

export type AutocompleteFilterModel = {
    filterType: 'autocomplete';
    values: AdvancedMultiSelectItem[];
};

export type TrdsDateFilterModel = {
    filterType: 'trdsDate';
    from: number;
    to: number;
};

export type ExternalFilterModel = {
    filterType: 'externalFilter';
    name: string;
    values?: string[];
};

export type FilterModelItemType =
    | TextFilterModel
    | NumberFilterModel
    | SetFilterModel
    | AutocompleteFilterModel
    | TrdsDateFilterModel
    | ExternalFilterModel;

export class ColumnsConfigurationEvent extends Event {
    constructor(
        type: string,
        public payload?: ColumnState[]
    ) {
        super(type);
    }
}

export class SetExternalFiltersEvent extends Event {
    constructor(public payload: FilterModel) {
        super(SET_EXTERNAL_FILTERS_EVENT_NAME);
    }
}

export class AddExternalFilterEvent extends Event {
    constructor(
        public key: string,
        public filterItem: FilterModelItemType
    ) {
        super(ADD_EXTERNAL_FILTER_EVENT_NAME);
    }
}

export class RemoveFilterEvent extends Event {
    constructor(public key: string) {
        super(REMOVE_FILTER_EVENT_NAME);
    }
}

export class OnGridReady extends Event {
    constructor() {
        super(ON_GRID_READY);
    }
}

export function getGridReadyEventSpy(jasmine): any {
    return cloneDeep(
        jasmine.createSpyObj('GridReadyEvent', [], {
            api: jasmine.createSpyObj('api', {
                setGridOption: () => void 0,
                setFilterModel: () => void 0,
                getFilterModel: () => ({}),
                hideOverlay: () => void 0,
                showNoRowsOverlay: () => void 0,
                onFilterChanged: () => void 0,
                addEventListener: () => void 0,
                removeEventListener: () => void 0,
                refreshCells: () => void 0,
                paginationGetPageSize: () => void 0,
                paginationGetCurrentPage: () => void 0,
                paginationGetRowCount: () => void 0,
                setDomLayout: () => void 0,
                sizeColumnsToFit: () => void 0,
                getColumnState: [],
                getAllDisplayedColumns: [],
                getAllGridColumns: [],
                getColumns: []
            })
        })
    );
}

export enum ColumnType {
    Date = 'Date'
}

export type SetValueFormatter = (params: ValueFormatterParams) => string | undefined;

export function getNumberColumnFilter(filterPlaceholder = 'Filter...'): ColDef {
    return {
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: Object.values(NumberFilterType),
            maxNumConditions: 1,
            filterPlaceholder
        },
        cellClass: 'ag-right-aligned-cell'
    };
}

export function getCustomNumberColumnFilter(filterPlaceholder = 'Filter...'): ColDef {
    return {
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: [
                NumberFilterType.GreaterThanOrEqual,
                NumberFilterType.LessThanOrEqual,
                {
                    displayKey: NumberFilterType.InRange,
                    displayName: 'InRange (Inclusive)',
                    predicate: ([fv1, fv2], cellValue) => cellValue == null || (fv1 <= cellValue && fv2 >= cellValue),
                    numberOfInputs: 2
                }
            ],
            maxNumConditions: 1,
            filterPlaceholder
        },
        cellClass: 'ag-right-aligned-cell'
    };
}

export function getSetColumnFilter(
    values: () => Observable<string[]>,
    valueFormatter: SetValueFormatter = undefined
): ColDef {
    return {
        filter: AgSetFilterComponent,
        filterParams: {
            values,
            valueFormatter
        }
    };
}

export function getDateColumnFilterAndCellRenderer(
    tooltipField: string,
    dateFormat: string,
    tooltipDateFormat: string
): ColDef {
    return {
        filter: AgDateFilterComponent,
        type: 'date',
        valueFormatter: params => {
            if (isNullOrUndefined(params.value)) {
                return '—';
            } else if (typeof params.value === 'string') {
                return params.value;
            }

            return moment.utc(params.value).format(dateFormat);
        },
        tooltipField,
        tooltipComponent: DateCellTooltipComponent,
        tooltipComponentParams: {
            tooltipDateFormat
        }
    };
}

export function getOriginColumnFilterAndCellRenderer(origins = ['TM', 'MS', 'KYC']): ColDef {
    return {
        width: 120,
        cellRenderer: TrdsLabelMultipleCellRendererComponent,
        cellRendererParams: {
            dictName: 'alertTypeOrigin'
        },
        ...getSetColumnFilter(() => of(origins))
    };
}

export function getActionColumnDefs(): ColDef {
    return {
        resizable: false,
        sortable: false,
        pinned: 'right',
        suppressColumnsToolPanel: true,
        suppressMovable: true,
        enableCellChangeFlash: false
    };
}

export function getIdsContentTooltip(tooltipField: string): ColDef {
    return {
        type: 'idsContent',
        tooltipComponent: IdsContentCellTooltipComponent,
        tooltipField,
        valueFormatter: params => {
            if (isNullOrUndefined(params.value)) {
                return '';
            }

            const maxVisibleCount = 3;
            const setVisibleIds = (ids: string[]) => {
                return mapToArrayIfString(ids).slice(0, maxVisibleCount).join(', ') + '...';
            };

            return params.value.length > maxVisibleCount
                ? setVisibleIds(params.value)
                : mapToArrayIfString(params.value).join(', ');
        }
    };
}

function mapToArrayIfString(value: string | string[]) {
    return isArray(value) ? value : [value];
}

export function getTooltip(tooltipField: string): ColDef {
    return {
        tooltipComponent: CellTooltipComponent,
        tooltipField
    };
}

export function getTippyTooltip(tooltipField: string): ColDef {
    return {
        tooltipComponent: CellTippyTooltipComponent,
        tooltipField
    };
}

export function setVisibilityByFeatureFlag(featureFlagIsEnabled: boolean): ColDef {
    return {
        hide: !featureFlagIsEnabled,
        suppressColumnsToolPanel: !featureFlagIsEnabled,
        lockVisible: !featureFlagIsEnabled
    };
}

export function getActiveSortModelItem(gridApi: GridApi): SortModelItem | null {
    const columnsWithSorting: ColumnState[] = gridApi
        .getColumnState()
        .filter(column => !isNullOrUndefined(column.sort))
        .map(
            column =>
                ({
                    colId: column.colId,
                    sort: column.sort
                }) as SortModelItem
        );

    return columnsWithSorting?.length ? { colId: columnsWithSorting[0].colId, sort: columnsWithSorting[0].sort } : null;
}

export type TrdsLabelCellRendererParams = {
    dictName: string;
    hasAttributeFlat?: boolean;
    icon?: TrdsMatIcon;
};

export type FilterModel = { [key: string]: FilterModelItemType };

export function getNameFromDictionary(dictionary: SystemEnumsDictionary, value: string): string {
    return dictionary[value]?.name || value;
}

export function getFormattedValueWithSpaces(params: ValueFormatterParams): string {
    if (isNullOrUndefined(params.value)) {
        return '';
    }

    return params.value
        .replace(/\s/g, '')
        .replace(/([A-Z])/g, ' $1')
        .trim();
}

export interface IGetOptionsRequest {
    searchTerm: string;
    offset: number;
    limit: number;
}

export function prepareSearchPropertyHttpParams(params: IGetOptionsRequest): HttpParams {
    let httpParams = new HttpParams({ encoder: new CustomHttpParameterCodec() }).set('valueLike', params.searchTerm);

    if (params.offset >= 0 && params.limit > 0) {
        httpParams = httpParams.appendAll({ offset: params.offset, limit: params.limit });
    }

    return httpParams;
}
