import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { TrdsMatIcon } from 'src/app/_shared/shared.models';

import { TrdsLabelCellRendererParams } from 'src/app/ag-grid-tables/shared/models.shared';

@Component({
    selector: 'trds-label-cell-renderer',
    template: `
        <trds-label
            trds-flat
            trds-icon
            trds-capitalize
            tabindex="0"
            [attr.aria-label]="item | dict: dictName"
            [ngxTippy]="smartTooltip.isTooltipDisabled ? null : (item | dict: dictName)"
            tippyClassName="trds-tooltip"
            #smartTooltip="trds-label"
        >
            <trds-mat-icon [icon]="icon"></trds-mat-icon>
            <span class="label">{{ item | dict: dictName }}</span>
        </trds-label>
    `
})
export class TrdsLabelCellRendererComponent implements ICellRendererAngularComp {
    item: string;
    dictName: string;
    icon: TrdsMatIcon;

    agInit(params: ICellRendererParams & TrdsLabelCellRendererParams): void {
        this.item = params.value;
        this.dictName = params.dictName;
        this.icon = params.icon;
    }

    refresh(params: ICellRendererParams<any>): boolean {
        return false;
    }
}
