import moment from 'moment';
import { Comment } from 'src/app/_shared/comments/comments.models';
import {
    getClientProfilesFromAAR,
    getClientProfilesFromAARByRole,
    isNullOrUndefined
} from 'src/app/_shared/shared.functions';
import { AccountActorRole, AccountActorRoles, Dictionary, Priority, Team, User } from 'src/app/_shared/shared.models';
import { AlertSTRStatus, AlertType, BaseAlert, IBaseAlertDTO } from '../../alerts/alerts.models';
import { CaseTags } from '../components/_shared/cases.types';
import { ExportCasePayload } from '../components/actions-dialogs/dialog.models';

export enum CaseStatus {
    NEW = 'NEW',
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    RESOLVED = 'RESOLVED',
    PENDING = 'PENDING',
    ESCALATED = 'ESCALATED',
    TRANSFERRED = 'TRANSFERRED',
    IN_REVIEW = 'IN_REVIEW'
}

export const CASE_STATUSES_MAP = new Map<string, string>([
    ['NEW', 'New'],
    ['ASSIGNED', 'Assigned'],
    ['IN_PROGRESS', 'In Progress'],
    ['IN_REVIEW', 'In Review'],
    ['RESOLVED', 'Resolved'],
    ['PENDING', 'Pending'],
    ['ESCALATED', 'Escalated'],
    ['TRANSFERRED', 'Transferred']
]);

export type ResolutionFlag =
    | 'UNRESOLVED'
    | 'FALSE_POSITIVE'
    | 'ADJUDICATED'
    | 'INCONCLUSIVE'
    | 'DUPLICATE'
    | 'OTHER'
    | 'RISK_ACCEPTED';

export enum CASE_PRIORITY {
    LOW,
    MEDIUM,
    HIGH
}

export interface ICaseDTO {
    id: string;
    createdAt: number;
    score: number;
    isFlagged: boolean;
    accountActorRoles: AccountActorRole[];
    alertTypes: AlertType[];
    status: string;
    resolutionFlag: ResolutionFlag;
    assignedTo: User;
    assignedTeam: Team;
    closingSummary: string;
    description: string;
    symbols: string[];
    secondarySymbols?: string[];
    createdBy: User;
    updatedAt: number;
    updatedBy: User;
    isProduction: boolean;
    priority: Priority;
    pendingActionType: PendingActionType;
    escalatedTeam?: Team;
    escalee?: User;
    tags?: CaseTags[];
    resolvedAt: number;
    alertStrReportStatuses?: CaseSTRStatuses[];
}

export interface ICaseHasAccessDTO extends ICaseDTO {
    hasAccess: boolean;
}

export type PendingActionType = 'ADMIN_ACTION' | 'REGULATORY_RESPONSE' | 'UNSPECIFIED' | null;

export interface IRelatedCasesDTO extends ICaseDTO {
    relations: ILinkDTO[];
}

export interface RelatedCasesDto {
    direction: string;
    id: string;
    createdAt: number;
    origin: string;
    score: number;
    clients: string[];
    actors: string[];
    symbols: string[];
    linkedAt: number;
    reason: string;
}

export interface ILinkDTO {
    linkedBy: User;
    linkedAt: number;
    reason: string;
    direction: 'INBOUND' | 'OUTBOUND';
}

export interface IExportCaseDetailsParams extends ExportCasePayload {
    caseIds: string[];
    modelTestIds?: string[];
}

export enum LinkDirection {
    UNKNOWN,
    INBOUND,
    OUTBOUND,
    BIDIRECTIONAL
}

export interface Attachment {
    id?: string;
    name: string;
    createdBy: User;
    storageKey?: string;
    createdAt: number;
    uploadedAt?: number;
    uploadedBy?: User;
}

export interface IAuditEventDTO {
    version: number;
    changedBy: User;
    changedAt: number;
    oldValue: string;
    newValue: string;
    field: string;
}

export interface IEscalationDetailsAuditDTO {
    id: string;
    escalatedBy: string;
    escalatedTeam: string;
    escalee: string;
    escaleeTeam: string;
    escalatedAt: number;
    reason: string;
}

export class EscalationAuditEvent implements IAuditEventDTO {
    changedAt: number;
    changedBy: User;
    field: 'escalations';
    newValue: string;
    oldValue: string;
    version: number;
    escalations: IEscalationDetailsAuditDTO[];
    historyMessage = 'N/A';

    constructor(dto: IAuditEventDTO) {
        this.changedAt = dto.changedAt;
        this.changedBy = dto.changedBy;
        this.field = dto.field as 'escalations';
        this.oldValue = dto.oldValue;
        this.newValue = dto.newValue;
        this.version = dto.version;
        this.escalations = this.getEscalationDetails(this.newValue);
        this.historyMessage = this.getHistoryMessage(this.escalations);
    }

    private getEscalationDetails(value: string): IEscalationDetailsAuditDTO[] {
        return JSON.parse(value).sort((a, b) => b.escalatedAt - a.escalatedAt);
    }

    private getHistoryMessage(escalations: IEscalationDetailsAuditDTO[]): string {
        const lastEscalation = escalations[0];
        if (isNullOrUndefined(lastEscalation)) {
            return 'N/A';
        }

        const from = this.prepareHistorySentence(lastEscalation.escalatedBy, lastEscalation.escalatedTeam, 'from');
        const to = this.prepareHistorySentence(lastEscalation.escalee, lastEscalation.escaleeTeam, 'to');

        return `Case was escalated ${from} ${to} for the reason '${lastEscalation.reason}'`;
    }

    private prepareHistorySentence(userName: string, teamName: string, prefix: 'from' | 'to'): string {
        return userName && teamName
            ? `${prefix} the user ${userName} (in the team ${teamName})`
            : `${prefix} the team ${teamName}`;
    }
}

export type CaseAuditContext = { $implicit: IAuditEventDTO; field: string };

type RelatedCaseKey = 'PRIMARY' | 'COUNTERPARTY';

export type IRelatedCasesTrendDTO = {
    [k in RelatedCaseKey]: IRelatedCasesAccountTrendDTO;
};

export class RelatedCasesTrend {
    primary: RelatedCasesAccountTrend;
    counterparty: RelatedCasesAccountTrend;

    constructor(dto: IRelatedCasesTrendDTO) {
        this.primary = dto.PRIMARY ? new RelatedCasesAccountTrend(dto.PRIMARY) : null;
        this.counterparty = dto.COUNTERPARTY ? new RelatedCasesAccountTrend(dto.COUNTERPARTY) : null;
    }
}

export type IRelatedCasesAccountTrendDTO = {
    [account: string]: {
        date: number;
        score: number;
    }[];
};

export class RelatedCasesAccountTrend {
    accounts: {
        [account: string]: {
            date: Date;
            score: number;
        }[];
    } = {};

    constructor(dto: IRelatedCasesAccountTrendDTO) {
        if (!dto) {
            return;
        }

        Object.entries(dto).forEach(([aName, data]) => {
            this.accounts[aName] = data.map(d => this.formatPair(d));
        });
    }

    private formatPair(pair: { date: number; score: number }): {
        date: Date;
        score: number;
    } {
        return {
            date: new Date(pair.date),
            score: pair.score
        };
    }
}

export interface IExtendedCaseDTO extends ICaseDTO {
    modelTestingBatchId: string;
    alerts: IBaseAlertDTO[];
    comments: Comment[];
    attachments: Attachment[];
    assignedTo: User;
    assignedTeam: Team;
    availableAssignees?: User[];
    availableAssignTeams?: Team[];
    kytIds: string[];
    escalations?: IEscalationDetails[];
    buIdentifiers?: string[];
    modelTestId?: string;
}

export interface IRelatedCasesTrendsDTO {
    cases: IRelatedCasesDTO[];
    relatedCasesTrend: IRelatedCasesTrendDTO;
}

export interface TimeInStatus {
    newSeconds: number;
    assignedSeconds: number;
    transferredSeconds: number;
    inProgressSeconds: number;
    pendingSeconds: number;
    escalatedSeconds: number;
    inReviewSeconds: number;
}

export enum TimeByStatus {
    NEW = 'newSeconds',
    ASSIGNED = 'assignedSeconds',
    TRANSFERRED = 'transferredSeconds',
    IN_PROGRESS = 'inProgressSeconds',
    PENDING = 'pendingSeconds',
    ESCALATED = 'escalatedSeconds',
    IN_REVIEW = 'inReviewSeconds'
}

export enum StatusByTime {
    newSeconds = 'NEW',
    assignedSeconds = 'ASSIGNED',
    transferredSeconds = 'TRANSFERRED',
    inProgressSeconds = 'IN_PROGRESS',
    pendingSeconds = 'PENDING',
    escalatedSeconds = 'ESCALATED',
    inReviewSeconds = 'IN_REVIEW'
}

export class Case extends AccountActorRoles implements ICaseDTO {
    id: string;
    createdAt: number;
    score: number;
    alertTypes: AlertType[];
    status: CaseStatus;
    resolutionFlag: ResolutionFlag;
    isProduction: boolean;
    isFlagged: boolean;

    accountActorRoles: AccountActorRole[];
    primaryAccounts: string[];
    primaryActors: string[];
    counterPartyAccounts: string[];
    counterPartyActors: string[];

    assignedTo: User;
    reviewedBy: User;
    assignedTeam: Team;
    assignedToName: string;
    reviewedByToName: string;
    closingSummary: string;
    description: string;
    symbols: string[];
    secondarySymbols?: string[];
    createdBy: User;
    updatedAt: number;
    updatedBy: User;
    priority: Priority;
    pendingActionType: PendingActionType;
    escalatedTeam: Team;
    escalee: User;
    escalatedBy: User;
    tags: CaseTags[];
    resolvedAt: number;
    statusChangedAt: number;
    timeInStatus: TimeInStatus;
    alertStrReportStatuses: CaseSTRStatuses[] = [];

    get alertTypeNames(): string[] {
        if (!this.alertTypes || this.alertTypes.length === 0) {
            return [];
        }

        const names = this.alertTypes.filter(at => at.name).map(at => at.name);
        return this.unique(names).sort();
    }

    get origins(): string[] {
        if (!this.alertTypes || this.alertTypes.length === 0) {
            return [];
        }

        const origins = this.alertTypes.filter(at => at.origin).map(at => at.origin);
        return this.unique(origins).sort();
    }

    get assignee(): string {
        if (this.assignedTo && this.assignedTo.name) {
            return this.assignedTo.name;
        }
        return 'Unassigned';
    }

    constructor(dto: ICaseDTO) {
        super(dto.accountActorRoles);
        // copy constructor
        Object.assign(this, dto);

        if (!this.assignedTo) {
            this.assignedTo = User.empty();
            this.assignedTo.name = 'Unassigned';
        }

        if (this.reviewedBy) {
            this.reviewedByToName = this.reviewedBy.name;
        }

        this.assignedToName = this.assignedTo?.name;
        this.alertStrReportStatuses = dto.alertStrReportStatuses ?? [];
    }

    get totalTimeInStatus(): number {
        if (!this.timeInStatus || !this.statusChangedAt) {
            return 0;
        }

        return (
            this.timeInStatus.newSeconds +
            this.timeInStatus.assignedSeconds +
            this.timeInStatus.transferredSeconds +
            this.timeInStatus.inProgressSeconds +
            this.timeInStatus.pendingSeconds +
            this.timeInStatus.escalatedSeconds +
            this.timeInStatus.inReviewSeconds +
            this.secondsFromLastChange
        );
    }

    get currentStatusTimeIn(): number {
        return this.timeInStatus[TimeByStatus[this.status as CaseStatus]] + this.secondsFromLastChange;
    }

    private get secondsFromLastChange(): number {
        const now = moment(this.status === CaseStatus.RESOLVED ? this.resolvedAt : new Date());
        const end = moment(this.statusChangedAt);
        const duration = moment.duration(now.diff(end));
        return duration.asSeconds();
    }

    get isNew(): boolean {
        return this.status === 'NEW';
    }

    get isInProgress(): boolean {
        return this.status === 'IN_PROGRESS';
    }

    get isInReview(): boolean {
        return this.status === 'IN_REVIEW';
    }

    get isResolved(): boolean {
        return this.status === 'RESOLVED';
    }

    get isPending(): boolean {
        return this.status === 'PENDING';
    }

    get isAssigned(): boolean {
        return this.status === 'ASSIGNED';
    }

    get isEscalated(): boolean {
        return this.status === 'ESCALATED';
    }

    get hasFlaggedAlerts(): boolean {
        return this.origins.includes('TM') || this.origins.includes('MS');
    }

    isAssignee(userId: number): boolean {
        return this.assignedTo?.id === userId;
    }

    isReviewer(userId: number): boolean {
        return this.reviewedBy?.id === userId;
    }

    isEscalee(userId: number): boolean {
        return this.escalee?.id === userId;
    }
}

export class ExtendedCase extends Case implements IExtendedCaseDTO {
    modelTestingBatchId: string;
    alerts: BaseAlert[];
    comments: Comment[];
    attachments: Attachment[];
    availableAssignees: User[];
    availableReviewers: User[];
    availableAssignTeams: Team[];
    escalations?: EscalationDetails[];
    isFlagged: boolean;
    kytIds: string[];
    version?: number;
    buIdentifiers?: string[];
    modelTestId?: string;
    hasMissingAlertEvents?: boolean;

    constructor(dto: IExtendedCaseDTO) {
        super(dto);
        // copy constructor
        Object.assign(this, dto);
        this.alerts = !Array.isArray(dto.alerts) ? [] : dto.alerts.map(a => new BaseAlert(a));
        this.escalations = !Array.isArray(dto.escalations) ? [] : dto.escalations.map(e => new EscalationDetails(e));
        this.kytIds = !Array.isArray(dto.kytIds) ? [] : dto.kytIds;
    }

    get shouldDisplayAlertEventWarningMessage(): boolean {
        return (
            (this.alertTypeNames.includes('ELLIPTIC_TRANSACTION') ||
                this.alertTypeNames.includes('ELLIPTIC_ADDRESS')) &&
            this.hasMissingAlertEvents
        );
    }

    get clientProfiles(): string {
        return getClientProfilesFromAAR(this.accountActorRoles);
    }

    get counterPartyClientProfiles(): string {
        return getClientProfilesFromAARByRole(this.accountActorRoles, 'COUNTERPARTY');
    }

    get primaryClientProfiles(): string {
        return getClientProfilesFromAARByRole(this.accountActorRoles, 'PRIMARY');
    }

    get tagsNames(): string {
        if (!this.tags || this.tags.length === 0) {
            return '';
        }

        return this.tags
            .map(t => t.name)
            .sort()
            .join(', ');
    }

    get escalatedToDisplay(): string {
        if (this.status !== 'ESCALATED') {
            return;
        }

        if (this.escalations?.length > 0) {
            return this.escalations[0].escalatedToDisplay;
        }
    }
}

export class HasAccessCase extends Case implements ICaseHasAccessDTO {
    hasAccess: boolean;

    constructor(dto: ICaseHasAccessDTO) {
        super(dto);
        this.hasAccess = dto.hasAccess;
    }
}

export class Link implements ILinkDTO {
    linkedAt: number;
    linkedBy: User;
    reason: string;
    direction: 'INBOUND' | 'OUTBOUND';
}

export class RelatedCase extends Case {
    relations: {
        links: ILinkDTO[];
        direction: LinkDirection;
    };

    constructor(dto: IRelatedCasesDTO) {
        super(dto);

        const relations = this.initRelations(dto.relations);
        Object.assign(this, dto, { relations });
    }

    private initRelations(links: ILinkDTO[]): { links: ILinkDTO[]; direction: LinkDirection } {
        let dir: LinkDirection;
        const directions = links?.map(l => l.direction);
        if (links.length > 2 || links.length < 1) {
            // this is an extreme error, but nothin we can do about it
            // inside a constructor except maybe throw an exception?
            // TODO (ayelet): consider changing to init function
            dir = LinkDirection.UNKNOWN;
        } else if (links.length === 2 && directions.includes('INBOUND') && directions.includes('OUTBOUND')) {
            dir = LinkDirection.BIDIRECTIONAL;
        } else {
            // only option remaining - a single link
            // use value to set enum
            dir = LinkDirection[links[0].direction];
        }

        // store the relation
        return {
            links,
            direction: dir
        };
    }
}

export class RelatedCases {
    cases: RelatedCase[] = [];
    relatedCasesTrend: RelatedCasesTrend;

    constructor(dto: IRelatedCasesTrendsDTO) {
        // copy constructor
        Object.assign(this, dto);

        this.cases = !Array.isArray(dto.cases) ? [] : dto.cases.map(c => new RelatedCase(c));
        this.relatedCasesTrend = dto.relatedCasesTrend ? new RelatedCasesTrend(dto.relatedCasesTrend) : null;
    }
}

export type CaseTrend = {
    account: Dictionary<TrendScore[]>;
    origin: Dictionary<TrendScore[]>;
    assignee: Dictionary<TrendScore[]>;
    status: Dictionary<TrendScore[]>;
    alertType: Dictionary<TrendScore[]>;
    client: Dictionary<TrendScore[]>;
};

export type TrendScore = {
    date: string;
    score: number;
};

export interface IEscalationDetails {
    id: string;
    escalatedBy: User;
    escalatedAt: number;
    escalatedTeam: Team;
    escalee: User;
    reason: string;
    returnedEscalation?: {
        id: string;
        returnedAt: number;
        returnedBy: User;
        reason: string;
    };
}

export class EscalationDetails implements IEscalationDetails {
    id: string;
    escalatedBy: User;
    escalatedAt: number;
    escalatedTeam: Team;
    escalee: User;
    reason: string;
    returnedEscalation?: {
        id: string;
        returnedAt: number;
        returnedBy: User;
        reason: string;
    };

    constructor(dto: IEscalationDetails) {
        Object.assign(this, dto);
    }

    get escalatedToDisplay(): string {
        const escalatedUser = this.escalee?.name;
        const escalatedTeam = this.escalatedTeam?.name;

        return escalatedUser && escalatedTeam
            ? escalatedUser + ' / ' + escalatedTeam
            : escalatedUser || escalatedTeam || '';
    }
}

export interface CasesBulkPayload {
    cases: {
        id: Case['id'];
        updatedAt: Case['updatedAt'];
    }[];
}

export interface BulkPayload {
    cases: Pick<Case, 'id' | 'updatedAt'>[];
}
export interface CasesBulkResolvePayload extends BulkPayload {
    resolutionType: ResolutionFlag;
    summary: string;
}

export interface CasesBulkInvestigationSummaryPayload extends BulkPayload {
    summary: string;
}

export interface CasesBulkCommentPayload extends BulkPayload {
    text: string;
}

export class CasesBulkDataBase {
    constructor(public cases: Case[]) {}

    public get payload(): CasesBulkPayload {
        return {
            cases: this.cases.map(c => ({ id: c.id, updatedAt: c.updatedAt }))
        };
    }
}

export class CasesBulkInvestigationSummary extends CasesBulkDataBase {
    constructor(
        public cases: Case[],
        private summary: string
    ) {
        super(cases);
    }

    get payload(): CasesBulkInvestigationSummaryPayload {
        return {
            ...super.payload,
            summary: this.summary
        };
    }
}

export class CasesBulkResolve extends CasesBulkDataBase {
    constructor(
        public cases: Case[],
        private type: ResolutionFlag,
        private summary: string
    ) {
        super(cases);
    }

    get payload(): CasesBulkResolvePayload {
        return {
            ...super.payload,
            resolutionType: this.type,
            summary: this.summary
        };
    }
}

export class CasesBulkComment extends CasesBulkDataBase {
    constructor(
        public cases: Case[],
        private comment: string
    ) {
        super(cases);
    }

    get payload(): CasesBulkCommentPayload {
        return {
            ...super.payload,
            text: this.comment
        };
    }
}

export enum CasesBulkUpdateType {
    Resolve,
    Assign,
    Comment,
    Attachment,
    AttachmentRemove,
    Investigate,
    AskForReview,
    InvestigationSummary
}

export const allowedAttachFileExt = [
    '.jpeg',
    '.jpg',
    '.pjpeg',
    '.jfif',
    '.pjp',
    '.pdf',
    '.png',
    '.docs',
    '.docx',
    '.zip',
    '.7z',
    '.csv',
    '.pdf',
    '.xlsx',
    '.txt',
    '.gif'
];
export const maxBulkAttachFileSizeInMb = 50;

export type CasesBulkAssignKey = 'assigneeId' | 'teamId';

export interface CasesBulkAssignPayload extends BulkPayload {
    assigneeId?: string;
    teamId?: string;
}

export interface CasesBulkReviewPayload extends BulkPayload {
    reviewedBy: number;
}

export class CasesBulkAssign extends CasesBulkDataBase {
    private readonly urls: { [key in CasesBulkAssignKey]: string } = {
        assigneeId: 'batch-assign',
        teamId: 'batch-assign-team'
    };
    constructor(
        public cases: Case[],
        private id: number,
        private key: CasesBulkAssignKey
    ) {
        super(cases);
    }

    get payload(): CasesBulkAssignPayload {
        return {
            ...super.payload,
            [this.key]: this.id
        };
    }

    public get url(): string {
        return this.urls[this.key];
    }
}

export class CasesBulkReview extends CasesBulkDataBase {
    constructor(
        public cases: Case[],
        private id: number
    ) {
        super(cases);
    }

    get payload(): CasesBulkReviewPayload {
        return {
            ...super.payload,
            reviewedBy: this.id
        };
    }
}

export type CasesBulkUpdateErrorReason = {
    reason: string;
    id: string;
};

export type CasesBulkUpdateHttpResponse = {
    updated: string[];
    skipped: { [key: string]: string[] };
    skippedErrorReasons?: CasesBulkUpdateErrorReason[];
};

export interface CasesBulkUpdateAttachment {
    id: string;
    savedAttachmentFile: Attachment[];
    status: {
        message: string;
        type: string;
    };
}

export interface CasesBulkUpdateAttachHttpResponse {
    updated: CasesBulkUpdateAttachment[];
    skipped: { [erroMessage: string]: CasesBulkUpdateAttachment[] };
}

export interface CasesAttachments {
    [casId: string]: Attachment[];
}

export interface CaseBulkAttachmentRemove {
    id: Case['id'];
    attachmentId: Attachment['id'];
    updatedAt: number;
}

export class CasesBulkUpdateResponse {
    public readonly updated: string[] = [];
    public skipped: CasesBulkUpdateErrorReason[] = [];

    protected readonly errorReasonTypes: { [key: string]: string } = {
        GENERIC_ERROR: 'Something went wrong',
        NOT_IN_ACTIVE_STATUS: 'Case is not yet in status for adding summary',
        CONCURRENT_UPDATE: 'Concurrent update',
        WRONG_STATUS: 'Failed to start investigation as it is not in a proper status',
        NOT_IN_REVIEW_STATUS: 'Failed to resolve case as it is not in "In Review" status',
        WRONG_ASSIGNEE: 'Failed to start investigation as it is not assigned to you',
        WRONG_REVIEWER: 'Failed to ask for review as user cannot review this case',
        NOT_IN_PROGRESS_STATUS: 'Failed to ask for, case not in progress status',
        USER_NOT_REVIEWER: 'Failed to ask for review, user cannot review case'
    };
    private readonly errorMessageTypes: { [key in CasesBulkUpdateType]: { all: string; some: string } } = {
        [CasesBulkUpdateType.Resolve]: {
            all: 'Unable to resolve selected cases. Please review the details below before retrying to resolve.',
            some: 'Some of the selected cases were not resolved. Please review the details below before retrying to resolve.'
        },
        [CasesBulkUpdateType.Assign]: {
            all: 'Unable to assign selected cases. Please review the details below before retrying to assign.',
            some: 'Some of the selected cases were not assigned. Please review the details below before retrying to assign.'
        },
        [CasesBulkUpdateType.Comment]: {
            all: 'Unable to post a comment to selected cases. Please review the details below before retrying to comment.',
            some: 'Some of the selected cases were not commented. Please review the details below before retrying to comment.'
        },
        [CasesBulkUpdateType.Attachment]: {
            all: 'Unable to attach a file to selected cases. Please review the details below before upload.',
            some: 'Unable to attach a file to some cases. Please review the details below before upload.'
        },
        [CasesBulkUpdateType.AttachmentRemove]: {
            all: 'Unable to remove a file to selected cases. Please review the details below before upload.',
            some: 'Unable to remove a file to some cases. Please review the details below before upload.'
        },
        [CasesBulkUpdateType.Investigate]: {
            all: 'Cases could not be moved to "In Progress". Please review the details below.',
            some: 'Some cases could not be moved to "In Progress". Please review the details below.'
        },
        [CasesBulkUpdateType.AskForReview]: {
            all: 'Cases could not be moved to "In Review". Please review the details below.',
            some: 'Some cases could not be moved to "In Review". Please review the details below.'
        },
        [CasesBulkUpdateType.InvestigationSummary]: {
            all: 'Unable update investigation summary to selected cases. Please review the details below.',
            some: 'Unable update investigation summary some of selected cases. Please review the details below.'
        }
    };

    constructor(
        response: CasesBulkUpdateHttpResponse,
        private type: CasesBulkUpdateType
    ) {
        this.updated = response.updated;
        this.skipped = response.skippedErrorReasons
            ? response.skippedErrorReasons
            : this.getErrorReasons(response.skipped);
    }

    public get areAllFailed(): boolean {
        return this.updated.length === 0 && this.skipped.length > 0;
    }

    public get hasFailedCases(): boolean {
        return this.skipped.length > 0;
    }

    public get errorMsg(): string {
        if (!this.hasFailedCases) {
            return '';
        }

        return this.areAllFailed ? this.errorMessageTypes[this.type].all : this.errorMessageTypes[this.type].some;
    }

    private getErrorReasons(data: { [key: string]: string[] }): CasesBulkUpdateErrorReason[] {
        if (isNullOrUndefined(data) || Object.keys(data).length === 0) {
            return [];
        }

        return Object.entries(data).flatMap(([reason, ids]) =>
            ids.map(id => ({ id, reason: this.getReasonText(reason) }))
        );
    }

    private getReasonText(key: string): string {
        return key in this.errorReasonTypes ? this.errorReasonTypes[key] : this.errorReasonTypes.GENERIC_ERROR;
    }
}

export interface ICaseStatusHistory {
    [CaseStatus.PENDING]: number;
    [CaseStatus.NEW]: number;
    [CaseStatus.ASSIGNED]: number;
    [CaseStatus.RESOLVED]: number;
    [CaseStatus.IN_PROGRESS]: number;
    [CaseStatus.TRANSFERRED]: number;
    [CaseStatus.ESCALATED]: number;
    [CaseStatus.IN_REVIEW]: number;
}

export interface ICaseStatusTimeHistory {
    status: CaseStatus;
    timeInStatus: string;
}

export interface ICaseTimeInStatusesDialogConfig {
    totalTime: string;
    resolutionStatus?: string;
    scheduleStatus: CaseTimeInStatusSettingKey;
    caseStatusHistoryItems: ICaseStatusTimeHistory[];
}

export interface CaseTimeInStatusSettingsDto {
    onScheduleInDays: number;
    offScheduleInDays: number;
}

export type CaseTimeInStatusSettingKey = keyof CaseTimeInStatusSettings;

export interface CaseTimeInStatusSettings {
    onSchedule: {
        fromSeconds?: number;
        toSeconds?: number;
    };
    behindSchedule: {
        fromSeconds?: number;
        toSeconds?: number;
    };
    offSchedule: {
        fromSeconds?: number;
        toSeconds?: number;
    };
}

export interface CaseSTRStatuses {
    alertType: string;
    strStatus: AlertSTRStatus;
}

export const OPEN_CASES_TABLE_KEY = 'UCM_Open_Cases';
export const RESOLVED_CASES_TABLE_KEY = 'UCM_Resolved_Cases';

export function isOpenOrResolvedCasesRoute(path: string): boolean {
    return path.includes('cases/open') || path.includes('cases/resolved');
}
