import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import moment from 'moment';
import { USE_LOCAL_TIME } from 'src/app/market-inspection-new/market-inspection.config';

@Component({
    selector: 'trds-date-cell',
    templateUrl: './date-cell.component.html',
    styleUrls: ['./date-cell.component.scss']
})
export class DateCellComponent implements ICellRendererAngularComp {
    static readonly defaultDateColumnWidth = 220;
    static readonly defaultColorColumnWidth = 10;

    public cellValue: number;
    USE_LOCAL_TIME = USE_LOCAL_TIME;
    DATE_TIME_FORMAT = 'HH:mm:ss.SSS, MMM DD YYYY';

    agInit(params: ICellRendererParams<unknown, unknown>): void {
        this.setCellValue(params);
    }
    refresh(params: ICellRendererParams<unknown, unknown>): boolean {
        this.setCellValue(params);
        return true;
    }

    private setCellValue(param: ICellRendererParams<unknown, unknown>): void {
        const getValueCb = param['getValueCb'];
        const value = getValueCb ? getValueCb(param, param['field']) : param.value;
        if (value) {
            const date = USE_LOCAL_TIME ? moment(value) : moment.utc(value);
            this.cellValue = date.valueOf();
        }
    }
}
