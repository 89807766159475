export interface MiInstrumentMetadata {
    instrument: string;
    securityType: string;
    exchangeSymbol: string;
    expirationDateTime: string;
    settleDateTime: string;
    positionEffect: string;
    putCall: string;
    strikePrice: number;
    fundingRate: number;
    contractMultiplier: number;
    cfiCode: string;
    exerciseStyle: string;
    strikeValue: number;
}

let miInstrumentsCache: Map<string, MiInstrumentMetadata> = new Map();
export const getDerivativesMetadata = (symbol: string): MiInstrumentMetadata => {
    return miInstrumentsCache.get(symbol);
};
export const cleanInstrumentCache = (): void => {
    miInstrumentsCache = new Map();
};
export const getInstrumentMetadata = (symbol: string): MiInstrumentMetadata => {
    return miInstrumentsCache.get(symbol);
};
export const setInstrumentMetadata = (key: string, value: MiInstrumentMetadata): void => {
    miInstrumentsCache.set(key, value);
};
