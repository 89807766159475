import moment from 'moment';
import { IQueryParam, ISymbolsPair } from '../models/market-inspection.models';
import { IOrderExecutionRequest, TraderSearchType } from '../components/tables/table.model';

const CACHE_DATE_HOUR_FORMAT = 'MMM-DD-YYYY_HH:00';
const IS_DERIVATIVES_KEY = '_isDerivatives';

export const genPrivateTableDataCacheKey = (
    body: IOrderExecutionRequest,
    queryParams: IQueryParam,
    symbols: string
): string => {
    const sort = queryParams.sort;
    const fromDate = timeFormat(body.fromTime);
    const toDate = timeFormat(body.toTime);
    const pairString = pairFormat(body.symbolsPair[0]);
    const pageSize = queryParams.size;
    const isDerivatives = isDerivativesFormat(body.isDerivatives);
    return `TABLE_${pairString}_${fromDate}-${toDate}_${sort}_${pageSize}${isDerivatives}_${symbols}`;
};

// keep for a future implementation evetns/total api
export const getTotalEventsCacheKey = (body: IOrderExecutionRequest, symbols: string): string => {
    const fromDate = timeFormat(body.fromTime);
    const toDate = timeFormat(body.toTime);
    const pairString = pairFormat(body.symbolsPair[0]);
    const isDerivatives = isDerivativesFormat(body.isDerivatives);
    return `TOTAL_EVENTS_${pairString}_${fromDate}-${toDate}${isDerivatives}_${symbols}`;
};

export const getTotalAlertRelatedCacheKey = (body: IOrderExecutionRequest): string => {
    const fromDate = timeFormat(body.fromTime);
    const toDate = timeFormat(body.toTime);
    const pairString = pairFormat(body.symbolsPair[0]);
    return `TOTAL_ALERT_RELATED_${pairString}_${fromDate}-${toDate}`;
};

export const genPrivateClientIdsCacheKey = (
    fromTime: number,
    toTime: number,
    pair: ISymbolsPair,
    isDerivatives: boolean,
    searchParams: TraderSearchType | string
): string => {
    const fromDate = timeFormat(fromTime);
    const toDate = timeFormat(toTime);
    const pairString = pairFormat(pair);
    const derivativesString = isDerivativesFormat(isDerivatives);
    return `TRADER_IDS_${searchParams}_${pairString}_${fromDate}-${toDate}${derivativesString}`;
};

export const genAlertTableDataCacheKey = (alertId: string, queryParams: any, symbols: string): string => {
    const sort = queryParams.sort;
    const pageSize = queryParams.size;
    return `TABLE_ALERT_${alertId}_${sort}_${pageSize}_${symbols}`;
};

export const genAlertRelatedTableDataCacheKey = (
    alertId: string,
    orderType: string,
    queryParams: any,
    symbols: string
): string => {
    const sort = queryParams.sort;
    const pageSize = queryParams.size;
    return `TABLE_ALERT_RELATED_${alertId}_${orderType}_${sort}_${pageSize}_${symbols}`;
};

export const genAlertTotalEventsDataCacheKey = (alertId: string, symbols: string): string =>
    `TOTAL_EVENTS_ALERT_${alertId}_${symbols}`;

export const MI_PRIVATE_ALERTS_TYPE_CACHE_KEY = `ALERTS_TYPE`;

const timeFormat = (ts: number) => moment(ts).format(CACHE_DATE_HOUR_FORMAT);
export const pairFormat = (pair: ISymbolsPair): string => `${pair.fromSymbol}/${pair.toSymbol}`;
const isDerivativesFormat = (isDerivatives: boolean): string => (isDerivatives ? IS_DERIVATIVES_KEY : '');
