import { ColDef, GetContextMenuItemsParams, MenuItemDef, SideBarDef } from 'ag-grid-enterprise';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { WindowOpenService } from 'src/app/_services/window-open/window-open.service';
import { AdvancedMultiSelectItem } from 'src/app/_shared/advanced-column-setting/advanced-column-setting.model';
import { AdvancedSearchService } from 'src/app/advanced-search/advanced-search-service/advanced-search.service';
import { TableType } from 'src/app/advanced-search/advanced-search.models';
import { SEARCH_ICON } from 'src/app/ag-grid-tables/shared/classes/context-menu/models';
import { ClickedEvent, ISymbolsPair, MITableType, RowColorRule } from '../../models/market-inspection.models';
import { TblCSVSelectHeaderComponent } from './views/mi-file-edit-view-table/tbl-select-header/tbl-select-header.component';
import { EventSide } from '../../enums/events.enum';
import {
    AlertHeaderName,
    alertTableColumn,
    CsvHeaderName,
    getColorStyleColum,
    TableColumn,
    TableHeader
} from './table-columns.model';
import { OriginType } from 'src/app/_shared/shared.models';
import { DictService } from 'src/app/localize/dict.service';
import { IconByStatusPipe } from 'src/app/_shared/pipes/icon-by-status.pipe';

export interface ICustomTableFilter {
    component: any;
    fn(fieldName: TableHeader, searchValue?: string): Observable<AdvancedMultiSelectItem[]>;
}

export const SIDEBAR_CONFIGURATION: SideBarDef = {
    toolPanels: [
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true
            }
        }
    ]
};

export enum TraderSearchType {
    clientId = TableHeader.clientId,
    account = TableHeader.account,
    actorId = TableHeader.actorId
}

export const TraderSearchTypes: { [key in TraderSearchType]: string } = {
    [TraderSearchType.clientId]: 'Client ID',
    [TraderSearchType.actorId]: 'Actor ID',
    [TraderSearchType.account]: 'Account ID'
};

export interface HeaderModel {
    headerName: string;
    isSelected: boolean;
    id: TableHeader;
}

export const CSV_EDIT_DEFAULT_HEADER_NAME = 'Select column';

export const HEADERS: HeaderModel[] = [
    { id: null, headerName: CSV_EDIT_DEFAULT_HEADER_NAME, isSelected: false },
    { id: TableHeader.transactionTime, headerName: CsvHeaderName.transactionTime, isSelected: false },
    { id: TableHeader.side, headerName: CsvHeaderName.side, isSelected: false },
    { id: TableHeader.symbol, headerName: CsvHeaderName.symbol, isSelected: false },
    { id: TableHeader.quantity, headerName: CsvHeaderName.quantity, isSelected: false },
    { id: TableHeader.price, headerName: CsvHeaderName.price, isSelected: false },
    { id: TableHeader.orderStatus, headerName: CsvHeaderName.orderStatus, isSelected: false },
    { id: TableHeader.id, headerName: CsvHeaderName.id, isSelected: false },
    { id: TableHeader.avgPrice, headerName: CsvHeaderName.avgPrice, isSelected: false },
    { id: TableHeader.clientId, headerName: CsvHeaderName.clientId, isSelected: false },
    { id: TableHeader.account, headerName: CsvHeaderName.account, isSelected: false },
    { id: TableHeader.actorId, headerName: CsvHeaderName.actorId, isSelected: false },
    { id: TableHeader.cumQty, headerName: CsvHeaderName.cumQty, isSelected: false },
    { id: TableHeader.executionVenue, headerName: CsvHeaderName.executionVenue, isSelected: false },
    { id: TableHeader.leavesQty, headerName: CsvHeaderName.leavesQty, isSelected: false },
    { id: TableHeader.localCurrency, headerName: CsvHeaderName.localCurrency, isSelected: false },
    { id: TableHeader.localNotional, headerName: CsvHeaderName.localNotional, isSelected: false },
    { id: TableHeader.orderType, headerName: CsvHeaderName.orderType, isSelected: false },
    { id: TableHeader.version, headerName: CsvHeaderName.version, isSelected: false }
];

export const HEADERS$ = new BehaviorSubject<HeaderModel[]>(HEADERS);

export const getDefaultColumns = (
    opt: {
        rowColorRule?: RowColorRule;
        isDerivatives?: boolean;
        traderSearchType?: TraderSearchType;
        isAlertView?: boolean;
        useApiV5?: boolean;
    } = {}
): Map<string, ColDef> => {
    const { rowColorRule, isDerivatives, traderSearchType, useApiV5 } = opt;
    const defaultColumns: Array<[string, ColDef]> = opt.isAlertView
        ? alertColumns(rowColorRule, traderSearchType)
        : baseBackendColumns(rowColorRule, traderSearchType, useApiV5);

    if (isDerivatives) {
        defaultColumns.push(
            getTableColEntity(TableHeader.securityType),
            getTableColEntity(TableHeader.exchangeSymbol),
            getTableColEntity(TableHeader.settleDateTime),
            getTableColEntity(TableHeader.positionEffect),
            getTableColEntity(TableHeader.putCall),
            getTableColEntity(TableHeader.strikePrice),
            getTableColEntity(TableHeader.fundingRate),
            getTableColEntity(TableHeader.contractMultiplier),
            getTableColEntity(TableHeader.cfiCode),
            getTableColEntity(TableHeader.exerciseStyle),
            getTableColEntity(TableHeader.strikeValue),
            getTableColEntity(TableHeader.expirationDateTime)
        );
    }

    return new Map(defaultColumns);
};

const baseBackendColumns = (
    rowColorRule: RowColorRule,
    traderSearchType,
    useApiV5?: boolean
): Array<[string, ColDef]> => {
    const colDef: Array<[string, ColDef]> = [
        [TableHeader.colorStyleColumn, getColorStyleColum(rowColorRule, traderSearchType)],
        getTableColEntity(TableHeader.isAlertRelated),
        getTableColEntity(TableHeader.transactionTime),
        getTableColEntity(TableHeader.orderStatus),
        getTableColEntity(TableHeader.side),
        getTableColEntity(TableHeader.isExecution),
        getTableColEntity(TableHeader.symbol),
        getTableColEntity(TableHeader.orderPrice),
        getTableColEntity(TableHeader.execPrice),
        getTableColEntity(TableHeader.orderQty),
        getTableColEntity(TableHeader.quantity),
        getTableColEntity(TableHeader.clientId),
        getTableColEntity(TableHeader.account),
        getTableColEntity(TableHeader.actorId),
        getTableColEntity(TableHeader.matchingAccountId),
        getTableColEntity(TableHeader.matchingActorId),
        getTableColEntity(TableHeader.matchingClientId),

        getTableColEntity(TableHeader.matchingOrderId),
        getTableColEntity(TableHeader.orderType),

        getTableColEntity(TableHeader.orderCapacity),

        getTableColEntity(TableHeader.executionVenue),
        getTableColEntity(TableHeader.matchingOrderCapacity),
        getTableColEntity(TableHeader.trdType),
        getTableColEntity(TableHeader.executionType),
        getTableColEntity(TableHeader.cumQty),
        getTableColEntity(TableHeader.contingencyType),
        getTableColEntity(TableHeader.timeInForce),
        getTableColEntity(TableHeader.origTransactTime),
        getTableColEntity(TableHeader.leavesQty),
        getTableColEntity(TableHeader.buIdentifier),
        getTableColEntity(TableHeader.matchingExecutionId),
        getTableColEntity(TableHeader.stopPx),
        getTableColEntity(TableHeader.leavesQty),
        getTableColEntity(TableHeader.ipAddress),
        getTableColEntity(TableHeader.matchingIpAddress),
        getTableColEntity(TableHeader.localNotional),
        getTableColEntity(TableHeader.localCurrency)
    ];

    if (useApiV5) {
        colDef.push(getTableColEntity(TableHeader.orderId));
        colDef.push(getTableColEntity(TableHeader.execId));
    } else {
        colDef.push(getTableColEntity(TableHeader.eventId));
    }

    return colDef;
};

const alertColumns = (rowColorRule: RowColorRule, traderSearchType): Array<[string, ColDef]> => [
    [TableHeader.colorStyleColumn, getColorStyleColum(rowColorRule, traderSearchType)],
    getTableColEntity(TableHeader.transactionTime),
    getTableColEntity(TableHeader.clientId),
    getTableColEntity(TableHeader.account),
    getTableColEntity(TableHeader.actorId),
    getTableColEntity(TableHeader.eventId),
    getTableColEntity(TableHeader.orderId),
    getTableColEntity(TableHeader.symbol),
    getTableColEntity(TableHeader.orderPrice),
    getTableColEntity(TableHeader.execPrice),
    getTableColEntity(TableHeader.side),
    getTableColEntity(TableHeader.orderType),
    getTableColEntity(TableHeader.orderStatus),
    getTableColEntity(TableHeader.localCurrency),
    getTableColEntity(TableHeader.localNotional),
    getTableColEntity(TableHeader.executionVenue),
    getTableColEntity(TableHeader.quantity),
    getTableColEntity(TableHeader.cumQty),
    getTableColEntity(TableHeader.leavesQty),
    getTableColEntity(TableHeader.eventType)
];

const getTableColEntity = (header: TableHeader): [string, ColDef] => {
    return [header, TableColumn[header]];
};

const getAlertTableColEntity = (
    dict: DictService,
    iconByStatusPipe: IconByStatusPipe,
    header: AlertHeaderName,
    windowOpenService: WindowOpenService
): [string, ColDef] => {
    return [header, alertTableColumn(dict, iconByStatusPipe, windowOpenService)[header]];
};

export function columnDefAlertViewFunction(options?: {
    customFilters?: Map<string, ICustomTableFilter>;
    rowColorRule?: RowColorRule;
    isDerivatives?: boolean;
    traderSearchType?: TraderSearchType;
}): ColDef[] {
    const colDef: ColDef[] = columnDefFunction(options);
    modifyAlertViewColumns(colDef);
    return colDef;
}

function modifyAlertViewColumns(colDef: ColDef[]) {
    const index = colDef.indexOf(TableColumn.orderCapacity);
    if (index > -1) {
        colDef.splice(index, 1);
    }
    colDef.push(TableColumn.eventType);
}

export function columnDefFunction(options?: {
    customFilters?: Map<string, ICustomTableFilter>;
    rowColorRule?: RowColorRule;
    isDerivatives?: boolean;
    traderSearchType?: TraderSearchType;
    isAlertView?: boolean;
    isCrossProduct?: boolean;
    useApiV5?: boolean;
}): ColDef[] {
    const columns = getDefaultColumns(options);
    if (options?.customFilters?.size) {
        options?.customFilters.forEach((customFilterCol, id) => {
            const column = columns.get(id);
            if (column) {
                column.filter = customFilterCol.component;
                const func = customFilterCol.fn.bind(null, id);
                column.filterParams = { getOptions: func };
            }
        });
    }

    return Array.from(columns.values());
}

export function columnAlertDefFunction(
    dict: DictService,
    iconByStatusPipe: IconByStatusPipe,
    windowOpenService: WindowOpenService,
    options?: {
        rowColorRule?: RowColorRule;
        traderSearchType?: TraderSearchType;
        isCrossProduct?: boolean;
    }
): ColDef[] {
    const columns = getDefaultAlertColumns(options, dict, iconByStatusPipe, windowOpenService);
    return Array.from(columns.values());
}

export const CSV_MANDATORY_FIELDS: string[] = [
    CsvHeaderName.transactionTime,
    CsvHeaderName.symbol,
    CsvHeaderName.side,
    CsvHeaderName.quantity,
    CsvHeaderName.price
];

export const TableMenuItems = {
    delete: (params: GetContextMenuItemsParams): MenuItemDef => ({
        name: 'Delete Row',
        action: () => {
            const selectedRows = [params.node.data];
            params.api.applyTransaction({ remove: selectedRows });

            const colDef = params.column.getColDef();
            colDef.cellStyle = { backgroundColor: '#f00' };
        }
    }),
    copy: 'copy',
    selectClientId: (
        searchType: TraderSearchType,
        params: GetContextMenuItemsParams,
        selectAction: (clientId: string) => void
    ): MenuItemDef => ({
        name: 'Select ' + TraderSearchTypes[searchType],
        disabled: !params?.node?.data?.clientId,
        action: () => {
            const selectedClientId = params.node.data[searchType];
            selectAction(selectedClientId);
        }
    }),
    openInAdvancedSearch: (
        tableType: TableType,
        params: GetContextMenuItemsParams,
        advancedSearchService: AdvancedSearchService,
        windowOpenService: WindowOpenService
    ): MenuItemDef => ({
        name: 'Open in Advanced Search',
        icon: SEARCH_ICON,
        action: () => {
            advancedSearchService.transferRouteData.setData({
                [tableType]: { id: [params.value] }
            });
            windowOpenService.open('/advanced-search');
        }
    })
};

export const DEFAULT_EDIT_COLDEF: ColDef = {
    minWidth: 180,
    resizable: true,
    headerComponent: TblCSVSelectHeaderComponent
};

export interface IDownloadTableDialogData {
    totalEvents: number;
    pageSize: number;
    contentType: MITableType;
    showRange: boolean;
}

export interface IPageRange {
    fromIndex: number;
    toIndex: number;
    pageSize: number;
}

export interface IDownloadTableDialogResult {
    exportSelectedColumns: boolean;
    isCustomPageRange: boolean;
    pageRange?: IPageRange;
    eventType: MITableType;
    columns?: string[];
}

export interface SingleErrorObject {
    errorMessage: string;
}

export interface IEventsSearchParams {
    values: string[];
    type: TraderSearchType;
}

export interface ISearchQueryParams {
    [key: string]: string[];
}

export interface IOrderExecutionRequest {
    fromTime: number;
    toTime: number;
    symbolsPair: ISymbolsPair[];
    searchCriteria?: ISearchCriteria;
    side?: EventSide;
    isDerivatives?: boolean;
}

export interface ISearchCriteria {
    searchParams?: ISearchQueryParams;
    searchComparisonParam?: ISearchComparisonParam;
    alertRelatedIds?: string[];
    clickedEvent?: ClickedEvent;
}

export enum FieldType {
    NUMBER = 'number',
    AUTOCOMPLATE = 'autocomplete'
}

export const eventTypeMapper = {
    Order: false,
    Execution: true
};

export type ComparisonOperators = 'to' | 'from';

export type ISearchComparisonParam = {
    [key: string]: { [key: string]: number };
};

export interface IAlertTableRequest {
    createdAt?: {
        from: number;
        to: number;
    };
    fromTime?: {
        from: number;
        to: number;
    };
    symbols?: string[];
    origins?: [OriginType];
    isAdditionalInfoEnabled: boolean;
}

export interface IDownloadTableRequest extends IOrderExecutionRequest {
    pageRange?: IPageRange;
    columns: string[];
}

export const getDefaultAlertColumns = (
    opt: {
        rowColorRule?: any;
        traderSearchType?: TraderSearchType;
    } = {},
    dict: DictService,
    iconByStatusPipe: IconByStatusPipe,
    windowOpenService: WindowOpenService
): Map<string, ColDef> => {
    const { rowColorRule, traderSearchType } = opt;
    const defaultColumns: Array<[string, ColDef]> = [
        [AlertHeaderName.Id, getColorStyleColum(rowColorRule, traderSearchType, true)],
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Id, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.CaseId, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.AlertType, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.CreatedAt, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.FromTime, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.ToTime, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Accounts, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Actors, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Clients, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Symbols, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.CaseStatus, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.Score, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.ManipulationValue, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.ThresholdBreach, windowOpenService),
        getAlertTableColEntity(dict, iconByStatusPipe, AlertHeaderName.ThresholdValue, windowOpenService)
    ];
    return new Map(defaultColumns);
};
