import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DictService } from './localize/dict.service';
import { SystemEnums } from './_shared/shared.models';
import { forkJoin, Observable } from 'rxjs';
import { ReAuthService } from './_services/re-auth/re-auth.service';

const ENV_VAR_PATH = 'assets/environment/env-var.json';
const SYSTEM_DICT_PATH = '/assets/localize/enums/enums.en.json';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    constructor(private http: HttpClient, private dict: DictService, private reAuth: ReAuthService) {}

    initApp(): Promise<[any, any]> {
        return forkJoin([this.loadEnvVariables(), this.loadDictionary()])
            .toPromise()
            .then(data => {
                this.reAuth.initReAuth();
                return data;
            });
    }

    private loadEnvVariables(): Observable<any> {
        return this.http.get(ENV_VAR_PATH).pipe(
            tap(env => {
                Object.assign(environment, env);
            })
        );
    }

    private loadDictionary(): Observable<any> {
        return this.http.get<SystemEnums>(SYSTEM_DICT_PATH).pipe(tap(d => (this.dict.systemConstants = d)));
    }

}
