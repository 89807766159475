import _ from 'lodash';
import moment from 'moment';
import { ORDERBOOK_DATE_TIME_FORMAT } from '../components/order-book/orderbook.constants';
import { EventSide, LegendStatus } from '../enums/events.enum';
import { MarkerClassType, TimePeriod } from '../enums/market-inspection.enum';
import { MarkerType } from '../models/chart.models';
import {
    candleUnitsMs,
    ChartSeries,
    IBenchmark,
    IBenchmarkInterval,
    IBenchmarkIntervalMap,
    IChartInterval,
    ICSVRecords,
    IExchangeIntervalData,
    IMarketDataBody,
    IMarketInterval,
    IntervalsMap,
    IQueryParam,
    ISymbolIntervalMap,
    ITablePageResponse,
    MIChartRequestBody,
    OrderStatus,
    Period,
    timePeriodMs,
    TradeCategory
} from '../models/market-inspection.models';
import { IOrderBookInterval, IOrderBookItem, IOrderBookResponse } from '../models/orderbook.models';
import { asLocalDate, CHART_DATE_FORMAT, dateAsUtc, getMiDate, roundDate } from './data-time-utils';
import { MI_TABLE_ALERT_RELATED_SORT } from '../market-inspection.config';
import { SingleMarkerSize } from '../models/events.models';

export const formatChartData = (chartData: IMarketDataBody, exchanges: string[]): IChartInterval[] => {
    const charIntervals = formatSymbolPairIntervals(chartData.pairs[0].intervals, exchanges);
    if (chartData.pairs.length > 1) {
        chartData.pairs.forEach((symbolPair, i) => {
            if (i !== 0) {
                const symbolInterval = formatSymbolPairIntervals(chartData.pairs[i].intervals, [exchanges[0]]);
                const symbolIntervalsMap = formatToSymbolPairIntervalMap(symbolInterval);

                charIntervals.forEach(interval => {
                    interval[symbolPair.name] = symbolIntervalsMap[interval.Date];
                });
            }
        });
    }

    return charIntervals;
};

export const chartDataSymbolOrder = (chartData: IMarketDataBody, body: MIChartRequestBody): IMarketDataBody => {
    if (body?.symbolsPair.length > 1) {
        const firstSymbole = `${body.symbolsPair[0].fromSymbol}/${body.symbolsPair[0].toSymbol}`;
        if (chartData.pairs.length > 1 && firstSymbole !== chartData.pairs[0].name) {
            const swap = chartData.pairs[0];
            chartData.pairs[0] = chartData.pairs[1];
            chartData.pairs[1] = swap;
        }
    }

    return chartData;
};

export const formatSymbolPairIntervals = (intervals: IMarketInterval[], exchanges: string[]): IChartInterval[] => {
    const firstExchangeName: string = intervals[0]?.exchanges[0]?.exchange ?? exchanges[0];

    return intervals.map(interval => {
        const firstExchange = interval.exchanges.find(exchangeItem => exchangeItem.exchange === firstExchangeName);
        // First exchange data
        const returnedInterval = {
            Close: firstExchange?.close,
            Open: firstExchange?.open,
            High: firstExchange?.high,
            Low: firstExchange?.low,
            Volume: firstExchange?.volume,
            Date: interval.date // have to keep date here for the Benchmark comparison
        };

        interval.exchanges.forEach(exchangeItem => {
            returnedInterval[exchangeItem.exchange] = {
                Close: exchangeItem.close,
                Open: exchangeItem?.open,
                High: exchangeItem?.high,
                Low: exchangeItem?.low,
                Volume: exchangeItem?.volume,
                DT: dateAsUtc(interval.date)
            };
        });

        return returnedInterval;
    });
};

const formatToSymbolPairIntervalMap = (intervals: IChartInterval[]): IntervalsMap => {
    const intervalsMap = {};
    intervals.forEach(interval => {
        intervalsMap[interval.Date] = interval;
    });

    return intervalsMap;
};

export const formatToBenchmarkIntervalMap = (intervals: IBenchmarkInterval[]): IBenchmarkIntervalMap => {
    const benchmarkDataMap = {};
    intervals.forEach(interval => {
        benchmarkDataMap[interval.date] = {
            [ChartSeries.BestBid]: { Date: interval.date, Close: interval.benchmarks.bestBid },
            [ChartSeries.BestAsk]: { Date: interval.date, Close: interval.benchmarks.bestAsk },
            [ChartSeries.MidPrice]: { Date: interval.date, Close: getMidPrice(interval.benchmarks) }
        };
    });

    return benchmarkDataMap;
};

export const formatToSecondarySymbolsMap = (chartData: IMarketDataBody, exchanges: string[]): ISymbolIntervalMap => {
    const isEmptyResponse = !chartData?.pairs;
    if (isEmptyResponse) {
        return {};
    }

    const firstExchangeName: string = chartData.pairs[0].intervals[0]?.exchanges[0]?.exchange ?? exchanges[0];
    const symbolIntervalMap: ISymbolIntervalMap = {};

    chartData.pairs.forEach(pair => {
        pair.intervals.forEach(interval => {
            const exchangeData = interval.exchanges.find(exchange => exchange.exchange === firstExchangeName);
            if (exchangeData) {
                if (!symbolIntervalMap[pair.name]) {
                    symbolIntervalMap[pair.name] = [];
                }
                symbolIntervalMap[pair.name].push({
                    Date: interval.date,
                    Close: exchangeData.close,
                    Volume: exchangeData.volume
                });
            }
        });
        symbolIntervalMap[pair.name]?.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
    });

    return symbolIntervalMap;
};

export const formatBenchmarkInterval = (intervals: IBenchmarkInterval[]): any => {
    return intervals.map(interval => {
        const date = getMiDate(interval.date);
        return {
            Date: date,
            [ChartSeries.BestBid]: { Date: date, Close: interval.benchmarks.bestBid },
            [ChartSeries.BestAsk]: { Date: date, Close: interval.benchmarks.bestAsk },
            [ChartSeries.MidPrice]: { Date: date, Close: getMidPrice(interval.benchmarks) }
        };
    });
};

const getMidPrice = (benchmarks: IBenchmark): number => {
    return (benchmarks.bestBid + benchmarks.bestAsk) / 2;
};

export const csvStringToJson = (csvDataString: string, delimiter: string): ICSVRecords[] => {
    const regexPattern = new RegExp(
        `(\\${delimiter}|\\r?\\n|\\r|^)(?:\\"((?:\\\\.|\\"\\"|[^\\\\\\"])*)\\"|([^\\${delimiter}\\"\\r\\n]*))`,
        'gi'
    );
    let matchedPatternArray = regexPattern.exec(csvDataString);
    const resultCSV: ICSVRecords[] = [];
    let rowObj: ICSVRecords = {};
    let columnCounter = 1;
    let rowCounter = 0;
    while (matchedPatternArray) {
        if (matchedPatternArray[1].length && matchedPatternArray[1] !== delimiter) {
            resultCSV.push(rowObj);
            rowObj = {};
            columnCounter = 1;
            rowObj['id'] = rowCounter;
            rowCounter++;
        }
        const cleanValue = matchedPatternArray[2]
            ? matchedPatternArray[2].replace(new RegExp('[\\\\"](.)', 'g'), '$1')
            : matchedPatternArray[3];

        rowObj[`column${columnCounter}`] = cleanValue;
        columnCounter++;
        matchedPatternArray = regexPattern.exec(csvDataString);
    }
    return resultCSV;
};

export const toOrderBookPageModel = (orderBook: IOrderBookResponse): IOrderBookInterval[] => {
    const pairs = orderBook?.pairs;
    if (pairs && pairs.length) {
        const intervals = orderBook?.pairs[0]?.intervals;
        if (intervals && intervals.length) {
            return intervals.map(item => {
                const date = moment(getMiDate(item.date)).format(ORDERBOOK_DATE_TIME_FORMAT);
                return {
                    date,
                    ask: toOrderBookItem(item.ask, date),
                    bid: toOrderBookItem(item.bid, date)
                };
            });
        }
    }
    return [];
};

export const toOrderBookItem = (items: number[][], date: string): IOrderBookItem[] => {
    return items?.map(item => ({
        price: item[0],
        amount: item[1],
        transactionTime: date,
        total: item[0] * item[1]
    }));
};

export const toOrderExecutionTableData = (data: ITablePageResponse): ITablePageResponse => {
    return {
        ...data,
        items: data.items.map((item, index) => ({
            ...item,
            id: `${index}`
        }))
    };
};

export const orderStatusToMarkerType = (status: string): MarkerType => {
    switch (status.toLowerCase()) {
        case OrderStatus.filled:
        case OrderStatus.partiallyfilled:
            return MarkerType.Execution;
        case OrderStatus.cancelled:
        case OrderStatus.canceled:
            return MarkerType.Cancel;
        case OrderStatus.new:
            return MarkerType.New;
        case OrderStatus.rejected:
            return MarkerType.Rejected;
        case OrderStatus.expired:
            return MarkerType.Expired;
        case OrderStatus.canceledfill:
            return MarkerType.CanceledFill;
        case OrderStatus.replaced:
            return MarkerType.Replaced;
        default:
            return MarkerType.Unknown;
    }
};

export const legendStatusToMarkerType = (status: LegendStatus): MarkerType => {
    switch (status) {
        case LegendStatus.cancel:
            return MarkerType.Cancel;
        case LegendStatus.new:
            return MarkerType.New;
        case LegendStatus.rejected:
            return MarkerType.Rejected;
        case LegendStatus.expired:
            return MarkerType.Expired;
        case LegendStatus.canceledFill:
            return MarkerType.CanceledFill;
        case LegendStatus.replaced:
            return MarkerType.Replaced;
        case LegendStatus.execution:
            return MarkerType.Execution;
        default:
            return MarkerType.Unknown;
    }
};

export const clientMarkerCategory = (
    prefix: string,
    markerSize: SingleMarkerSize,
    clientNumber: number,
    symbol: string
): string => {
    // the order is important, see extractMarkerCategorySymbol()
    return `${prefix}-${markerSize}-group-${clientNumber}:${symbol}`;
};

export const extractMarkerCategorySymbol = (category: string): string => {
    return _.last(category.split(':'));
};

export const getSide = (side: string): EventSide => {
    const slc = side?.toLowerCase();
    if (slc === 'sell') {
        return EventSide.sell;
    } else if (slc === 'buy') {
        return EventSide.buy;
    }
    return null;
};

export const getMarkerSideClass = (side: EventSide): MarkerClassType => {
    return side == EventSide.buy ? 'buy-marker' : 'sell-marker';
};

export const getTimePeriodMS = (timePeriod: TimePeriod): number => {
    return timePeriodMs[timePeriod];
};

export const getCandlePeriodMS = (candlePeriod: Period): number => {
    return candleUnitsMs[candlePeriod.unit] * candlePeriod.quantity;
};

export const roundUpMinutes = (ts: number): number => {
    const date = new Date(ts);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime();
};

export const createFullPeriodCandlesEmptyChart = (body: MIChartRequestBody): IMarketDataBody => {
    const lastRequestDate = asLocalDate(body.toTime);
    const lastCandleDate = roundDate(lastRequestDate, body.period);
    const lastDate = moment(lastCandleDate).format(CHART_DATE_FORMAT);

    const firstRequestDate = asLocalDate(body.fromTime);
    const firstCandleDate = roundDate(firstRequestDate, body.period);
    const firstDate = moment(firstCandleDate).format(CHART_DATE_FORMAT);

    return {
        pairs: [
            {
                name: '',
                intervals: [
                    {
                        date: firstDate,
                        exchanges: [{ close: undefined } as IExchangeIntervalData]
                    },
                    {
                        date: lastDate,
                        exchanges: [{ close: undefined } as IExchangeIntervalData]
                    }
                ]
            }
        ]
    };
};

export const addFullPeriodCandles = (chartData: IMarketDataBody, body: MIChartRequestBody): IMarketDataBody => {
    addLastData(chartData, body);
    addFirstData(chartData, body);
    return chartData;
};

export const addLastData = (chartData: IMarketDataBody, body: MIChartRequestBody): void => {
    const intervals = chartData.pairs[0].intervals;
    const lastCandle = _.last(intervals);

    const lastRequestDate = getMiDate(body.toTime);
    const lastCandleDate = roundDate(lastRequestDate, body.period);
    const lastResponseDate = dateAsUtc(lastCandle.date);

    if (lastCandleDate > lastResponseDate) {
        const close = lastCandle.exchanges[0].close;
        const newLastCandle = createEmptyCandle(close, lastCandleDate);
        intervals.push(newLastCandle);
    }
};

export const addFirstData = (chartData: IMarketDataBody, body: MIChartRequestBody): void => {
    const intervals = chartData.pairs[0].intervals;
    const firstCandle = _.first(intervals);

    const firstRequestDate = getMiDate(body.fromTime);
    const firstCandleDate = roundDate(firstRequestDate, body.period);
    const firstResponseDate = dateAsUtc(firstCandle.date);

    if (firstCandleDate < firstResponseDate) {
        const close = firstCandle.exchanges[0].close;
        const newLastCandle = createEmptyCandle(close, firstCandleDate);
        intervals.unshift(newLastCandle);
    }
};

const createEmptyCandle = (close: number, lastCandleDate: Date) => {
    const date = moment(lastCandleDate).utc().format(CHART_DATE_FORMAT);
    return {
        date,
        exchanges: [{ close } as IExchangeIntervalData]
    };
};

export const booleanToTraderCategory = (isDerivatives: boolean): TradeCategory =>
    isDerivatives ? TradeCategory.Derivatives : TradeCategory.Spot;

export const SORT_DELIMITER = '-';

export const generateStringParams = (queryParam: IQueryParam): string => {
    const keys = Object.keys(queryParam);
    const params = keys.map(key => {
        if (key !== 'sort') {
            return `${key}=${queryParam[key]}`;
        } else {
            let sortPramsArr = (queryParam[key] as string).split(SORT_DELIMITER).filter(v => v);
            if (sortPramsArr.includes(MI_TABLE_ALERT_RELATED_SORT) && sortPramsArr.length > 1) {
                const _params = sortPramsArr.filter(param => param !== MI_TABLE_ALERT_RELATED_SORT);
                _params.unshift(MI_TABLE_ALERT_RELATED_SORT);
                sortPramsArr = _params;
            }
            return sortPramsArr.length ? sortPramsArr.map(v => `sort=${v}`).join('&') : '';
        }
    });
    const filteredParams = params.filter(v => v).join('&');
    return '?' + filteredParams;
};
