import { Injectable } from '@angular/core';
import { IEventOrder } from '../../models/events.models';
import { MarketInspectionChartFacade } from '../../state/market-inspection-chart.facade';
import { calculateMinMax } from '../../utils/chart-utils';
import {
    MiEventsIntervalStatisticDto,
    MiEventsStatisticDto,
    MiSideStatisticDto,
    MiStatisticDto,
} from '../../models/events-aggregation.models';
import { isNullOrUndefined } from '../../../_shared/shared.functions';

interface MinMaxResult {
    [key: string]: IMinMax;
}

export interface IClientEventsSize {
    maxM: number;
    maxXS: number;
}

export class IMinMax {
    min;
    max;

    constructor(min = Number.MAX_VALUE, max = Number.MIN_VALUE) {
        this.min = min;
        this.max = max;
    }

    mergeMinMax(minMax: IMinMax): void {
        const { min, max } = calculateMinMax(minMax, this);
        this.min = min;
        this.max = max;
    }

    handleNumber(num: number) {
        if (!isNullOrUndefined(num)) {
            this.min = Math.min(this.min, num);
            this.max = Math.max(this.max, num);
        }
    }
}

enum NumberFieldName {
    amount = 'amount',
    price = 'price',
}

@Injectable({
    providedIn: 'root',
})
export class ClientEventsHandlerService {
    constructor(private marketInspectionChartFacade: MarketInspectionChartFacade) {}

    public setClientsEventsMinMax(events: IEventOrder[]): void {
        const commonMinMax: MinMaxResult = this.findMinAndMax(events, [NumberFieldName.amount, NumberFieldName.price]);
        const minMaxPrice: IMinMax = commonMinMax[NumberFieldName.price];
        this.marketInspectionChartFacade.updateSingleEventsPriceMinMax(minMaxPrice);
    }

    public handleAggregationSingleEventsMinMaxPrice(events: IEventOrder[]): void {
        const minMaxPrice = this.getMinMaxPrice(events);
        this.marketInspectionChartFacade.updateAggregationEventsPriceMinMax(minMaxPrice);
    }

    public handleSmartAggregationSingleEventsMinMaxPrice(
        events: IEventOrder[],
        statistics: MiEventsIntervalStatisticDto[],
    ): void {
        const minMaxPrice: IMinMax = this.getMinMaxPrice(events);
        const minMaxVwap: IMinMax = this.getMinMaxVwap(statistics);
        minMaxPrice.mergeMinMax(minMaxVwap);
        this.marketInspectionChartFacade.updateSmartAggregationEventsPriceMinMax(minMaxPrice);
    }

    public handleAlertRelatedEventsMinMaxPrice(events: IEventOrder[]): void {
        const minMaxPrice = this.getMinMaxPrice(events);
        this.marketInspectionChartFacade.updateAlertRelatedEventsPriceMinMax(minMaxPrice);
    }

    private getMinMaxPrice(events: IEventOrder[]): IMinMax {
        const minMax = this.findMinAndMax(events, [NumberFieldName.price]);
        return minMax[NumberFieldName.price];
    }

    private findMinAndMax(events: IEventOrder[], fieldNameList: NumberFieldName[]): MinMaxResult {
        const res: MinMaxResult = {
            [NumberFieldName.amount]: new IMinMax(),
            [NumberFieldName.price]: new IMinMax(-1, -1),
        };

        events.forEach((event) => {
            event.versions.forEach((version) => {
                fieldNameList.forEach((fieldName) => {
                    const field = version[fieldName];
                    const minMax = res[fieldName];
                    const min = fieldName === NumberFieldName.price && minMax.min < 0 ? Number.MAX_VALUE : minMax.min;
                    minMax.min = Math.min(min, field);
                    minMax.max = Math.max(minMax.max, field);
                });
            });
        });
        return res;
    }

    private getMinMaxVwap(statistics: MiEventsIntervalStatisticDto[]): IMinMax {
        const minMax = new IMinMax();
        statistics
            .flatMap((i) => i.eventsStat)
            .flatMap((clientStat: MiEventsStatisticDto) => Object.values(clientStat))
            .forEach((stat: MiSideStatisticDto) => {
                const handle = (sideStat: MiStatisticDto) => sideStat && minMax.handleNumber(sideStat.vwap);

                handle(stat.buy);
                handle(stat.sell);
            });
        return minMax;
    }
}
