import { Clipboard } from '@angular/cdk/clipboard';

import { WindowOpenService } from 'src/app/_services/window-open/window-open.service';
import { AdvancedSearchService } from 'src/app/advanced-search/advanced-search-service/advanced-search.service';
import { AccountActorRoleDisplayField } from 'src/app/_shared/shared.models';
import { TableType } from 'src/app/advanced-search/advanced-search.models';

import moment, { Moment } from 'moment';

export interface IQuickActionItemConfig {
    title: string;
    icon?: string;
    isLabel?: boolean;
    subItems?: QuickActionItem[];
}

export interface ICopyQuickActionItemConfig {
    clipboard: Clipboard;
    entityIds: string[];
}

export interface ISearchQuickActionItemConfig extends IQuickActionItemConfig {
    entityId: string;
    entityKey: AccountActorRoleDisplayField;
    tableType: TableType;
    advancedSearchService: AdvancedSearchService;
    createdAtTime: number;
}

export interface ISearchQuickActionMiItemConfig extends ISearchQuickActionItemConfig {
    isMiScreen: boolean;
    onActionCallback?: () => void;
}

export class QuickActionItem {
    get title(): string {
        return this.config.title;
    }

    get icon(): string | null {
        return this.config.icon ?? null;
    }

    get isLabel(): boolean {
        return this.config.isLabel ?? false;
    }

    get subItems(): QuickActionItem[] {
        return this.config.subItems ?? [];
    }

    get hasSubItems(): boolean {
        return this.subItems.length > 0;
    }

    constructor(public readonly config: IQuickActionItemConfig) {}

    // optional method for inherited classes
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public action(): void {}
}

export class CopyQuickActionItem extends QuickActionItem {
    constructor(private readonly copyConfig: ICopyQuickActionItemConfig) {
        super({ title: 'Copy', icon: 'copy' });
    }

    action(): void {
        this.copyConfig.clipboard.copy(this.copyConfig.entityIds.join(', \n'));
    }
}

export class SearchQuickActionItem extends QuickActionItem {
    get entityId(): string {
        return this.config.entityId;
    }

    constructor(
        public readonly config: ISearchQuickActionMiItemConfig,
        private windowOpenService: WindowOpenService
    ) {
        super(config);
    }

    action(): void {
        const field = this.config.entityKey === 'actorId' ? 'actor' : this.config.entityKey;
        const value = this.config.entityKey === 'clientId' ? [this.entityId] : this.entityId;

        let timeParams = {};
        if (this.config.createdAtTime) {
            const fromTime: Moment = [TableType.Cases, TableType.Alerts].includes(this.config.tableType)
                ? moment.utc(this.config.createdAtTime).subtract(30, 'days')
                : moment.utc(this.config.createdAtTime).subtract(24, 'hours');

            timeParams = {
                fromTime: fromTime.startOf('day').valueOf(),
                toTime: moment.utc(this.config.createdAtTime).endOf('day').valueOf()
            };
        }

        this.config.advancedSearchService.transferRouteData.setData({
            [this.config.tableType]: {
                [field]: value,
                ...timeParams
            }
        });

        if (this.config.isMiScreen) {
            if (this.config.onActionCallback) {
                this.config.onActionCallback();
            }
        } else {
            this.windowOpenService.open('/advanced-search');
        }
    }
}
