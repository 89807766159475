import { ColDef, ValueFormatterParams } from 'ag-grid-enterprise';
import { WindowOpenService } from 'src/app/_services/window-open/window-open.service';
import { numberWithCommas, formatNumberTwoDecimals } from 'src/app/_shared/shared.functions';
import { ScoreCellRendererComponent } from 'src/app/ag-grid-tables/shared/components/cell-renderers/score-cell-renderer/score-cell-renderer.component';
import { TrdsLabelCellRendererComponent } from 'src/app/ag-grid-tables/shared/components/cell-renderers/trds-label-cell-renderer/trds-label-cell-renderer.component';
import { TrdsLabelMultipleCellRendererComponent } from 'src/app/ag-grid-tables/shared/components/cell-renderers/trds-label-multiple-cell-renderer/trds-label-multiple-cell-renderer.component';
import { getNameFromDictionary, getCustomNumberColumnFilter } from 'src/app/ag-grid-tables/shared/models.shared';
import { DictService } from 'src/app/localize/dict.service';
import { BaseAlert } from 'src/app/ucm/alerts/alerts.models';
import { IconByStatusPipe } from 'src/app/_shared/pipes/icon-by-status.pipe';
import { ALERT_TBL_FIELD_MAPPER } from '../../market-inspection.config';
import { MIColors, RowColorRule } from '../../models/market-inspection.models';
import { toDateFormat } from '../../utils/data-time-utils';
import { toEventType, toTitleCase } from '../../utils/table-utils';
import { DateCellComponent } from './components/date-cell/date-cell.component';
import { TraderSearchType, eventTypeMapper } from './table.model';
import { EventTypeCellRendererComponent } from './components/event-type-cell-renderer/event-type-cell-renderer.component';
import { LegendStatus } from '../../enums/events.enum';
import { MiInstrumentMetadata, getDerivativesMetadata } from '../../models/instrument-metadata.models';

export const fetchFromDerivativesMetadata = (params: any, field: TableHeader) => {
    const derivatives: MiInstrumentMetadata = getDerivativesMetadata(params.data.symbol);
    return cellRenderer(derivatives?.[field] ?? params.value);
};

// the names of the enum elements are to do with backend implementation - don't change
export enum TableHeader {
    colorStyleColumn = 'colorStyleColumn',
    transactionTime = 'transactionTime',
    clientId = 'clientId',
    matchingClientId = 'matchingClientId',
    account = 'account',
    actorId = 'actorId',
    version = 'version',
    symbol = 'symbol',
    price = 'price', // is used in the CSV table
    orderPrice = 'orderPrice',
    side = 'side',
    orderType = 'orderType',
    orderStatus = 'orderStatus',
    orderQty = 'orderQty',
    localCurrency = 'localCurrency',
    localNotional = 'localNotional',
    avgPrice = 'avgPrice',
    executionVenue = 'executionVenue',
    leavesQty = 'leavesQty',
    quantity = 'quantity', // is used in alert view
    id = 'id',
    eventId = 'eventId',
    cumQty = 'cumQty',
    orderCapacity = 'orderCapacity',

    isAlertRelated = 'isAlertRelated',

    // Executions
    matchingOrderId = 'matchingOrderId',
    orderId = 'orderId',
    execId = 'execId',
    execPrice = 'execPrice',

    // Alert
    eventType = 'eventType',
    executionId = 'executionId',
    executionType = 'executionType',

    // Derivatives
    securityType = 'securityType',
    exchangeSymbol = 'exchangeSymbol',
    expirationDateTime = 'expirationDateTime',
    settleDateTime = 'settleDateTime',
    positionEffect = 'positionEffect',
    putCall = 'putCall',
    strikePrice = 'strikePrice',
    fundingRate = 'fundingRate',
    contractMultiplier = 'contractMultiplier',
    cfiCode = 'cfiCode',
    exerciseStyle = 'exerciseStyle',
    strikeValue = 'strikeValue',
    matchingAccountId = 'matchingAccountId',
    matchingActorId = 'matchingActorId',
    isExecution = 'isExecution',
    trdType = 'trdType',
    contingencyType = 'contingencyType',
    timeInForce = 'timeInForce',
    origTransactTime = 'origTransactTime',
    buIdentifier = 'buIdentifier',
    matchingExecutionId = 'matchingExecutionId',
    stopPx = 'stopPx',
    ipAddress = 'ipAddress',
    matchingIpAddress = 'matchingIpAddress',
    matchingOrderCapacity = 'matchingOrderCapacity'
}

// the keys of the enum elements are to do with backend implementation - don't change
export enum CsvHeaderName {
    transactionTime = 'Transaction Time',
    symbol = 'Symbol',
    side = 'Side',
    quantity = 'Quantity',
    price = 'Price',
    clientId = 'Client ID',
    account = 'Account ID',
    actorId = 'Actor ID',
    version = 'Version',
    orderType = 'Order Type',
    orderStatus = 'Order Status',
    leavesQty = 'Leaves Qty',
    sumQty = 'Sum Qty',
    localCurrency = 'Local Currency',
    localNotional = 'Local Notional',
    avgPrice = 'Avg Price',
    executionVenue = 'Execution Venue',
    id = 'ID',
    cumQty = 'Cum Qty'
}

export const TableColumn: { [key: string]: ColDef } = {
    [TableHeader.isAlertRelated]: {
        field: TableHeader.isAlertRelated,
        suppressHeaderMenuButton: true,
        suppressColumnsToolPanel: true,
        hide: true,
        sortable: true
    },
    [TableHeader.eventId]: { field: TableHeader.eventId, headerName: 'ID', width: 130 },
    [TableHeader.account]: {
        field: TableHeader.account,
        headerName: 'Account ID (P)',
        cellRenderer: param => cellRenderer(param.value),
        width: 150
    },
    [TableHeader.clientId]: {
        field: TableHeader.clientId,
        headerName: 'Client ID (P)',
        cellRenderer: param => cellRenderer(param.value),
        width: 160
    },
    [TableHeader.actorId]: {
        field: TableHeader.actorId,
        headerName: 'Actor ID (P)',
        cellRenderer: param => cellRenderer(param.value),
        width: 140
    },
    [TableHeader.matchingAccountId]: {
        field: TableHeader.matchingAccountId,
        headerName: 'Matching Account',
        width: 150,
        cellRenderer: param => cellRenderer(param.value)
    },

    [TableHeader.matchingActorId]: {
        field: TableHeader.matchingActorId,
        headerName: 'Matching Actor',
        cellRenderer: param => cellRenderer(param.value),
        width: 140
    },

    [TableHeader.matchingClientId]: {
        field: TableHeader.matchingClientId,
        headerName: 'Matching Client ID',
        cellRenderer: param => cellRenderer(param.value),
        width: 160
    },

    [TableHeader.transactionTime]: {
        field: TableHeader.transactionTime,
        cellRenderer: DateCellComponent,
        headerName: 'Timestamp',
        width: DateCellComponent.defaultDateColumnWidth
    },

    [TableHeader.executionType]: {
        field: TableHeader.executionType,
        headerName: 'Exec Type',
        cellRenderer: param => cellRenderer(param.value),
        valueFormatter: params => eventTypeMapper[params.value],
        width: 160,
        hide: true
    },

    [TableHeader.version]: { field: TableHeader.version, headerName: 'Version', width: 130, hide: true },
    [TableHeader.symbol]: { field: TableHeader.symbol, headerName: 'Symbol', width: 200, hide: true },
    [TableHeader.orderPrice]: {
        field: TableHeader.orderPrice,
        headerName: 'Order Price',
        width: 155,
        valueFormatter: params => numberWithCommas(params.value),
        ...getCustomNumberColumnFilter(),
        cellRenderer: param => cellRenderer(param.valueFormatted)
    },
    [TableHeader.execPrice]: {
        field: TableHeader.execPrice,
        headerName: 'Exec Price',
        width: 150,
        cellRenderer: param => cellRenderer(param.valueFormatted),
        valueFormatter: params => numberWithCommas(params.value),
        ...getCustomNumberColumnFilter()
    },
    [TableHeader.side]: {
        field: TableHeader.side,
        headerName: 'Side',
        width: 110,
        cellStyle: params =>
            params.value?.toLowerCase() == 'sell' ? { color: MIColors.SellColor } : { color: MIColors.BuyColor },
        cellRenderer: params => toTitleCase(params.value)
    },
    [TableHeader.orderType]: {
        field: TableHeader.orderType,
        headerName: 'Order Type',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },
    [TableHeader.orderStatus]: {
        field: TableHeader.orderStatus,
        headerName: 'Status',
        width: 130
    },
    [TableHeader.contingencyType]: {
        field: TableHeader.contingencyType,
        headerName: 'Contingency Type',
        cellRenderer: param => cellRenderer(param.value),
        width: 180,
        hide: true
    },
    [TableHeader.timeInForce]: {
        field: TableHeader.timeInForce,
        headerName: 'Time In Force',
        cellRenderer: param => cellRenderer(param.value),
        width: 180,
        hide: true
    },
    [TableHeader.origTransactTime]: {
        field: TableHeader.origTransactTime,
        headerName: 'Orig Transact Time',
        cellRenderer: DateCellComponent,
        width: 180,
        hide: true
    },

    [TableHeader.localCurrency]: {
        field: TableHeader.localCurrency,
        headerName: 'Local Currency',
        width: 175,
        hide: true
    },
    [TableHeader.localNotional]: {
        field: TableHeader.localNotional,
        headerName: 'Local Notional',
        width: 175,
        hide: true,
        ...getCustomNumberColumnFilter(),
        valueFormatter: params => formatNumberTwoDecimals(params.value)
    },
    [TableHeader.avgPrice]: { colId: TableHeader.avgPrice, field: 'Avg Price', width: 140, hide: true },
    [TableHeader.executionVenue]: { field: TableHeader.executionVenue, headerName: 'Ex Venue', width: 150, hide: true },
    [TableHeader.matchingExecutionId]: {
        field: TableHeader.matchingExecutionId,
        headerName: 'Matching Exec ID',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },

    [TableHeader.orderQty]: {
        field: TableHeader.orderQty,
        headerName: 'Ord Qty',
        valueFormatter: params => numberWithCommas(params.value),
        cellRenderer: param => cellRenderer(param.valueFormatted),
        width: 140,
        ...getCustomNumberColumnFilter()
    },
    [TableHeader.quantity]: {
        field: TableHeader.quantity,
        headerName: 'Exec Qty',
        cellRenderer: param => cellRenderer(param.valueFormatted),
        width: 140,
        valueFormatter: params => numberWithCommas(params.value),
        ...getCustomNumberColumnFilter()
    },
    [TableHeader.leavesQty]: {
        field: TableHeader.leavesQty,
        headerName: 'Leaves Qty',
        cellRenderer: param => cellRenderer(param.value),
        ...getCustomNumberColumnFilter(),
        hide: true,
        width: 150
    },
    [TableHeader.orderCapacity]: {
        field: TableHeader.orderCapacity,
        headerName: 'Order Capacity',
        cellRenderer: param => cellRenderer(param.value),
        width: 175,
        hide: true
    },
    [TableHeader.cumQty]: {
        field: TableHeader.cumQty,
        headerName: 'Cum Qty',
        cellRenderer: param => cellRenderer(param.value),
        ...getCustomNumberColumnFilter(),
        width: 150,
        hide: true
    },

    [TableHeader.buIdentifier]: {
        field: TableHeader.buIdentifier,
        headerName: 'BU Identifier',
        cellRenderer: param => cellRenderer(param.value),
        width: 200,
        hide: true
    },

    [TableHeader.matchingOrderId]: {
        field: TableHeader.matchingOrderId,
        headerName: 'Matching Order ID',
        cellRenderer: param => cellRenderer(param.value),
        width: 200,
        hide: true
    },
    [TableHeader.orderId]: {
        field: TableHeader.orderId,
        headerName: 'Order ID',
        cellRenderer: param => cellRenderer(param.value),
        width: 200,
        hide: false
    },
    [TableHeader.execId]: {
        field: TableHeader.execId,
        headerName: 'Exec ID',
        cellRenderer: param => cellRenderer(param.value),
        width: 200,
        hide: false
    },
    [TableHeader.securityType]: {
        field: TableHeader.securityType,
        headerName: 'Security Type',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.securityType),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.securityType),
        width: 170,
        hide: true,
        sortable: false
    },
    [TableHeader.exchangeSymbol]: {
        field: TableHeader.exchangeSymbol,
        headerName: 'Exchange Symbol',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.exchangeSymbol),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.exchangeSymbol),
        width: 190,
        hide: true,
        sortable: false
    },
    [TableHeader.expirationDateTime]: {
        field: TableHeader.expirationDateTime,
        headerName: 'Expiration Date Time',
        width: DateCellComponent.defaultDateColumnWidth,
        cellRenderer: DateCellComponent,
        cellRendererParams: {
            getValueCb: fetchFromDerivativesMetadata.bind(this),
            field: TableHeader.expirationDateTime
        },
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.expirationDateTime),
        hide: true,
        sortable: false
    },

    [TableHeader.settleDateTime]: {
        field: TableHeader.settleDateTime,
        headerName: 'Settle Date Time',
        width: DateCellComponent.defaultDateColumnWidth,
        cellRenderer: DateCellComponent,
        cellRendererParams: {
            getValueCb: fetchFromDerivativesMetadata.bind(this),
            field: TableHeader.settleDateTime
        },
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.settleDateTime),
        hide: true,
        sortable: false
    },
    [TableHeader.positionEffect]: {
        field: TableHeader.positionEffect,
        headerName: 'Position Effect',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.positionEffect),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.positionEffect),
        width: 185,
        hide: true,
        sortable: false
    },
    [TableHeader.putCall]: {
        field: TableHeader.putCall,
        headerName: 'Put/Call',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.putCall),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.putCall),
        width: 150,
        hide: true,
        sortable: false
    },
    [TableHeader.strikePrice]: {
        field: TableHeader.strikePrice,
        headerName: 'Strike Price',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.strikePrice),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.strikePrice),
        width: 170,
        hide: true,
        sortable: false
    },
    [TableHeader.fundingRate]: {
        field: TableHeader.fundingRate,
        headerName: 'Funding Rate',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.fundingRate),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.fundingRate),
        width: 170,
        hide: true,
        sortable: false
    },
    [TableHeader.contractMultiplier]: {
        field: TableHeader.contractMultiplier,
        headerName: 'Contract Multiplier',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.contractMultiplier),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.contractMultiplier),
        width: 200,
        hide: true,
        sortable: false
    },
    [TableHeader.cfiCode]: {
        field: TableHeader.cfiCode,
        headerName: 'CFI Code',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.cfiCode),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.cfiCode),
        width: 145,
        hide: true,
        sortable: false
    },
    [TableHeader.exerciseStyle]: {
        field: TableHeader.exerciseStyle,
        headerName: 'Exercise Style',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.exerciseStyle),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.exerciseStyle),
        width: 175,
        hide: true,
        sortable: false
    },
    [TableHeader.strikeValue]: {
        field: TableHeader.strikeValue,
        headerName: 'Strike Value',
        cellRenderer: param => fetchFromDerivativesMetadata(param, TableHeader.strikeValue),
        useValueFormatterForExport: true,
        valueFormatter: param => fetchFromDerivativesMetadata(param, TableHeader.strikeValue),
        width: 175,
        hide: true,
        sortable: false
    },
    [TableHeader.eventType]: {
        field: TableHeader.eventType,
        headerName: 'Event Type',
        width: 150,
        cellRenderer: params => toEventType(params.value),
        hide: true
    },
    [TableHeader.isExecution]: {
        field: TableHeader.isExecution,
        headerName: 'Event Type',
        cellRenderer: EventTypeCellRendererComponent,
        width: 150,
        hide: true
    },

    [TableHeader.trdType]: {
        field: TableHeader.trdType,
        headerName: 'TRD Type',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },
    [TableHeader.stopPx]: {
        field: TableHeader.stopPx,
        headerName: 'Stop Px',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },
    [TableHeader.ipAddress]: {
        field: TableHeader.ipAddress,
        headerName: 'IP Address',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },
    [TableHeader.matchingIpAddress]: {
        field: TableHeader.matchingIpAddress,
        headerName: 'Matching Ip Address',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    },

    [TableHeader.matchingOrderCapacity]: {
        field: TableHeader.matchingOrderCapacity,
        headerName: 'Matching Order Capacity',
        cellRenderer: param => cellRenderer(param.value),
        width: 150,
        hide: true
    }
};

export const getColumStyle = (
    rowColorRule,
    traderSearchType,
    field: TableHeader | AlertHeaderName,
    headerName: string
): ColDef => ({
    field,
    headerName,
    width: DateCellComponent.defaultDateColumnWidth + 8, // because of border 0.5 rem we have to add additional pixels
    pinned: true,
    cellRenderer: DateCellComponent,
    valueFormatter: (params: ValueFormatterParams) => (isNaN(params.value) ? params.value : toDateFormat(params.value)),
    cellStyle: params => {
        if (rowColorRule) {
            const borderColor = rowColorRule[params.data[traderSearchType]] || '#151515';
            return { 'border-left': `0.5rem solid ${borderColor}` };
        } else {
            return { 'border-left': '0.5rem solid #151515' };
        }
    }
});

export const getColorStyleColum = (
    rowColorRule: RowColorRule,
    traderSearchType: TraderSearchType,
    isAlert = false
): ColDef => ({
    field: TableHeader.colorStyleColumn,
    headerName: '',
    width: 5,
    minWidth: 5,
    maxWidth: 5,
    pinned: 'left',
    lockPosition: 'left',
    enableCellChangeFlash: false,
    suppressHeaderMenuButton: true,
    suppressColumnsToolPanel: true,
    lockPinned: true,
    editable: false,
    suppressMovable: true,
    filter: false,
    resizable: false,
    cellRenderer: params => {
        return createBackground(isAlert, rowColorRule, traderSearchType, params);
    },
    cellStyle: () => {
        return { borderStyle: 'none', padding: '0px', margin: '0px' };
    }
});

function createBackground(isAlert: boolean, rowColorRule, traderSearchType: TraderSearchType, params: any) {
    let borderColor = '#151515';
    if (rowColorRule) {
        if (isAlert) {
            const mapper = { ...ALERT_TBL_FIELD_MAPPER };
            const ids = params.data[mapper[traderSearchType]];
            borderColor = ids.reduce((acc, currId) => acc || rowColorRule[currId], null) || '#151515';
        } else {
            borderColor = rowColorRule[params.data[traderSearchType]] || '#151515';
        }
        return `<div style="height: 100%; width: 6px; background-color: ${borderColor}"></div>`;
    } else {
        return `<div style="height: 100%; width: 6px; background-color: '#151515"></div>`;
    }
}

export enum AlertHeaderName {
    Id = 'id',
    Name = 'name',
    AlertType = 'alertType',
    Clients = 'clients',
    Accounts = 'accounts',
    Actors = 'actors',
    Score = 'score',
    Symbols = 'symbols',
    ManipulationValue = 'manipulationValue',
    ThresholdBreach = 'thresholdBreach',
    ThresholdValue = 'thresholdValue',
    CreatedAt = 'createdAt',
    FromTime = 'fromTime',
    ToTime = 'toTime',
    CaseId = 'caseId',
    CaseStatus = 'caseStatus'
}

export const alertTableColumn = (
    dict: DictService,
    iconByStatusPipe: IconByStatusPipe,
    windowOpenService: WindowOpenService
): { [key: string]: ColDef } => {
    return {
        [AlertHeaderName.Id]: {
            field: AlertHeaderName.Id,
            headerName: 'Alert ID',
            width: 150,
            onCellClicked: params => openAlert(params, windowOpenService),
            cellClass: 'link-cell',
            cellRenderer: ScoreCellRendererComponent
        },
        [AlertHeaderName.Score]: {
            field: AlertHeaderName.Score,
            headerName: 'Risk Score',
            width: 150,
            cellRenderer: ScoreCellRendererComponent
        },
        [AlertHeaderName.AlertType]: {
            field: AlertHeaderName.AlertType,
            headerName: 'Type',
            width: 100,
            valueGetter: params => [(params.data as BaseAlert).alertType.name],
            valueFormatter: params => getNameFromDictionary(dict.systemConstants.alertTypeName, params.value),
            cellRenderer: TrdsLabelMultipleCellRendererComponent,
            cellRendererParams: {
                dictName: 'alertTypeName',
                hasAttributeFlat: true
            }
        },
        [AlertHeaderName.Clients]: {
            field: AlertHeaderName.Clients,
            headerName: 'Client ID (P|C)',
            width: 200,
            cellRenderer: param => param?.value?.join()
        },
        [AlertHeaderName.Accounts]: {
            field: AlertHeaderName.Accounts,
            headerName: 'Account ID (P|C)',
            width: 200,
            cellRenderer: param => param?.value?.join()
        },
        [AlertHeaderName.Actors]: {
            field: AlertHeaderName.Actors,
            headerName: 'Actor ID (P|C)',
            width: 200,
            cellRenderer: param => param?.value?.join()
        },
        [AlertHeaderName.Symbols]: {
            field: AlertHeaderName.Symbols,
            headerName: 'Symbol',
            width: 150,
            cellRenderer: param => param.value?.join()
        },
        [AlertHeaderName.ManipulationValue]: {
            field: AlertHeaderName.ManipulationValue,
            headerName: 'Manipulation Value',
            width: 150
        },
        [AlertHeaderName.ThresholdBreach]: {
            field: AlertHeaderName.ThresholdBreach,
            headerName: 'Threshold Breach',
            width: 150
        },
        [AlertHeaderName.CreatedAt]: {
            field: AlertHeaderName.CreatedAt,
            headerName: 'Created At',
            width: DateCellComponent.defaultDateColumnWidth,
            cellRenderer: DateCellComponent
        },
        [AlertHeaderName.FromTime]: {
            field: AlertHeaderName.FromTime,
            headerName: 'From Time',
            width: DateCellComponent.defaultDateColumnWidth,
            cellRenderer: DateCellComponent
        },
        [AlertHeaderName.ToTime]: {
            field: AlertHeaderName.ToTime,
            headerName: 'To Time',
            width: DateCellComponent.defaultDateColumnWidth,
            cellRenderer: DateCellComponent
        },
        [AlertHeaderName.CaseId]: {
            field: AlertHeaderName.CaseId,
            headerName: 'Case ID',
            width: 150,
            onCellClicked: params => openCase(params.value, windowOpenService),
            cellClass: 'link-cell'
        },
        [AlertHeaderName.CaseStatus]: {
            field: AlertHeaderName.CaseStatus,
            headerName: 'Case Status',
            width: 150,
            cellRenderer: TrdsLabelCellRendererComponent,
            cellRendererParams: params => ({
                dictName: 'caseStatus',
                icon: iconByStatusPipe.transform(params.value)
            }),
            valueFormatter: params => getNameFromDictionary(dict.systemConstants.caseStatus, params.value)
            // ...getSetColumnFilter(
            //     () => of(['NEW', 'ASSIGNED', 'IN_PROGRESS', 'RESOLVED', 'PENDING', 'ESCALATED', 'TRANSFERRED']),
            //     params => getNameFromDictionary(dict.systemConstants.caseStatus, params.value)
            // )
        },
        [AlertHeaderName.ThresholdValue]: {
            field: AlertHeaderName.ThresholdValue,
            headerName: 'Threshold Value',
            width: 150
        }
    };
};

function openAlert(alert, windowOpenService: WindowOpenService): void {
    const url = `ucm/cases/${alert.data.caseId}/alerts-v2/MS/${alert.data.alertType.alertCategory}/${alert.value}`;
    windowOpenService.open(url);
}

function openCase(caseId: string, windowOpenService: WindowOpenService): void {
    const url = `ucm/cases/${caseId}`;
    windowOpenService.open(url);
}
function cellRenderer(param: string | undefined): string {
    return param || typeof param === 'number' ? param : '<span>&mdash;</span>';
}

export enum OrderStatusFilterUiName {
    new = 'New',
    cancelled = 'Cancelled',
    cancelledFilled = 'Cancelled Filled',
    filled = 'Filled',
    partiallyFilled = 'Partially Filled',
    replaced = 'Replaced',
    rejected = 'Rejected',
    expired = 'Expired'
}

export enum OrderStatusFilterBeName {
    new = 'New',
    cancelled = 'Cancelled',
    canceled = 'Canceled',
    canceledFill = 'CanceledFill',
    filled = 'Filled',
    partiallyFilled = 'PartiallyFilled',
    replaced = 'Replaced',
    rejected = 'Rejected',
    expired = 'Expired'
}

export const LegendStatusToOrderStatusFilterBeName: { [key in LegendStatus]: OrderStatusFilterBeName[] } = {
    [LegendStatus.new]: [OrderStatusFilterBeName.new],
    [LegendStatus.execution]: [OrderStatusFilterBeName.partiallyFilled, OrderStatusFilterBeName.filled],
    [LegendStatus.cancel]: [OrderStatusFilterBeName.cancelled, OrderStatusFilterBeName.canceled],
    [LegendStatus.expired]: [OrderStatusFilterBeName.expired],
    [LegendStatus.rejected]: [OrderStatusFilterBeName.rejected],
    [LegendStatus.canceledFill]: [OrderStatusFilterBeName.canceledFill],
    [LegendStatus.replaced]: [OrderStatusFilterBeName.replaced]
};

export const OrderStatusBeToLegendStatusMap: { [key in OrderStatusFilterBeName]?: LegendStatus } = {
    [OrderStatusFilterBeName.new]: LegendStatus.new,
    [OrderStatusFilterBeName.cancelled]: LegendStatus.cancel,
    [OrderStatusFilterBeName.canceledFill]: LegendStatus.canceledFill,
    [OrderStatusFilterBeName.partiallyFilled]: LegendStatus.execution,
    [OrderStatusFilterBeName.filled]: LegendStatus.execution,
    [OrderStatusFilterBeName.rejected]: LegendStatus.rejected,
    [OrderStatusFilterBeName.expired]: LegendStatus.expired,
    [OrderStatusFilterBeName.replaced]: LegendStatus.replaced
};

export const TableFilterOrderStatusMap = [
    [OrderStatusFilterUiName.new, OrderStatusFilterBeName.new],
    [OrderStatusFilterUiName.cancelled, OrderStatusFilterBeName.cancelled],
    [OrderStatusFilterUiName.cancelledFilled, OrderStatusFilterBeName.canceledFill],
    [OrderStatusFilterUiName.filled, OrderStatusFilterBeName.filled],
    [OrderStatusFilterUiName.partiallyFilled, OrderStatusFilterBeName.partiallyFilled],
    [OrderStatusFilterUiName.replaced, OrderStatusFilterBeName.replaced],
    [OrderStatusFilterUiName.rejected, OrderStatusFilterBeName.rejected],
    [OrderStatusFilterUiName.expired, OrderStatusFilterBeName.expired]
];
