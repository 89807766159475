import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isNullOrUndefined } from '../shared.functions';

const DEFAULT_STORAGE_TTL = 30 * 60 * 1000; // 30 minutes ms
const CACHE_PREFIX = 'TRDS_CACHE';

interface StorageItem {
    expiredTs: number;
    item: any;
}

class Storage {
    private _expiredDateKeysMap = new Map<number, string>();
    private _storage: globalThis.Storage;
    constructor(_storageObj: globalThis.Storage) {
        this._storage = _storageObj;
        this.findOldCacheRecords();
    }

    getFromCacheOrApply(
        key: string,
        fetchFn: () => Observable<any>,
        options?: { ttlMs?: number; saveIfFn?: (any) => boolean; tapCacheResultFn?: (any) => void }
    ): Observable<any> {
        const cachedData = this.getFromStorage(key);
        const ttlMs = options?.ttlMs || DEFAULT_STORAGE_TTL;
        const safeInCacheIfFn = options?.saveIfFn ? options.saveIfFn : () => true;
        const tapCacheFn = options?.tapCacheResultFn ? options.tapCacheResultFn : res => res;

        return isNullOrUndefined(cachedData)
            ? fetchFn().pipe(tap(res => safeInCacheIfFn(res) && this.saveInStorage(res, key, ttlMs)))
            : of(cachedData).pipe(tap(res => tapCacheFn(res)));
    }

    private getCacheKey(originalKey: string): string {
        return `${CACHE_PREFIX}_${originalKey}`;
    }

    saveInStorage(item: any, originalKey: string, ttlMs = DEFAULT_STORAGE_TTL): void {
        const key = this.getCacheKey(originalKey);
        const storageItem: StorageItem = {
            expiredTs: Date.now() + ttlMs,
            item
        };
        this._storage.setItem(key, JSON.stringify(storageItem));
        this._expiredDateKeysMap.set(storageItem.expiredTs, key);
        this.checkExpiredItems();
    }

    removeItem(originalKey: string): void {
        const key = this.getCacheKey(originalKey);
        this.removeItem(key);
    }

    private remove(key: string): void {
        this._storage.removeItem(key);
    }

    getFromStorage(originalKey: string): any {
        const key = this.getCacheKey(originalKey);
        const cachedData = this._storage.getItem(key);
        if (!cachedData) {
            return null;
        }

        const storageItem: StorageItem = JSON.parse(cachedData);
        const dateIsExpired = storageItem.expiredTs < Date.now();
        if (dateIsExpired) {
            this.remove(key);
            this._expiredDateKeysMap.delete(storageItem.expiredTs);
        }
        return dateIsExpired ? null : storageItem.item;
    }

    private checkExpiredItems() {
        const now = Date.now();

        this._expiredDateKeysMap.forEach((itemKey, expiredTs) => {
            if (expiredTs < now) {
                this.remove(itemKey);
                this._expiredDateKeysMap.delete(expiredTs);
            }
        });
    }

    private findOldCacheRecords() {
        const now = Date.now();
        const entries = Object.entries(this._storage).filter(i => i[0].startsWith(CACHE_PREFIX));
        entries.forEach(entry => {
            const key = entry[0];
            const value: StorageItem = JSON.parse(entry[1]);
            if (value.expiredTs < now) {
                this.remove(key);
            } else {
                this._expiredDateKeysMap.set(value.expiredTs, key);
            }
        });
    }
}

export const CacheSessionStorage = new Storage(sessionStorage);
export const CacheLocalStorage = new Storage(localStorage);
