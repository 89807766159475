import { Component, HostBinding, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { ColDef, IHeaderParams } from 'ag-grid-enterprise';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CSV_EDIT_DEFAULT_HEADER_NAME,
    CSV_MANDATORY_FIELDS,
    DEFAULT_EDIT_COLDEF,
    HeaderModel,
    HEADERS$
} from '../../../table.model';

@Component({
    selector: 'trds-tbl-select-header',
    templateUrl: './tbl-select-header.component.html',
    styleUrls: ['./tbl-select-header.component.scss']
})
export class TblCSVSelectHeaderComponent implements IHeaderAngularComp, OnDestroy {
    @HostBinding('class') class = 'market-inspection-tb-select-header';
    private destroy$ = new Subject<void>();
    public headers: HeaderModel[];
    selected = null;

    filterSelectFormControl = new FormControl();

    form = this.fb.group({
        headersName: ['', []]
    });

    public params: IHeaderParams;

    constructor(private fb: FormBuilder) {}
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    agInit(params: IHeaderParams<any>): void {
        HEADERS$.pipe(takeUntil(this.destroy$)).subscribe(headers => {
            this.headers = headers;
            const selectedHeader = this.selected ? this.selected : CSV_EDIT_DEFAULT_HEADER_NAME;
            this.filterSelectFormControl.setValue(selectedHeader);
        });
        this.params = params;
    }

    refresh(params: IHeaderParams<any>): boolean {
        return true;
    }

    onSelectHeader(event): void {
        const headerName = event;
        this.filterSelectFormControl.setValue(headerName);
        // this.selected = headerName;
        const coldef: ColDef = {
            headerName: headerName,
            field: this.params.column.getColId(),
            ...DEFAULT_EDIT_COLDEF
        };
        const colDefs = this.params.api.getColumnDefs().map(item => {
            if ((item as ColDef).colId === this.params.column.getColId()) {
                return coldef;
            }

            return item;
        });
        this.params.api.setGridOption('columnDefs', colDefs);
        this.selectHeaderName(headerName);
    }

    selectHeaderName(headerName: string): void {
        let selectedHeader = headerName;
        this.headers.forEach(header => {
            if (header.headerName === headerName) {
                if (headerName !== CSV_EDIT_DEFAULT_HEADER_NAME) {
                    header.isSelected = true;
                }
            }
            if (
                this.selected === header.headerName &&
                headerName === CSV_EDIT_DEFAULT_HEADER_NAME &&
                header.isSelected
            ) {
                selectedHeader = null;
                header.isSelected = false;
            }

            if (this.selected !== headerName && headerName !== CSV_EDIT_DEFAULT_HEADER_NAME) {
                this.unselectHeaderName(this.selected);
            }
        });
        this.selected = selectedHeader;
        HEADERS$.next(this.headers);
    }
    unselectHeaderName(unselectedName: string): void {
        this.headers.forEach(header => {
            if (header.headerName === unselectedName) {
                header.isSelected = false;
            }
        });
    }

    isDefaultHeader(header: string): boolean {
        return header == CSV_EDIT_DEFAULT_HEADER_NAME;
    }

    isRequiredOption(header: string): boolean {
        return CSV_MANDATORY_FIELDS.includes(header);
    }
}
