import moment from 'moment';
import { USE_LOCAL_TIME } from '../market-inspection.config';
import { Period } from '../models/market-inspection.models';
import { getCandlePeriodMS } from './chart-data-converter';

export function dateAsUtc(date: string): Date {
    return new Date(`${date}+00:00`);
}

export const getMiDate = (date: string | number): Date => {
    return USE_LOCAL_TIME
        ? asLocalDate(date)
        : moment(date)
              .utc()
              .toDate();
};

export const asLocalDate = (date: string | number): Date => {
    const dt = new Date(date);
    const d = dt.getTime();
    const offset = dt.getTimezoneOffset();
    return new Date(d + offset * 60000);
};

export const getDate = (date: Date | string | number = new Date()): Date => {
    return USE_LOCAL_TIME ? new Date(date) : localDateToUtc(date);
};

export const localDateToUtc = (date: Date | string | number = new Date()): Date => {
    const dt = new Date(date);
    const d = dt.getTime();
    const offset = dt.getTimezoneOffset();
    return new Date(d - offset * 60000);
};

export const toDateFormat = (date: string | number, format = CHART_DATE_FORMAT): string => {
    const m = USE_LOCAL_TIME ? moment(date) : moment.utc(date);
    return m.format(format);
};

export const CHART_DATE_FORMAT = 'yyyy-MM-DD HH:mm:ss.SSS';

export const roundDate = (originDate: Date, period: Period): Date => {
    const ts = originDate.getTime();
    return new Date(roundTs(ts, period));
};

export const roundTs = (ts: number, period: Period): number => {
    const candlePeriodMs = getCandlePeriodMS(period);
    return ts - (ts % candlePeriodMs);
};
