import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TimePeriod, TimeRangeOptions } from '../enums/market-inspection.enum';
import { IPeriod, ISymbolExchange } from '../models/chart.models';
import {
    IPriceChartInfo,
    ISymbolLegendConfigMap,
    ISymbolsPair,
    LegendItem,
    MIChartRequestBody,
    MITableType,
    PackageType,
    RowColorRule
} from '../models/market-inspection.models';
import { IClientEventsSize, IMinMax } from '../service/events/client-events-handler.service';
import { MI_MAX_SINGLE_EVENTS } from '../market-inspection.config';
import { MarketInspectionState, MarketInspectionStore } from './market-ispection.store';
import { IEventsSearchParams, ISearchCriteria, TraderSearchType } from '../components/tables/table.model';
import { ChipModel } from '../components/chip/chip.model';
import { IMiAlertDescriptionView } from 'src/app/_shared/shared.models';
import { IEventColorConfig, LegendFilters } from '../models/events.models';
import { pairFormat } from '../utils/mi-cache-utils';
import { map } from 'rxjs/operators';
import { getSymbolPairFilterObj } from '../utils/chart-utils';

@Injectable({ providedIn: 'root' })
export class MarketInspectionQuery extends Query<MarketInspectionState> {
    constructor(protected store: MarketInspectionStore) {
        super(store);
    }

    isZoomDisabled$ = this.select(state => state.isZoomInDisabled);

    isZoomIn$ = this.select(state => state.isZoomIn);

    isCandleChart$ = this.select(state => state.isCandleChart);

    get isCandleChart(): boolean {
        return this.getValue().isCandleChart;
    }

    eventsToggleValue$ = this.select(state => state.eventsToggleValue);

    enableEventToggle$ = this.select(state => state.enableEventToggle);

    displayedSegment$ = this.select(state => state.displayedSegment);

    get displayedSegment(): IPeriod {
        return this.getValue().displayedSegment;
    }

    isAlertView$ = this.select(state => state.isAlertView);

    get isAlertView(): boolean {
        return this.getValue().isAlertView;
    }

    isCustomRange$ = this.select(state => state.isCustomRange);

    get isCustomRange(): boolean {
        return this.getValue().isCustomRange;
    }

    selectedPeriod$ = this.select(state => state.selectedPeriod);

    get selectedPeriod(): TimePeriod {
        return this.getValue().selectedPeriod;
    }

    get selectedTimeRange(): TimeRangeOptions {
        return this.getValue().selectedTimeRange;
    }

    selectedTimeRangeOption$ = this.select(state => state.selectedTimeRange);

    selectedPair$ = this.select(state => state.selectedPair);

    get selectedPair(): ISymbolsPair {
        return this.getValue().selectedPair;
    }

    showTimeRange$ = this.select(state => state.showTimeRange);

    isEditView$ = this.select(state => state.isEditView);

    get isEditView(): boolean {
        return this.getValue().isEditView;
    }

    isBEview$ = this.select(state => state.isBEview);

    get isBEview(): boolean {
        return this.getValue().isBEview;
    }

    isCSVView$ = this.select(state => state.isCSVView);

    get isCSVView(): boolean {
        return this.getValue().isCSVView;
    }

    isDerivatives$ = this.select(state => state.isDerivatives);

    get isDerivatives(): boolean {
        return this.getValue().isDerivatives;
    }

    isDerivativesEnabled$ = this.select(state => state.isDerivativesEnabled);

    traderSearchType$ = this.select(state => state.traderSearchType);

    get traderSearchType(): TraderSearchType {
        return this.getValue().traderSearchType;
    }

    showAlerts$ = this.select(state => state.showAlerts);

    get showAlerts(): boolean {
        return this.getValue().showAlerts;
    }

    showAggAlerts$ = this.select(state => state.showAggAlerts);

    get showAggAlerts(): boolean {
        return this.getValue().showAggAlerts;
    }

    eventsSize$ = this.select(state => state.clientEventsSize);

    get eventsSize(): IClientEventsSize {
        return this.getValue().clientEventsSize;
    }

    get clientHasPrivateData(): boolean {
        return this.getValue().clientHasPrivateData;
    }

    beDataModeWhenCsvSelected$ = this.select(state => {
        return (state.isBEview || state.isAlertView) && !state.isCSVView && state.csvAfterEdit != null;
    });

    isCacheCSVFileExist$ = this.select(state => {
        return state.csvAfterEdit != null;
    });

    csvAfterEdit$ = this.select(state => state.csvAfterEdit);

    get csvAfterEdit(): { [key: string]: any }[] {
        return this.getValue().csvAfterEdit;
    }

    includeEvents$ = this.select(state => state.includeEvents);

    get includeEvents(): boolean {
        return this.getValue().includeEvents;
    }

    get isChartEventsSwitchedOn(): boolean {
        return this.getValue().includeEvents || this.hasActiveChipEvents;
    }

    includeEventsStatistic$ = this.select(state => state.includeEventsStatistic);

    get includeEventsStatistic(): boolean {
        return this.getValue().includeEventsStatistic;
    }

    get isChartEventsStatisticSwitchedOn(): boolean {
        return this.getValue().includeEventsStatistic || this.hasActiveChipEvents;
    }

    showChartDescription$ = this.select(state => {
        const hasActiveChipEvents = state.chipModelArray.filter(chip => chip.showEvents === true).length > 0;
        const showAlerts = state.showAlerts || this.clientIdsShowAlertArray.length;
        return state.isAlertView || showAlerts || state.includeEvents || hasActiveChipEvents;
    });

    get symbolPairCompare(): string {
        return this.getValue().symbolPairCompare;
    }

    isChartFocus$ = this.select(state => state.isChartFocus);

    get isChartFocus(): boolean {
        return this.getValue().isChartFocus;
    }

    csvFileFileName$ = this.select(state => state.csvFileFileName);

    chipModelArray$ = this.select(state => state.chipModelArray);

    get chipModelArray(): ChipModel[] {
        return this.getValue().chipModelArray;
    }

    hasActiveChipEvents$ = this.select(
        state => state.chipModelArray.filter(chip => chip.showEvents === true).length > 0
    );

    get hasActiveChipEvents(): boolean {
        return this.getValue().chipModelArray.filter(chip => chip.showEvents === true).length > 0;
    }

    get clientIdsArray(): string[] {
        return this.getValue().chipModelArray.map(chip => chip.id);
    }

    priceChartInfo$ = this.select(state => state.priceChartInfo);

    get priceChartInfo(): IPriceChartInfo {
        return this.getValue().priceChartInfo;
    }

    get includeBenchmark(): boolean {
        return this.getValue().includeBenchmark;
    }

    get clientIdsShowAlertArray(): string[] {
        return this.getValue()
            .chipModelArray.filter(chip => chip.showAlerts)
            .map(chip => chip.id);
    }

    get isAlertAggregation(): boolean {
        return !this.getValue().chipModelArray.map(chip => chip.id).length;
    }

    clientIdsShowEventsArray$ = this.select(state =>
        state.chipModelArray.filter(chip => chip.showEvents).map(chip => chip.id)
    );

    get clientIdsShowEventsArray(): string[] {
        return this.getValue()
            .chipModelArray.filter(chip => chip.showEvents)
            .map(chip => chip.id);
    }

    get fullPeriodBody(): MIChartRequestBody {
        return {
            ...this.chartRequestBody,
            ...this.displayedChartPeriod
        };
    }

    get chartRequestBody(): MIChartRequestBody {
        const body = this.getValue().chartRequestBody;
        if (body) {
            body.searchParams = {
                values: this.getValue().chipModelArray.map(clientId => clientId.id),
                type: this.getValue().traderSearchType
            };
        }
        return body;
    }

    get displayedChartPeriod(): IPeriod {
        return this.getValue().displayedChartPeriod;
    }

    rowStyleRule$ = this.select(state => state.rowStyleRule);

    get rowStyleRule(): RowColorRule {
        return this.getValue().rowStyleRule;
    }

    exchangesList$ = this.select(state => state.exchangesList);

    get exchangesList(): string[] {
        return this.getValue().exchangesList;
    }

    symbolExchangeFilter$ = this.select(state => state.symbolExchangeFilter);

    get symbolExchangeFilter(): ISymbolExchange {
        return this.getValue().symbolExchangeFilter;
    }

    tableTotalEvents$ = this.select(state => state.tableTotalEvents);

    get tableTotalEvents(): number {
        return this.getValue().tableTotalEvents;
    }

    // tableMaxEvents$ = this.select(state => state.tableMaxEvents);
    // get tableMaxEvents(): number {
    //     return this.getValue().tableMaxEvents;
    // }

    isEventsTotalLoading$ = this.select(state => state.isEventsTotalLoading);

    get isEventsTotalLoading(): boolean {
        return this.getValue().isEventsTotalLoading;
    }

    isReceivingNewEventsDisabled$ = this.select(state => state.tableTotalEvents >= MI_MAX_SINGLE_EVENTS);

    get isReceivingNewEventsDisabled(): boolean {
        return this.getValue().tableTotalEvents >= MI_MAX_SINGLE_EVENTS;
    }

    guardrailsRules$ = this.select(state => state.guardrailsRules);

    get guardrailsRules(): { [key: string]: boolean } {
        return this.getValue().guardrailsRules;
    }

    clientPackageType$ = this.select(state => state.clientPackageType);

    get clientPackageType(): string {
        return this.getValue().clientPackageType;
    }

    get isStandardPackage(): boolean {
        return this.getValue().clientPackageType === PackageType.STANDARD;
    }

    isCompareDisabled$ = this.select(state => state.isCompareDisabled);

    isPriceChartDisabled$ = this.select(state => state.isPriceChartDisabled);

    timePeriodRule$ = this.select(state => state.timePeriodRule);

    get timePeriodRule(): { [key: string]: boolean } {
        return this.getValue().timePeriodRule;
    }

    isAlertsTypeLoading$ = this.select(state => state.isAlertsTypeLoading);

    selectedAlgoTypes$ = this.select(state => state.selectedAlgoTypes);

    get selectedAlgoTypes(): string[] {
        return this.getValue().selectedAlgoTypes;
    }

    isMultiLineChart$ = this.select(state => state.isMultiLineChart);

    userFilterState$ = this.select(state => state.userFilterState);

    screenShotEnabled$ = this.select(state => state.screenShotEnabled);

    isDrawDisabled$ = this.select(state => state.isDrawDisabled);

    drawOn$ = this.select(state => state.drawOn);

    isShowDraw$ = this.select(state => state.isShowDraw);

    get caseId(): string {
        return this.getValue().caseId;
    }

    candleViewToggleDisabled$ = this.select(state => {
        return state.candleViewToggleDisabled || state.isMultiLineChart || state.alertRelated?.isCrossProduct;
    });

    showOnlyAlertRelatedEvents$ = this.select(state => state.showOnlyAlertRelatedEvents);

    get showOnlyAlertRelatedEvents(): boolean {
        return this.getValue().showOnlyAlertRelatedEvents;
    }

    // eventsType$ = this.select(state => state.eventsType);
    // get eventsType(): MIEventType {
    //     return this.getValue().eventsType;
    // }

    tableType$ = this.select(state => state.tableType);

    get tableType(): MITableType {
        return this.getValue().tableType;
    }

    tableAlertData$ = this.select(state => state.tableAlertData);

    get isAlertTableActive(): boolean {
        return (this.getValue().tableType as MITableType) === MITableType.ALERT;
    }

    disabledTabs$ = this.select(state => state.disabledTabs);

    get disabledTabs(): number[] {
        return this.getValue().disabledTabs;
    }

    useAggregationEvents$ = this.select(state => state.eventsAggregationView);

    get useAggregationEvents(): boolean {
        return this.getValue().eventsAggregationView;
    }

    showExecutionsOnly$ = this.select(state => state.showExecutionsOnly);

    get showExecutionsOnly(): boolean {
        return this.getValue().showExecutionsOnly;
    }

    legendFilters$ = this.select(state => state.legendFilters);

    get legendFilters(): LegendFilters {
        return this.getValue().legendFilters;
    }

    isBeEventsLoading$ = this.select(state => state.isBeEventsLoading);

    showEventsForAllChips$ = this.select(
        state => state.chipModelArray.length && !state.chipModelArray.find(chip => !chip.showEvents)
    );

    get showEventsForAllChips(): boolean {
        const chips = this.getValue().chipModelArray;
        return chips.length && !chips.find(chip => !chip.showEvents);
    }

    alertViewResetViewDisabled$ = this.select(state => state.alertViewResetViewDisabled);

    alertViewFocusViewDisabled$ = this.select(state => state.alertViewFocusViewDisabled);

    isEmptyChartResponse$ = this.select(state => state.isEmptyChartResponse);

    get isEmptyChartResponse(): boolean {
        return this.getValue().isEmptyChartResponse;
    }

    isEmptyBenchmarkResponse$ = this.select(state => state.isEmptyBenchmarkResponse);

    get isEmptyBenchmarkResponse(): boolean {
        return this.getValue().isEmptyBenchmarkResponse;
    }

    minMaxPriceSingleEvents$ = this.select(state => state.minMaxPriceSingleEvents);

    get minMaxPriceSingleEvents(): IMinMax {
        return this.getValue().minMaxPriceSingleEvents;
    }

    minMaxPriceAggregationEvents$ = this.select(state => state.minMaxPriceAggregationEvents);

    get minMaxPriceAggregationEvents(): IMinMax {
        return this.getValue().minMaxPriceAggregationEvents;
    }

    minMaxPriceSmartAggregationEvents$ = this.select(state => state.minMaxPriceSmartAggregationEvents);

    get minMaxPriceSmartAggregationEvents(): IMinMax {
        return this.getValue().minMaxPriceSmartAggregationEvents;
    }

    minMaxPriceAlertRelatedEvents$ = this.select(state => state.minMaxPriceAlertRelatedEvents);

    get minMaxPriceAlertRelatedEvents(): IMinMax {
        return this.getValue().minMaxPriceAlertRelatedEvents;
    }

    get alertRelated(): IMiAlertDescriptionView {
        return this.getValue().alertRelated;
    }

    alertRelated$ = this.select(state => state.alertRelated);

    get isPriceChartOn(): boolean {
        const priceChartInfo = this.getValue().priceChartInfo;
        return priceChartInfo.displayBbo || priceChartInfo.displayMidPrice;
    }

    get searchCriteria(): ISearchCriteria {
        return this.getValue().searchCriteria;
    }

    get isClientIdsSelected(): boolean {
        return !!this.getValue().chipModelArray?.length;
    }

    get eventsModeWasChangedByUser(): boolean {
        return this.getValue().eventsModeWasChangedByUser;
    }

    isCrossProduct$ = this.select(state => (state.alertRelated ? state.alertRelated.isCrossProduct : false));

    get isCrossProduct(): boolean {
        return this.getValue().isAlertView && this.getValue().alertRelated?.isCrossProduct;
    }

    secondarySymbols$ = this.select(state => state.alertRelated?.secondarySymbols ?? []);

    get secondarySymbols(): string[] {
        return this.getValue().alertRelated?.secondarySymbols ?? [];
    }

    get secondarySymbolsAsPairs(): ISymbolsPair[] {
        return this.getValue().alertRelated?.secondarySymbols?.map(i => getSymbolPairFilterObj(i)) || [];
    }

    get primarySymbol(): ISymbolsPair {
        return this.getValue().chartRequestBody?.symbolsPair[0];
    }

    selectedSecondarySymbols$ = this.select(state => state.selectedSecondarySymbols);

    get selectedSecondarySymbols(): Set<string> {
        return this.getValue().selectedSecondarySymbols;
    }

    get hiddenSymbols(): Set<string> {
        return this.getValue().hiddenSymbols;
    }

    eventsColorConfig$ = this.select(state => state.eventsColorConfig);

    get eventsColorConfig(): IEventColorConfig {
        return this.getValue().eventsColorConfig;
    }

    isAlertRelatedPined$ = this.select(state => state.isAlertRelatedPined);

    get isAlertRelatedPined(): boolean {
        return this.getValue().isAlertRelatedPined;
    }

    selectedSymbols$ = this.select(state => state.selectedSecondarySymbols).pipe(
        map((symbols: Set<string>) => {
            const baseSymbolPair = this.chartRequestBody?.symbolsPair[0]
                ? pairFormat(this.chartRequestBody.symbolsPair[0])
                : null;
            const baseSymbol = this.alertRelated?.symbol[0] ?? baseSymbolPair;
            return [baseSymbol, ...this.selectedSecondarySymbols].filter(Boolean);
        })
    );

    get selectedSymbols(): string[] {
        const baseSymbol = pairFormat(this.getValue().chartRequestBody.symbolsPair[0]);
        return [baseSymbol, ...this.getValue().selectedSecondarySymbols];
    }

    get symbolsLegendConfigMap(): ISymbolLegendConfigMap {
        return this.getValue().symbolsLegendConfigMap;
    }

    selectedEvent$ = this.select(state => state.selectedEvent);

    get isPrimarySymbolHided(): { symbol: string; isSelected: boolean } {
        return this.getValue().isPrimarySymbolHided;
    }

    get legendItem(): LegendItem[] {
        return this.getValue().legendItem;
    }

    showTrades$ = this.select(state => state.showTrades);

    get showTraders(): boolean {
        return this.getValue().showTrades;
    }

    get showNews(): boolean {
        return this.getValue().showNews;
    }

    isShowNews$ = this.select(state => state.showNews);

    sideBarButtons$ = this.select(state => state.sideBarButtons);

    get sideBarButtons() {
        return this.getValue().sideBarButtons;
    }

    panelsEditMode$ = this.select(state => state.panelsEditMode);

    get panelsEditMode(): boolean {
        return this.getValue().panelsEditMode;
    }

    get eventsSearchParams(): IEventsSearchParams {
        return {
            values: this.clientIdsArray,
            type: this.traderSearchType
        };
    }

    get includePrivateData(): boolean {
        return this.getValue().includePrivateData;
    }

    multiVenues$ = this.select(state => state.multiVenues);

    get isMultiVenuesExchange(): boolean {
        return this.getValue().multiVenues?.length > 1;
    }

    selectedVenues$ = this.select(state => state.selectedVenues);

    get selectedVenues(): string[] {
        return this.getValue().selectedVenues;
    }

    get selectedVisibleVenues(): string[] {
        const hiddenVenues = this.getValue().selectedHiddenVenues;
        return this.getValue().selectedVenues.filter(i => !hiddenVenues.has(i));
    }
}
