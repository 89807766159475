import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
    selector: 'trds-score-cell-renderer',
    template: `
        <span class="score" [trdsScoreColor]="score">{{ score }}</span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreCellRendererComponent implements ICellRendererAngularComp {
    score: string;

    agInit(params: ICellRendererParams): void {
        this.score = params.value;
    }

    refresh(params: ICellRendererParams<any>): boolean {
        return false;
    }
}
