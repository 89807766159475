import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'trds-date-display',
    template: `
        <span
            tippyClassName="trds-tooltip"
            [ngxTippy]="
                !timestamp || disableTooltip
                    ? null
                    : 'Local: ' + (timestamp | amFromUtc | amLocal | amDateFormat: tooltipDateFormat)
            "
        >
            <span *ngIf="!showAsLocalTime" [attr.auto_id]="autoId">
                {{ timestamp ? (timestamp | amFromUtc | amUtc | amDateFormat: dateFormat) : '&mdash;' }}
            </span>
            <span *ngIf="showAsLocalTime" [attr.auto_id]="autoId">
                {{ timestamp ? (timestamp | amDateFormat: dateFormat) : '&mdash;' }}
            </span>
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateDisplayComponent {
    @Input() timestamp: number | string | Date;
    @Input() tooltipDateFormat = 'MMM Do YYYY, HH:mm';
    @Input() dateFormat = 'MMM DD YYYY, HH:mm';
    @Input() showAsLocalTime = false;
    @Input() disableTooltip = false;
    @Input() autoId;
}
