import _ from 'lodash';
import { EventSide } from '../enums/events.enum';
import { IClientEvent, IEventOrder } from '../models/events.models';
import { OrderStatus } from '../models/market-inspection.models';
import { getMiDate, toDateFormat } from './data-time-utils';
import { CsvHeaderName } from '../components/tables/table-columns.model';

export const UNDEFINED_CSV_CLIENT = 'UNDEFINED_CSV_CLIENT';

export const csvDataToChartEvents = (data: any[]): IClientEvent[] => {
    // leave this logic to the future implementation
    // const containsClients = !!data[0][CsvHeaderName.clientId];
    // return containsClients ? csvEventsWithClientIds(data) : csvEvents(data);
    return csvEvents(data);
};

// leave this logic to the future implementation
const csvEventsWithClientIds = (data: any[]): IClientEvent[] => {
    const clientIdGroups = _.chain(data)
        .groupBy(CsvHeaderName.clientId)
        .map((value, searchValue) => {
            const { buy, sell } = asBuySellEventOrderArrays(value);
            return { searchValue, buy, sell };
        })
        .value();
    return clientIdGroups;
};

const csvEvents = (data: any[]): IClientEvent[] => {
    const { buy, sell } = asBuySellEventOrderArrays(data);
    return [{ searchValue: UNDEFINED_CSV_CLIENT, buy, sell }];
};

const asBuySellEventOrderArrays = (value: any[]): { buy: IEventOrder[]; sell: IEventOrder[] } => {
    const buy: IEventOrder[] = csvToEventOrderArray(value, EventSide.buy);
    const sell: IEventOrder[] = csvToEventOrderArray(value, EventSide.sell);
    return { buy, sell };
};

const csvToEventOrderArray = (value: any[], eventSide: EventSide): IEventOrder[] => {
    const side = eventSide.toLowerCase();
    return value
        .filter((val) => val[CsvHeaderName.side].toLowerCase() == side)
        .map(
            (item) =>
                new IEventOrder({
                    id: item[CsvHeaderName.id] || item.id,
                    isExecution: true,
                    symbol: item[CsvHeaderName.symbol],
                    versions: [
                        {
                            amount: item[CsvHeaderName.quantity],
                            orderType: item[CsvHeaderName.orderType],
                            date: toDateFormat(item[CsvHeaderName.transactionTime]),
                            csvDate: getMiDate(item[CsvHeaderName.transactionTime]),
                            price: item[CsvHeaderName.price],
                            status: item[CsvHeaderName.orderStatus] || OrderStatus.csvUndefinedStatus,
                            version: item[CsvHeaderName.version],
                        },
                    ],
                    side: eventSide,
                }),
        );
};

export const toTitleCase = (value: string): string =>
    value && `${value.substring(0, 1).toUpperCase()}${value.substring(1)?.toLowerCase()}`;

export const toEventType = (value: string): string => {
    switch (value) {
        case 'msPrivateOrder':
            return 'Order';
        case 'msPrivateExecution':
            return 'Execution';
    }
};
