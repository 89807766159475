import _ from 'lodash';
import { ChartFilter, EventSide, LegendStatus, SecurityType } from '../enums/events.enum';
import { IMinMax } from '../service/events/client-events-handler.service';
import { calculateMinMax } from '../utils/chart-utils';
import { MarkerType } from './chart.models';
import { WritableSignal } from '@angular/core';

export interface ChartFilterButton {
    icon?: string;
    color?: string;
    title: string;
    selected: WritableSignal<boolean>;
    legendKey?: LegendStatus | EventSide | ChartFilter;
    disabled?: WritableSignal<boolean>;
    tooltip?: string;
}

export interface IEventColorConfig {
    [type: EventSide | string]: {
        isButton: boolean;
        tooltip?: { on: string; off: string };
        active?: boolean;
    };
}

export interface IClientsEventsMultiSymbols {
    // symbol
    [key: string]: IClientEvent[];
}

export interface IClientsEventsResponse {
    symbolPair: string;
    clients: IClientEvent[];
}

export interface IClientEvent {
    searchValue: string;
    sell?: IEventOrder[];
    buy?: IEventOrder[];
}

export class IEventOrder implements MICacheItem {
    id: string;
    isExecution: boolean;
    side: EventSide;
    symbol: string;
    versions: IOrderVersion[];

    constructor(dto: Partial<IEventOrder>) {
        this.id = dto.id;
        this.isExecution = dto.isExecution;
        this.side = dto.side;
        this.symbol = dto.symbol;
        this.versions = dto.versions;
    }

    getComparisonList(): number[] {
        return this.versions.map(v => v.amount);
    }
}

export interface IEventsInfo<T extends MICacheItem> {
    [LegendStatus.new]: T[];
    [LegendStatus.execution]: T[];
    [LegendStatus.cancel]: T[];
    [LegendStatus.expired]: T[];
    [LegendStatus.rejected]: T[];
    [LegendStatus.canceledFill]: T[];
    [LegendStatus.replaced]: T[];
}

export interface IOrderVersion {
    date: string;
    csvDate?: Date;
    amount: number;
    orderType: string;
    price: number;
    version: number;
    status: string;
    securityType?: SecurityType;
    strikeValue?: string;
    execId?: string;
    exVenue?: string;
}

export interface MiMarkersCache<T extends MICacheItem> {
    events: ClientOrderCache<T>;
    minMax: IMinMax;
}

export interface ClientOrderCache<T extends MICacheItem> {
    // key is clientId
    [key: string]: T[];
}

export interface SideEventsCachedItem<T extends MICacheItem> {
    buy: EventsCacheItem<T>;
    sell: EventsCacheItem<T>;
}

export interface ClientEventStatusCache<T extends MICacheItem> {
    // key is clientId
    [key: string]: SideEventsCachedItem<T>;
}

export interface MiEventCacheResponse {
    searchValue: string;
}

export interface MICacheItem {
    side: EventSide;

    getComparisonList(): number[];
}

export class EventsCacheItem<T extends MICacheItem> {
    private _events: T[] = [];
    private _minMax: IMinMax = new IMinMax();

    get events(): T[] {
        return this._events;
    }

    get minMax(): IMinMax {
        return this._minMax;
    }

    addEvents(newEvents: T[]): void {
        this._events = [...this._events, ...newEvents];
        const newAmountList: number[] = newEvents.flatMap(ev => ev.getComparisonList());
        const newMinMax: IMinMax = new IMinMax(_.min(newAmountList), _.max(newAmountList));
        this._minMax = calculateMinMax(newMinMax, this.minMax);
    }
}

export interface ILegendFiltersChange {
    legendFiltersSelect: LegendFiltersSelect[];
    legendFilters: LegendFilters;
}

export interface LegendFiltersSelect {
    filter: EventSide | LegendStatus | ChartFilter;
    selected: boolean;
}

export interface LegendFilters {
    side: EventSide[];
    status: LegendStatus[];
}

export enum SingleMarkerSize {
    s_17 = 's_17',
    s_20 = 's_20',
    s_23 = 's_23',
    s_26 = 's_26',
    s_29 = 's_29',
    s_32 = 's_32',
    s_35 = 's_35',
    s_38 = 's_38'
}

export interface ICreateImageDto {
    type: MarkerType;
    markerSize: SingleMarkerSize;
    side: EventSide;
}
