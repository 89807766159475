import { Component, ChangeDetectionStrategy, ElementRef, HostListener, HostBinding, OnInit } from '@angular/core';

/**
 * List of classes to add to MatButton instances based on host attributes to
 * style as different variants.
 */
const LABEL_HOST_ATTRIBUTES = [
    'trds-icon', // have an icon prefix
    'trds-flat', // has background color
    'trds-media-adjusted', // have the label adjust to media
    'trds-large-height', // has large height
    'trds-no-border', // label has no border
    'trds-capitalize', // capitalized label
    'trds-chip', // clear label
    'trds-badge', // label with counter
    'trds-hover' // label with hover
];

@Component({
    selector: 'trds-label',
    templateUrl: './label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'trds-label'
})
export class LabelComponent implements OnInit {
    @HostBinding('class') classList = 'trds-label';

    public isTooltipDisabled = false;

    @HostListener('mouseenter') onEnter(): void {
        this.detectOverflow();
    }

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        // Stolen with pride from material button
        // https://github.com/angular/components/blob/master/src/material/button/button.ts
        // For each of the variant selectors that is present in the label's host
        // as attributes, add the correct corresponding class.
        for (const attr of LABEL_HOST_ATTRIBUTES) {
            if (this._hasHostAttributes(attr)) {
                (this._getHostElement() as HTMLElement).classList.add(attr);
            }
        }
    }

    private detectOverflow() {
        const label = this.elementRef.nativeElement.querySelector('.trds-label-wrapper');
        if (label && label.clientWidth !== 0) {
            this.isTooltipDisabled = label.offsetWidth >= label.scrollWidth;
        } else {
            this.isTooltipDisabled = false;
        }
    }

    private _getHostElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    /** Gets whether the button has one of the given attributes. */
    private _hasHostAttributes(...attributes: string[]) {
        return attributes.some(attribute => this._getHostElement().hasAttribute(attribute));
    }
}
