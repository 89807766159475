import { PackageType } from '../models/market-inspection.models';
import { ClientPermission } from './client-permission.interface';
import { CLIENT_SEARCH_MAX_LENGHT } from '../market-inspection.config';

export class StandardPackage extends ClientPermission {
    isDrawEnabled(): boolean {
        return true;
    }
    screenShotEnabled(): boolean {
        return true;
    }
    packageType(): PackageType {
        return PackageType.STANDARD;
    }

    packageClientSearchMaxLength(): number {
        return CLIENT_SEARCH_MAX_LENGHT;
    }

    isSingleExchange(): boolean {
        return false;
    }
}
