export const MI_CLIENT_EVENT_MARKER_CATEGORY = 'mi-client-event-marker';
export const AGGREGATION_MARKERS_PREFIX = 'aggregation-marker';
export const MI_TRADER_MARKER_CATEGORY = 'mi-trade-marker';
export const MI_NEWS_MARKER_CATEGORY = 'mi-news-marker';

export enum EventSide {
    buy = 'Buy',
    sell = 'Sell'
}

export enum ChartEventType {
    scroll = 'scroll',
    layout = 'layout',
    tap = 'tap',
    newChart = 'newChart'
}

export enum SecurityType {
    swap = 'SWAP',
    spot = 'SPOT',
    fut = 'FUT',
    opt = 'OPT'
}

export enum EventStatus {
    new = 'new',
    replaced = 'replaced',
    filled = 'filled',
    partiallyfilled = 'partiallyfilled',
    cancelled = 'cancelled',
    canceled = 'canceled'
}

export enum EventGroup {
    orders = 'Orders',
    executions = 'Executions',
    canceled = 'Cancelation',
    rejected = 'Rejected',
    expired = 'Expired',
    canceledFill = 'Cancelled Filled',
    replaced = 'Replaced'
}

export enum ChartFilter {
    executionsOnly = 'executionsOnly',
    more = 'more'
}
export enum LegendStatus {
    new = 'new',
    execution = 'execution',
    cancel = 'cancel',
    expired = 'expired',
    rejected = 'rejected',
    canceledFill = 'cancelledFilled',
    replaced = 'replaced'
}
