import { ChartInterval } from '../enums/chart.enum';
import { EventSide } from '../enums/events.enum';
import { eventsPath, imagesPath } from '../utils/link-utils';
import { CandlePeriodUnit, IChartExecution, Period } from './market-inspection.models';
import { SingleMarkerSize } from './events.models';

export interface SubEvent {
    date: Date;
    price: number;
    headline?: string;
    story?: string | Date;
}

export interface IPeriod {
    fromTime: number;
    toTime: number;
}

export interface ISymbolExchange {
    symbol: string;
    exchanges: string[];
}

export interface SwimLaneEvent {
    label: string;
    spanLabel?: string;
    startDate: Date | string;
    endDate?: Date | string;
    subChildren?: SubEvent[];
    category?: any;
    headline?: string;
    showPriceLines?: boolean;
    story?: Date | string;
    markerShape?: 'circle' | 'square' | 'callout';
    bgColor?: string;
    textColor?: string;
    isActive?: boolean;
    glyph?: string; // path to image from .src folder, f.e. 'assets/images/icons/warning.svg'
    alwaysZoom?: boolean;
}

export interface SwimLaneData {
    type: string;
    events: SwimLaneEvent[];
    spanType: 'spanEvent' | 'durationEvent' | 'singleEvent';
    infoPanel?: 'main' | 'panel';
    alwaysZoom?: boolean;
}

export interface IExecutionsPerDay {
    [key: string]: IChartExecution;
}

export interface IChartEventDayStatistic {
    buy: IExecutionsPerDay;
    sell: IExecutionsPerDay;
}

export enum ChartType {
    CANDLE = 'CANDLE',
    LINE = 'LINE'
}

export enum MarkerType {
    Execution = 'execution',
    Cancel = 'cancel',
    New = 'new',
    Empty = 'empty',
    Alert = 'alert',
    News = 'news',
    CanceledFill = 'canceledfill',
    Expired = 'expired',
    Rejected = 'rejected',
    Replaced = 'replaced',
    Cross = 'cross',
    Unknown = 'unknown',
    Trade = 'trades'
}

export enum MarkerForm {
    circle = 'circle',
    diamond = 'diamond',
    callout = 'callout',

    // function drawing of square markers was overridden for aggregation markers
    // See /utils/chart-draw-markers-override.ts
    aggregation = 'square'
}

export interface ChartImageMarker {
    type: MarkerType;
    icon?: string;
    path?: string;
    className: string;
}

export const CHART_ICON_MARKERS: ChartImageMarker[] = [
    {
        type: MarkerType.Cancel,
        icon: 'cancel',
        path: eventsPath('cancel'),
        className: 'cancel-marker'
    },
    {
        type: MarkerType.New,
        icon: 'new',
        path: eventsPath('new'),
        className: 'new-marker'
    },
    {
        type: MarkerType.Execution,
        icon: 'execution',
        path: eventsPath('execution'),
        className: 'execution-marker'
    },
    {
        type: MarkerType.Alert,
        icon: 'alert',
        path: eventsPath('alert'),
        className: 'alert-marker'
    },
    {
        type: MarkerType.News,
        icon: 'news',
        path: eventsPath('news'),
        className: 'news-marker'
    },
    {
        type: MarkerType.Empty,
        className: 'empty-marker'
    }
];

export const CHART_IMAGE_MARKERS: Map<EventSide, ChartImageMarker[]> = new Map([
    [
        EventSide.buy,
        [
            {
                type: MarkerType.Cancel,
                icon: 'cancel',
                path: imagesPath('cancel_buy'),
                className: 'alert-marker'
            },
            {
                type: MarkerType.New,
                icon: 'new',
                path: imagesPath('new_buy'),
                className: 'new-marker'
            },
            {
                type: MarkerType.Execution,
                icon: 'execution',
                path: imagesPath('execution_buy'),
                className: 'execution-marker'
            },
            {
                type: MarkerType.CanceledFill,
                icon: 'cancelled_filled_buy',
                path: imagesPath('cancelled_filled_buy'),
                className: 'cancel-marker'
            },
            {
                type: MarkerType.Expired,
                icon: 'expired',
                path: imagesPath('expired_buy'),
                className: 'expired'
            },
            {
                type: MarkerType.Rejected,
                icon: 'rejected',
                path: imagesPath('rejected_buy'),
                className: 'rejected'
            },
            {
                type: MarkerType.Replaced,
                icon: 'replaced',
                path: imagesPath('replaced_buy'),
                className: 'replaced'
            },
            {
                type: MarkerType.Cross,
                icon: 'cross',
                path: imagesPath('cross'),
                className: 'cross'
            },
            {
                type: MarkerType.Unknown,
                icon: 'unknown',
                path: imagesPath('unknown_buy'),
                className: 'unknown'
            }
        ]
    ],
    [
        EventSide.sell,
        [
            {
                type: MarkerType.Cancel,
                icon: 'cancel',
                path: imagesPath('cancel_sell'),
                className: 'alert-marker'
            },
            {
                type: MarkerType.New,
                icon: 'new',
                path: imagesPath('new_sell'),
                className: 'new-marker'
            },
            {
                type: MarkerType.Execution,
                icon: 'execution',
                path: imagesPath('execution_sell'),
                className: 'execution-marker'
            },
            {
                type: MarkerType.CanceledFill,
                icon: 'cancelled_filled_sell',
                path: imagesPath('cancelled_filled_sell'),
                className: 'cancel-marker'
            },
            {
                type: MarkerType.Expired,
                icon: 'expired',
                path: imagesPath('expired_sell'),
                className: 'expired'
            },
            {
                type: MarkerType.Rejected,
                icon: 'rejected',
                path: imagesPath('rejected_sell'),
                className: 'rejected'
            },
            {
                type: MarkerType.Replaced,
                icon: 'replaced',
                path: imagesPath('replaced_sell'),
                className: 'replaced'
            },
            {
                type: MarkerType.Unknown,
                icon: 'unknown',
                path: imagesPath('unknown_sell'),
                className: 'unknown'
            }
        ]
    ]
]);

export interface ChartImage {
    type: MarkerType;
    side: EventSide;
    date: Date;
    price: number;
    symbol: string;
    markerSize: SingleMarkerSize;
}

export enum MarkerVerticalPositionClass {
    top = 'top',
    bottom = 'bottom',
    center = 'center'
}

export enum MarkerHorizontalPositionClass {
    left = 'left',
    right = 'right'
}

export class MarkerPosition {
    vertical: MarkerVerticalPositionClass;
    horizontal: MarkerHorizontalPositionClass;

    constructor(vertical = MarkerVerticalPositionClass.bottom, horizontal = MarkerHorizontalPositionClass.right) {
        this.horizontal = horizontal;
        this.vertical = vertical;
    }

    static allClassOptions(): string[] {
        return [...Object.values(MarkerVerticalPositionClass), ...Object.values(MarkerHorizontalPositionClass)];
    }

    get classValues(): string[] {
        return [this.vertical, this.horizontal];
    }
}

export class AutocompleteItem {
    key: string;
    displayName: string;

    constructor(key: string, displayName?: string) {
        this.key = key;
        this.displayName = displayName || key;
    }

    toString(): string {
        return this.displayName;
    }
}

export interface AttachmentFile {
    id: number;
    name: string;
    uploadedAt: number;
    uploadedBy: {
        id: number;
        name: string;
        email: string;
    };
}

export enum ChartSeriesType {
    candle = 'candle',
    line = 'line',
    histogram = 'Histogram',
    stacked = 'stacked'
}

export interface IChartPeriodicity {
    period: number;
    interval: number;
    timeUnit?: ChartInterval;
}

export const chartPeriodicityToPeriod = (period: number, interval: ChartInterval): Period => {
    let unit = chartIntervalToUnit[interval];
    if (period === 60 && unit === CandlePeriodUnit.MINUTE) {
        unit = CandlePeriodUnit.HOUR;
        period = 1;
    }
    return {
        quantity: period,
        unit
    };
};

const chartIntervalToUnit = {
    [ChartInterval.millisecond]: CandlePeriodUnit.MILLISECOND,
    [ChartInterval.second]: CandlePeriodUnit.SECOND,
    [ChartInterval.minute]: CandlePeriodUnit.MINUTE,
    [ChartInterval.day]: CandlePeriodUnit.DAY
};

export interface ChartIqQuoteFeedParams {
    initializeChart?: boolean;
    interval: ChartInterval;
    period: number;
}

export interface ClickSpecification {
    clientId: string;
    side: string;
    status?: string[];
    id: string;
    useEventAggregation: boolean;
    aggregationMarkerFromDate?: Date;
    exVenue?: string;
}
