import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { CaseDetailsOrigin, CaseDetailsParams } from '../../ucm/cases/pages/case-details/case-details.models';

export class PageTitle {
    title: string;
    route: string[];
    autoIdAttrName?: string;
    tooltip?: string;
    icon?: string;

    constructor(title: string, route: string[], id?: string, autoIdAttrName?: string, tooltip?: string, icon?: string) {
        this.title = id ? `${title} ${id}` : title;
        this.route = route;
        this.autoIdAttrName = autoIdAttrName || 'text_PageTitle';
        this.tooltip = tooltip;
        this.icon = icon;
    }
}

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    private _title: PageTitle[] = [];
    private titleSubject: BehaviorSubject<PageTitle[]> = new BehaviorSubject([]);
    public title = this.titleSubject.asObservable();

    private titleFocusSub = new BehaviorSubject(null);
    public titleFocus$ = this.titleFocusSub.asObservable();

    private tabFocusSub = new BehaviorSubject(null);
    public tabFocus$ = this.tabFocusSub.asObservable();

    private overview: PageTitle[] = [new PageTitle('Risk Dashboard', ['ucm', 'overview'])];
    private ucm = this.overview.concat([
        new PageTitle('Unified Case Management', ['ucm', 'cases'], undefined, 'text_CaseMngTitle')
    ]);
    private marketInspection = [new PageTitle('Market Inspection', ['market-inspection-v2'])];
    private onboarding = [new PageTitle('Onboarding', ['onboarding'])];
    private algos = [new PageTitle('Algorithm Settings', ['algos'], undefined, 'txt_AlgoSetttingsPageTitle')];
    private applications = this.onboarding.concat([
        new PageTitle('Applications Queue', ['onboarding', 'applications'])
    ]);
    private adminSettings: PageTitle[] = [new PageTitle('Admin Settings', ['admin'])];

    private advancedSearch: PageTitle[] = [
        new PageTitle('Advanced Search', ['advanced-search'], undefined, 'txt_AdvancedSearchPageTitle')
    ];

    private clients = [new PageTitle('Universal Client View', ['ucv'])];

    private systemInfo = [new PageTitle('Data Audit', ['system-info'], undefined, 'text_DataAuditPageTitle')]; // previous name : User Activity

    private modelTesting = [new PageTitle('Model Testing', ['model-testing'], undefined, 'txt_ModelTestingPage')];

    private newModelTesting = [new PageTitle('New Model Run', ['new-model-run'], undefined, 'txt_NewModelRunPage')];

    private newModelTestingWithId = (modelTestId: string) => [
        new PageTitle('Test', ['new-model-run', modelTestId], modelTestId)
    ];

    private modelTestAnalytics = (modelTestId: string) => [
        new PageTitle('Test', ['analytics', modelTestId], modelTestId, 'txt_ModelTestAnalyticsTestId')
    ];

    private riskModel = [new PageTitle('Risk Model', ['risk-model'], undefined, 'txt_RiskModleTitle')];

    private screeningPolicy = [new PageTitle('Screening Policies', ['policy'], undefined, 'txt_KycPolicyPageTitle')];

    private notificationSettings = [
        new PageTitle(
            'Notification Settings',
            ['notification-settings'],
            undefined,
            'txt_NotificationSettingsPageTitle'
        )
    ];

    private triggerSubject() {
        this.titleSubject.next(this._title);
    }

    private readonly CaseDetailsOriginTitles: {
        [key in CaseDetailsOrigin]: (params: CaseDetailsParams) => PageTitle[];
    } = {
        [CaseDetailsOrigin.UCM]: () => {
            return this.ucm;
        },
        [CaseDetailsOrigin.UCV]: (data: CaseDetailsParams) => {
            const clientId = data.clientId;

            if (clientId) {
                return this.clients.concat(new PageTitle('Universal Client View', ['ucv', clientId], clientId));
            }

            return this.clients;
        },
        [CaseDetailsOrigin.ModelTesting]: (params: CaseDetailsParams) => {
            return this.getModelTestUcm(params.modelTestId);
        }
    };

    setOverview(): void {
        this._title = this.overview;
        this.triggerSubject();
    }

    setUCM(): void {
        this._title = this.ucm;
        this.triggerSubject();
    }

    setModelTestUCM(id: string): void {
        this._title = this.getModelTestUcm(id);
        this.triggerSubject();
    }

    setCase(id: string, params: CaseDetailsParams): void {
        const originTitle: PageTitle[] = this.CaseDetailsOriginTitles[params.origin](params);
        this._title = originTitle.concat(this.getCaseTitle(id));
        this.triggerSubject();
    }

    setNewCase(): void {
        this._title = this.ucm.concat(this.getNewCaseTitle());
        this.triggerSubject();
    }

    setAlert(caseId: string, alertType: string, alertId: string, modelTestId?: string): void {
        const parent: PageTitle[] = modelTestId ? this.getModelTestUcm(modelTestId) : this.ucm;
        this._title = parent.concat(this.getCaseTitle(caseId), this.getAlertTitle(caseId, alertType, alertId));
        this.triggerSubject();
    }

    setMarketInspection(tooltip?: string, icon?: string): void {
        this.marketInspection[0].tooltip = tooltip;
        this.marketInspection[0].icon = icon;
        this._title = this.marketInspection;
        this.triggerSubject();
    }

    setOnboarding(): void {
        this._title = this.onboarding;
        this.triggerSubject();
    }

    setApplications(): void {
        this._title = this.applications;
        this.triggerSubject();
    }

    setApplication(applicationId: string): void {
        this._title = this.applications.concat(this.getApplicationTitle(applicationId));
        this.triggerSubject();
    }

    setAlgos(): void {
        this._title = this.algos;
        this.triggerSubject();
    }

    setAdminSettings(): void {
        this._title = this.adminSettings;
        this.triggerSubject();
    }

    setAdvancedSearch(): void {
        this._title = this.advancedSearch;
        this.triggerSubject();
    }

    setModelTesting(): void {
        this._title = this.modelTesting;
        this.triggerSubject();
    }

    setUCV(): void {
        this._title = this.clients;
        this.triggerSubject();
    }

    setSystemInfo(): void {
        this._title = this.systemInfo;
        this.triggerSubject();
    }

    setClient(clientId: string): void {
        this._title = this.clients.concat(this.getClientId(clientId));
        this.triggerSubject();
    }

    setNewModelTesting(modelTestId?: string): void {
        this._title = this.modelTesting.concat(
            modelTestId ? this.newModelTestingWithId(modelTestId) : this.newModelTesting
        );

        this.triggerSubject();
    }

    setModelTestAnalytics(modelTestId: string): void {
        this._title = this.modelTesting.concat(this.modelTestAnalytics(modelTestId));

        this.triggerSubject();
    }

    setRiskModel(): void {
        this._title = this.riskModel;
        this.triggerSubject();
    }

    setScreeningPolicy(): void {
        this._title = this.screeningPolicy;
        this.triggerSubject();
    }

    setNotificationSettingsPage(): void {
        this._title = this.notificationSettings;
        this.triggerSubject();
    }

    private getCaseTitle(id: string): PageTitle {
        return new PageTitle('Case', ['ucm', 'cases', id], id, 'txt_CaseDetailPageTitle');
    }

    private getNewCaseTitle(): PageTitle {
        return new PageTitle('Create New Case', ['ucm', 'cases'], undefined, 'txt_CreateNewCase');
    }

    private getAlertTitle(caseId: string, alertType: string, alertId: string): PageTitle {
        return new PageTitle('Alert', ['ucm', 'cases', caseId, 'alerts', alertType, alertId], alertId);
    }

    private getApplicationTitle(applicationId: string): PageTitle {
        return new PageTitle('Application', ['onboarding', applicationId], applicationId);
    }

    private getClientId(id: string): PageTitle {
        return new PageTitle(id, ['ucv', 'clients'], undefined, 'txt_ClientDetailPageTile');
    }

    private getModelTestUcm(modelTestId: string): PageTitle[] {
        return this.modelTesting
            .concat(new PageTitle('', ['model-testing', 'new-model-run', modelTestId], modelTestId))
            .concat(new PageTitle('Unified Case Management', ['model-testing', 'ucm', modelTestId]));
    }

    focusOnTitle(): void {
        this.titleFocusSub.next(true);
    }

    focusOnTab(): void {
        this.tabFocusSub.next(this.getModuleName());
    }

    private getModuleName(): string {
        const pageTitle: PageTitle[] = this.titleSubject.value;

        if (pageTitle && pageTitle[0]?.route && pageTitle[0].route[0]) {
            return pageTitle[0].route[0];
        }
    }
}
