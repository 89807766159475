import { Injectable } from '@angular/core';
import { getMinCandleSize, TimePeriod } from '../enums/market-inspection.enum';
import { ClientPackage } from '../package/user-package';
import { IMinMax } from '../service/events/client-events-handler.service';
import { MarketInspectionStore } from './market-ispection.store';
import { calculateMinMax } from '../utils/chart-utils';
import { LegendItem } from '../models/market-inspection.models';

@Injectable({ providedIn: 'root' })
export class MarketInspectionChartFacade {
    constructor(
        private marketInspectionStore: MarketInspectionStore,
        private clientPackage: ClientPackage
    ) {}

    zoomInToggle(isZoomIn: boolean): void {
        this.marketInspectionStore.update(state => ({ ...state, isZoomIn }));
    }

    disableZoomIn(isZoomInDisabled: boolean): void {
        this.marketInspectionStore.update(state => {
            isZoomInDisabled =
                state.selectedPeriod == getMinCandleSize() || state.selectedPeriod == TimePeriod.day_1
                    ? true
                    : isZoomInDisabled;
            return {
                ...state,
                isZoomInDisabled
            };
        });
    }

    setChartView(isCandle: boolean): void {
        this.marketInspectionStore.update(state => {
            const chartRequestBody = state.chartRequestBody;
            if (chartRequestBody) {
                chartRequestBody.candleView = isCandle;
            }
            return {
                ...state,
                isCandleChart: isCandle,
                chartRequestBody
            };
        });
    }

    updateIsCompareDisabled(isCompareDisabled: boolean): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            isCompareDisabled
        }));
    }

    updateIsPriceChartDisabled(isPriceChartDisabled: boolean): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            isPriceChartDisabled
        }));
    }

    updateIsMultiLineChart(isMultiLineChart: boolean): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            isMultiLineChart
        }));
    }

    updateScreenShotEnabled(screenShotEnabled: boolean): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            screenShotEnabled: this.clientPackage.package.screenShotEnabled() && screenShotEnabled
        }));
    }

    updateIsDrawDisabled(isDrawDisabled: boolean): void {
        this.marketInspectionStore.update(state => {
            return {
                ...state,
                isDrawDisabled: !this.clientPackage.package.isDrawEnabled() ? true : isDrawDisabled,
                drawOn: state.drawOn && isDrawDisabled ? false : state.drawOn
            };
        });
    }

    updateDrawOn(): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            drawOn: !state.drawOn
        }));
    }

    candleViewToggleDisabled(candleViewToggleDisabled: boolean): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            candleViewToggleDisabled
        }));
    }

    updateSingleEventsPriceMinMax(minMax: IMinMax): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceSingleEvents: calculateMinMax(minMax, state.minMaxPriceSingleEvents)
        }));
    }

    updateAggregationEventsPriceMinMax(minMax: IMinMax): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceAggregationEvents: calculateMinMax(minMax, state.minMaxPriceAggregationEvents)
        }));
    }

    updateSmartAggregationEventsPriceMinMax(minMax: IMinMax): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceSmartAggregationEvents: calculateMinMax(minMax, state.minMaxPriceSmartAggregationEvents)
        }));
    }

    updateAlertRelatedEventsPriceMinMax(minMax: IMinMax): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceAlertRelatedEvents: calculateMinMax(minMax, state.minMaxPriceAlertRelatedEvents)
        }));
    }

    resetMinMaxPriceAggregationEvents(): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceAggregationEvents: null
        }));
    }

    resetMinMaxPriceSmartAggregationEvents(): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceSmartAggregationEvents: null
        }));
    }

    resetMinMaxPriceSingleEvents(): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceSingleEvents: null
        }));
    }

    resetMinMaxPriceAlertRelatedEvents(): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            minMaxPriceAlertRelatedEvents: null
        }));
    }

    updateIsPrimarySymbolHided(isPrimarySymbolHided: { symbol: string; isSelected: boolean }): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            isPrimarySymbolHided
        }));
    }

    updateLegendItem(legendItem: LegendItem[]): void {
        this.marketInspectionStore.update(state => ({
            ...state,
            legendItem
        }));
    }

    updateHiddenExVenues(exVenue: string, isHidden: boolean) {
        const selectedHiddenVenues = this.marketInspectionStore.getValue().selectedHiddenVenues;
        isHidden ? selectedHiddenVenues.add(exVenue) : selectedHiddenVenues.delete(exVenue);
        this.marketInspectionStore.update(state => ({
            ...state,
            selectedHiddenVenues
        }));
    }
}
