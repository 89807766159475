import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Auth, AUTH_TOKEN, SsoAuthInfo, TenantDto } from './auth.model';
import { ReAuthSessionData } from '../admin/admin.models';
import { SSO_CLIENT_ID, SSO_TOKEN } from '../_shared/shared.models';
import { PreSaveValueStorage } from '../ucm/cases/pages/create-case/components/pre-save-value-before-unload/pre-save-value.storage';
import { UserEnv, UserFlag } from '../_services/users/user.service';
import {
    MI_CSV_DATA,
    TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_ALERT_VIEW,
    TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_MI_VIEW
} from '../market-inspection-new/models/market-inspection.models';
import { isNullOrUndefined } from '../_shared/shared.functions';
import { TableStateStorageService } from 'src/app/ucm/cases/components/_shared/table-state-storage/table-state-storage.service';
import { CacheLocalStorage } from '../_shared/cache-storage/cache-storage';
import { SCREEN_CONFIGURATION_CACHE } from '../market-inspection-new/market-inspection.config';

export const microserviceKey = 'auth';
const PRIOR_DAYS_TO_PASSWORD_EXPIRATION = 14;

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _passwordExpiresInPopup = new BehaviorSubject<number>(null);

    private _userFlagEmitter: Subject<{ type: UserFlag; value: boolean }> = new Subject<{
        type: UserFlag;
        value: boolean;
    }>();

    private _userEnvEmitter: Subject<{ type: UserEnv; value: string }> = new Subject<{
        type: UserEnv;
        value: string;
    }>();

    private readonly storage = localStorage;

    constructor(
        private http: HttpClient,
        private tableStateStorageService: TableStateStorageService
    ) {}

    get userFlagUpdate$(): Observable<{ type: UserFlag; value: boolean }> {
        return this._userFlagEmitter.asObservable();
    }

    get userEnvUpdate$(): Observable<{ type: UserEnv; value: string }> {
        return this._userEnvEmitter.asObservable();
    }

    get passwordExpiresInPopup$(): Observable<number> {
        return this._passwordExpiresInPopup.asObservable();
    }

    public isLoggedIn(): boolean {
        return !!this.getToken();
    }

    public getToken(): string {
        return this.storage.getItem(AUTH_TOKEN);
    }

    public getSSOToken(): string {
        return this.storage.getItem(SSO_TOKEN);
    }

    public setPassword(verificationKey: string, uid: string, password: string): Observable<boolean> {
        // TEMP
        // return of(true);

        return this.http.post<boolean>(`${microserviceKey}/api/v1/users/set-password`, {
            verificationKey,
            uid,
            password
        });
    }

    public login(email: string, password: string, tenant?: string): Observable<Auth> {
        return this.http.post<Auth>(`${microserviceKey}/api/v1/auth/secure-login`, { email, password, tenant }).pipe(
            tap({
                next: authResponse => {
                    this.updateUserFlagsAndEnvs(authResponse);
                    if (authResponse.token && authResponse.user) {
                        this.setAuthToken(authResponse.token);
                    }
                    // TODO: remove check when PASSWORD_EXPIRATION_WORKFLOW is removed

                    if (authResponse?.user?.expireIn <= PRIOR_DAYS_TO_PASSWORD_EXPIRATION) {
                        this._passwordExpiresInPopup.next(authResponse?.user?.expireIn);
                    }
                }
            })
        );
    }

    public logout(): void {
        const data = this.storage.getItem(PreSaveValueStorage.storageKey);
        const csvData = this.storage.getItem(MI_CSV_DATA);
        const miPanelConfig = CacheLocalStorage.getFromStorage(TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_MI_VIEW);
        const alertPanelConfig = CacheLocalStorage.getFromStorage(TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_ALERT_VIEW);
        this.setAuthToken(null); // Set token to null in order to receive StorageEvent in another tab
        this.setSSOToken(null);
        this.storage.clear();

        if (miPanelConfig) {
            CacheLocalStorage.saveInStorage(
                miPanelConfig,
                TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_MI_VIEW,
                SCREEN_CONFIGURATION_CACHE
            );
        }

        if (alertPanelConfig) {
            CacheLocalStorage.saveInStorage(
                alertPanelConfig,
                TRDS_CACHE_LAYOUT_SCREEN_CONFIGURATION_ALERT_VIEW,
                SCREEN_CONFIGURATION_CACHE
            );
        }

        if (csvData && Object.keys(JSON.parse(csvData))?.length) {
            this.storage.setItem(MI_CSV_DATA, csvData);
        }

        if (data && Object.keys(JSON.parse(data))?.length) {
            this.storage.setItem(PreSaveValueStorage.storageKey, data);
        }

        sessionStorage.setItem(SSO_CLIENT_ID, null);
        sessionStorage.clear();
        this.tableStateStorageService.clear();
    }

    public sendResetPasswordLink(email: string, tenant?: string): Observable<boolean> {
        // return of(true);

        return this.http.post<boolean>(`${microserviceKey}/api/v1/users/password/send-link`, { email, tenant });
    }

    public resetPassword(verificationKey: string, uid: string, password: string, tenant?: string): Observable<boolean> {
        // TEMP
        // return of(true);

        return this.http.post<boolean>(`${microserviceKey}/api/v1/users/password/reset`, {
            verificationKey,
            uid,
            password,
            tenant
        });
    }

    public resetPasswordGetUserTenants(verificationKey: string, uid: string): Observable<TenantDto[]> {
        return this.http.post<TenantDto[]>(`${microserviceKey}/api/v1/users/password/tenants`, {
            verificationKey,
            uid
        });
    }

    public authenticate(code: string, userId: string, sso = false, reload = true): Observable<Auth> {
        return this.http
            .post<Auth>(
                `${microserviceKey}/api/v1/auth/auth-code`,
                { code, userId, sso },
                { params: { reloadOnFailure: reload } }
            )
            .pipe(
                tap({
                    next: authResponse => {
                        this.updateUserFlagsAndEnvs(authResponse);
                        if (authResponse.token && authResponse.user) {
                            this.setAuthToken(authResponse.token);
                        }
                    }
                })
            );
    }

    public getReAuthData(): Observable<ReAuthSessionData> {
        return this.http.get<ReAuthSessionData>(`${microserviceKey}/api/v1/client-settings/re-auth`);
    }

    public isUISessionExpired(): Observable<boolean> {
        return this.http.get<boolean>(`${microserviceKey}/api/v1/users/verify-re-auth`);
    }

    public validateEmailLogin(email: string): Observable<SsoAuthInfo> {
        return this.http.post<SsoAuthInfo>(`${microserviceKey}/api/v1/auth/auth-info`, { email });
    }

    public loginWithAzure(token: string): Observable<Auth> {
        return this.http.post<Auth>(`${microserviceKey}/api/v1/auth/azure-login`, { token }).pipe(
            tap({
                next: authResponse => {
                    this.updateUserFlagsAndEnvs(authResponse);
                    if (authResponse.token && authResponse.user) {
                        this.setAuthToken(authResponse.token);
                        this.setSSOToken(token);
                    }
                }
            })
        );
    }

    public loginWithOkta(token: string, issuerDomain: string): Observable<Auth> {
        return this.http.post<Auth>(`${microserviceKey}/api/v1/auth/okta/login`, { token, issuerDomain }).pipe(
            tap({
                next: authResponse => {
                    this.updateUserFlagsAndEnvs(authResponse);
                    if (authResponse.token && authResponse.user) {
                        this.setAuthToken(authResponse.token);
                        this.setSSOToken(token);
                    }
                }
            })
        );
    }

    public resetPasswordExpiresInPopup(): void {
        this._passwordExpiresInPopup.next(null);
    }

    public sendChangePasswordMail(): Observable<void> {
        return this.http.get<void>(`${microserviceKey}/api/v1/users/send-update-link`);
    }

    private setAuthToken(token: string): void {
        this.storage.setItem(AUTH_TOKEN, token);
    }

    private setSSOToken(token: string): void {
        this.storage.setItem(SSO_TOKEN, token);
    }

    private updateUserFlagsAndEnvs(authResponse: Auth): void {
        for (const userFlag of Object.values(UserFlag)) {
            this._userFlagEmitter.next({ value: authResponse[userFlag], type: userFlag });
        }
        for (const userEnv of Object.values(UserEnv)) {
            if (!isNullOrUndefined(authResponse[userEnv])) {
                this._userEnvEmitter.next({ value: authResponse[userEnv], type: userEnv });
            }
        }
    }
}
