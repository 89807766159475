<div *ngIf="cellValue > 0; else dashContent">
    <trds-date-display
        *ngIf="cellValue"
        [timestamp]="cellValue"
        [showAsLocalTime]="USE_LOCAL_TIME"
        [disableTooltip]="USE_LOCAL_TIME"
        [dateFormat]="DATE_TIME_FORMAT"
        [tooltipDateFormat]="DATE_TIME_FORMAT"
    ></trds-date-display>
</div>

<ng-template #dashContent>
    <span>&mdash;</span>
</ng-template>
