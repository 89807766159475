import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';

@Component({
    selector: 'trds-event-type-cell-renderer',
    template: `
        <trds-label trds-flat trds-icon tabindex="0">
            <mat-icon class="event-type-icon" [svgIcon]="icon"></mat-icon>
            <span class="label">{{ eventType }}</span>
        </trds-label>
    `,
    styleUrls: ['./event-type-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTypeCellRendererComponent implements ICellRendererAngularComp {
    eventType: 'Order' | 'Execution';
    icon: 'execution' | 'order';

    agInit(params: ICellRendererParams): void {
        this.eventType = params.value ? 'Execution' : 'Order';
        this.icon = params.value ? 'execution' : 'order';
    }
    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
